/* eslint-disable array-callback-return */
import React from "react";
import { useQuery, useMutation } from "@apollo/client";
import { useParams } from "react-router-dom";
import { PageBody, PageGrid } from "../../theme";
import { ProjectReducer } from "../../utilities";
import { DataContext } from "../../context/DataContext";
import {
  GET_PROJECT_BY_ID,
  UPDATE_PROJECT,
  CREATE_CLAIM,
  UPDATE_CLAIM,
  DELETE_CLAIM,
} from "../../api";
import {
  AddProject,
  Editor,
  ContactToPRS,
  Deliverables,
  InfoCard,
  ProjectsAndServicesOverview,
  Notes,
  Tags,
  ClaimsAndInvoices,
  ActivePRS,
  Budgets,
  InteractionToSingleItem,
} from "../../components";

const Project = (props: any) => {
  const { id } = useParams();
  const { resources, users } = React.useContext<any>(DataContext);
  const [project, setProject] = React.useState<any>(null);
  const [created, setCreated] = React.useState<string>("");
  const [editor, setEditor] = React.useState<boolean>(false);
  const [managerStatus, setManagerStatus] = React.useState<boolean>(false);
  const [canView, setCanView] = React.useState<boolean>(true);
  const [updateStatus] = useMutation(UPDATE_PROJECT);
  const { loading, data: projectData } = useQuery(GET_PROJECT_BY_ID, {
    variables: { id },
  });

  React.useEffect(() => {
    if (projectData && !loading) {
      setProject(projectData.getProjectById);
    }
  }, [projectData, loading]);

  React.useEffect(() => {
    if (project && project.createdAt) {
      setCreated(project.createdAt.substr(0, 10));
    }
  }, [project]);

  React.useEffect(() => {
    const checkUser = () => {
      /* 
      If the user is an Uber User, Finance, or the project lead, 
      they can view it with no restrictions
      */
      if (
        props.user.attributes["custom:userGroup"] === "Uber User" ||
        props.user.attributes["custom:userGroup"] === "Finance" ||
        project.lead.includes(props?.user.attributes.sub)
      ) {
        setManagerStatus(true);
        setCanView(true);
      } else if (users && project && project.lead) {
        /*
        If the user is a manager, and the project is attached to their team,
        they can view it
        */
        let attachedUsers = [];
        const leadUsers = project.lead.map((leadId: string) =>
          users.find((user: any) => user.id === leadId)
        );
        const memberUsers = project.members.map((memberId: string) =>
          users.find((user: any) => user.id === memberId)
        );
        attachedUsers.push(...leadUsers, ...memberUsers);
        if (
          props?.user.attributes["custom:userGroup"] === "Manager" &&
          attachedUsers.find((user: any) =>
            user.team.includes(props?.user.attributes["custom:team"])
          )
        ) {
          setManagerStatus(true);
          setCanView(true);
        }
      } else if (project.members.includes(props.user.attributes.sub)) {
        /*
        If the user is a member of the project, they can ONLY view it
        */
        setCanView(true);
      } else {
        setCanView(false);
      }
    };

    if (props.user && project) {
      checkUser();
    } else {
      setCanView(false);
    }
  }, [project, users, props?.user]);

  const handleEdit = (e: any) => {
    e.preventDefault();
    setEditor(!editor);
  };

  const handleStatusUpdate = (status: string) => {
    updateStatus({
      variables: {
        id,
        input: {
          status: status,
        },
      },
      refetchQueries: [{ query: GET_PROJECT_BY_ID }],
    });
  };

  return (
    <>
      {!loading && canView ? (
        <>
          {editor && (
            <Editor
              type="Project"
              component={
                <AddProject {...props} isEdit={true} id={id} data={project} />
              }
            />
          )}
          <PageBody>
            <div className="last-modified">
              <p>
                Last Modified:
                <span>{created}</span>
              </p>
            </div>
            <PageGrid>
              <div className="item-left-column">
                <InfoCard
                  title="Project Overview"
                  height="650px"
                  width="100%"
                  headerOption={managerStatus ? "edit" : null}
                  onBtnClick={handleEdit}
                  fields={[
                    <ProjectsAndServicesOverview
                      data={project}
                      type="project"
                      handleStatusUpdate={handleStatusUpdate}
                      managerPermission={managerStatus}
                    />,
                  ]}
                />
                <InfoCard
                  title="Tags"
                  height="300px"
                  width="100%"
                  fields={[<Tags type="service" data={project} />]}
                />
                {managerStatus && (
                  <div
                    className="grid-block"
                    style={{ height: "300px", margin: "25px" }}
                  />
                )}
              </div>
              <div className="item-middle-column">
                <InfoCard
                  title="Main Funder Contacts"
                  height="300px"
                  width="100%"
                  fields={[<ContactToPRS data={project?.contacts} />]}
                />
                <InfoCard
                  title="Resources"
                  height="300px"
                  width="100%"
                  fields={[
                    <ActivePRS
                      data={resources}
                      type="resources"
                      id={id}
                      pairType="program"
                    />,
                  ]}
                />
                <InfoCard
                  title="Deliverables"
                  height="300px"
                  width="100%"
                  fields={[
                    <Deliverables
                      key="project-deliverables"
                      id={id}
                      type="project"
                      field="deliverables"
                      data={project?.deliverables}
                    />,
                  ]}
                />
                {managerStatus && (
                  <InfoCard
                    title="Claims"
                    height="300px"
                    width="100%"
                    fields={[
                      <ClaimsAndInvoices
                        id={id}
                        type="project"
                        field="claim"
                        data={project?.claims}
                        createMutation={CREATE_CLAIM}
                        updateMutation={UPDATE_CLAIM}
                        deleteMutation={DELETE_CLAIM}
                      />,
                    ]}
                  />
                )}
              </div>
              <div className="item-right-column">
                <InfoCard
                  title="Notes"
                  height="650px"
                  width="100%"
                  fields={[
                    <Notes
                      id={id}
                      data={project?.notes}
                      reducer={ProjectReducer}
                      refetchQuery={GET_PROJECT_BY_ID}
                    />,
                  ]}
                />
                <InfoCard
                  title="Interactions"
                  height="300px"
                  width="100%"
                  fields={[
                    <InteractionToSingleItem id={id} type="program/project" />,
                  ]}
                />
                {managerStatus && (
                  <InfoCard
                    title="Budget"
                    height="300px"
                    width="100%"
                    fields={[
                      <Budgets
                        key="project-budget"
                        id={id}
                        type="budget"
                        field="budget"
                        data={project?.budget}
                      />,
                    ]}
                  />
                )}
              </div>
            </PageGrid>
          </PageBody>
        </>
      ) : (
        <div className="no-permission">
          <h1>You do not have permission to view this page.</h1>
        </div>
      )}
    </>
  );
};

export default Project;
