import { gql } from "@apollo/client";

export const GET_RESOURCES = gql`
  query getAllResources($nextToken: String) {
    getAllResources(nextToken: $nextToken) {
      resources {
        type
        program
        participants
        notes {
          username
          sourceId
          parent
          id
          createdAt
          body
        }
        name
        link
        id
        description
        dateHeld
        createdAt
        ceuDescription
        ceu
        author
        participants
        views
        copies
        listeners
        username
      }
      nextToken
    }
  }
`;

export const GET_RESOURCE_BY_ID = gql`
  query getResourceById($id: ID!) {
    getResourceById(id: $id) {
      type
      program
      participants
      notes {
        username
        sourceId
        parent
        id
        createdAt
        body
      }
      name
      link
      id
      description
      dateHeld
      createdAt
      ceuDescription
      ceu
      author
      participants
      views
      copies
      listeners
      username
    }
  }
`;

export const CREATE_RESOURCE = gql`
  mutation CreateResource($newResource: ResourceInput) {
    createResource(newResource: $newResource) {
      type
      program
      participants
      notes {
        username
        sourceId
        parent
        id
        createdAt
        body
      }
      name
      link
      id
      description
      dateHeld
      createdAt
      ceuDescription
      ceu
      author
      participants
      views
      copies
      listeners
      username
    }
  }
`;

export const UPDATE_RESOURCE = gql`
  mutation UpdateResource($id: ID!, $input: ResourceInput) {
    updateResource(id: $id, input: $input) {
      type
      program
      participants
      notes {
        username
        sourceId
        parent
        id
        createdAt
        body
      }
      name
      link
      id
      description
      dateHeld
      createdAt
      ceuDescription
      ceu
      author
      participants
      views
      copies
      listeners
      username
    }
  }
`;

export const DELETE_RESOURCE = gql`
  mutation DeleteResource($id: ID!) {
    deleteResource(id: $id) {
      type
      program
      participants
      notes {
        username
        sourceId
        parent
        id
        createdAt
        body
      }
      name
      link
      id
      description
      dateHeld
      createdAt
      ceuDescription
      ceu
      author
      participants
      views
      copies
      listeners
      username
    }
  }
`;
