import React, { useState } from "react";
import { Auth } from "aws-amplify";
import { InfoCard, AdvancedInput } from "..";
import { Button, SubmitButton } from "../../theme";

const ForgotPassword = (props: any) => {
  const [modal, setModal] = useState(true);
  const [stage, setStage] = useState(1);
  const [email, setEmail] = useState("");
  const [code, setCode] = useState("");
  const [message, setMessage] = useState("Change Password");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const sendCode = (e) => {
    e.preventDefault();
    Auth.forgotPassword(email)
      .then((data) => {
        alert("A reset code has been sent to your phone");
        setStage(2);
        console.log(data);
      })
      .catch((err) => console.log(err));
  };

  const resetPassword = (e) => {
    e.preventDefault();
    Auth.forgotPasswordSubmit(email, code, password).then(() => {
      if (password !== confirmPassword) {
        alert("OOPS! The passwords appear to be different. Try again");
        return;
      } else {
        alert("Password successfully updated");
        setMessage("Success");
        setModal(false);
      }
    });
  };

  const handleClose = (e) => {
    e.preventDefault();
    setModal(!modal);
  };

  return (
    <div className={modal ? "forgot-password" : "forgot-password is-notActive"}>
      <InfoCard
        className="forgot-password-modal"
        title="Reset your password"
        headerOption="modal"
        height={stage === 1 ? "200px" : "auto"}
        width="350px"
        backgroundColor="white"
        onBtnClick={handleClose}
        fields={[
          <>
            {stage === 1 && (
              <form className="forgot-pw-input-container" onSubmit={sendCode}>
                <AdvancedInput
                  {...props}
                  type="text"
                  width="95%"
                  name="Username"
                  value={email}
                  onChange={(event) => setEmail(event.target.value)}
                />
                <Button width="200px">Send Code</Button>
              </form>
            )}

            {stage === 2 && (
              <form
                className="forgot-pw-input-container"
                onSubmit={resetPassword}
              >
                <AdvancedInput
                  {...props}
                  type="text"
                  width="95%"
                  name="Validation Code"
                  value={code}
                  onChange={(e) => setCode(e.target.value)}
                />
                <AdvancedInput
                  {...props}
                  type="text"
                  width="95%"
                  name="New Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <AdvancedInput
                  {...props}
                  type="text"
                  width="95%"
                  name="Confirm New Password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />
                <SubmitButton width="200px" onClick={resetPassword}>
                  {message}
                </SubmitButton>
              </form>
            )}
          </>,
        ]}
      />
    </div>
  );
};

export default ForgotPassword;
