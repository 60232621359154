/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { SwiperSlide, Swiper } from "swiper/react";
import SwiperCore, { Navigation } from "swiper/core";
import { DataContext } from "../../context/DataContext";

SwiperCore.use([Navigation]);

const ContactToPRS = (props: any) => {
  const { contacts } = React.useContext(DataContext);
  const [data, setData] = useState(props.data);
  const [matchedContacts, setmatchedContacts] = useState([]);

  useEffect(() => {
    if (Array.isArray(data) && data.length > 0 && contacts) {
      const filteredContacts = contacts.filter((contact: any) => {
        if (data.includes(contact.id)) {
          return contact;
        }
      });
      setmatchedContacts(filteredContacts);
    }
  }, [contacts, data]);

  // Update the data state when props.data changes
  useEffect(() => {
    setData(props.data);
  }, [props.data]);

  return (
    <div>
      <Swiper
        className="contact-swiper"
        slidesPerView={1}
        spaceBetween={30}
        slidesPerGroup={1}
        style={{ height: props.height }}
        loopFillGroupWithBlank
        navigation
      >
        {matchedContacts?.map((contact) => (
          <SwiperSlide className="contact-card" key={contact.id}>
            <div className="contact-card-header">
              <p>{contact.name}</p>
              <Link key={contact.id} to={`/directory/contacts/${contact.id}`}>
                View Contact
              </Link>
            </div>
            <div className="contact-card-body">
              {contact.isPrimary ? (
                <div className="contact-isPrimary">
                  <h6>Primary Contact</h6>
                </div>
              ) : (
                <div className="contact-isNotPrimary" />
              )}
              <p>
                Division:
                <span>{contact.division}</span>
              </p>
              <p>
                Job Title:
                <span>{contact.jobTitle}</span>
              </p>
              <p>
                Phone:
                <span>
                  <a href={`tel://${contact.phone}`}>{contact.phone}</a>
                </span>
              </p>
              <p>
                Email:
                <span>
                  <a href={`mailto://${contact.primaryEmail}`}>
                    {contact.primaryEmail}
                  </a>
                </span>
              </p>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default ContactToPRS;
