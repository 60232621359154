import { gql } from "@apollo/client";

export const GET_RESOURCETYPES = gql`
  query getAllResourceTypes($nextToken: String) {
    getAllResourceTypes(nextToken: $nextToken) {
      resourceTypes {
        id
        name
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const GET_RESOURCETYPE_BY_ID = gql`
  query getResourceTypeById($id: ID!) {
    getResourceTypeById(id: $id) {
      id
      name
      createdAt
      updatedAt
    }
  }
`;

export const CREATE_RESOURCETYPE = gql`
  mutation greateResourceType($newResourceType: ResourceTypeInput) {
    createResourceType(newResourceType: $newResourceType) {
      id
      name
      createdAt
      updatedAt
    }
  }
`;

export const UPDATE_RESOURCETYPE = gql`
  mutation updateResourceType($id: ID!, $input: ResourceTypeInput) {
    updateResourceType(id: $id, input: $input) {
      id
      name
      createdAt
      updatedAt
    }
  }
`;

export const DELETE_RESOURCETYPE = gql`
  mutation deleteResourceType($id: ID!) {
    deleteResourceType(id: $id) {
      id
      name
      createdAt
      updatedAt
    }
  }
`;
