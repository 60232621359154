/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Auth } from "aws-amplify";
import { Table, AddItem, AddResource } from "../../components";
import { ResourceSchema, IdToNameFormatter } from "../../utilities";
import { PageBody } from "../../theme";
import { DataContext } from "../../context/DataContext";

const Resources = (props: any) => {
  const [addItem, setAddItem] = useState(false);
  const [isSuperAdmin, setIsSuperAdmin] = useState(false);
  const { resources, setResources, users, programs, projects } =
    React.useContext(DataContext);

  useEffect(() => {
    if (resources) {
      setResources(
        resources.map((item: any) => {
          const formattedProgram = item.program
            ? IdToNameFormatter({
                data: programs,
                fieldToMatch: item.program,
              })
            : "";
          const formattedProject = item.program
            ? IdToNameFormatter({
                data: projects,
                fieldToMatch: item.program,
              })
            : "";

          return {
            ...item,
            formattedAuthor: IdToNameFormatter({
              data: users,
              fieldToMatch: item.author,
            }),
            formattedProgram,
            formattedProject,
          };
        })
      );
    }
  }, []);

  useEffect(() => {
    const getUserDetails = async () => {
      const authedUser = await Auth.currentAuthenticatedUser();
      if (authedUser?.attributes["custom:userGroup"].includes("Uber User")) {
        setIsSuperAdmin(true);
      } else {
        setIsSuperAdmin(false);
      }
    };
    getUserDetails();
  }, []);

  const handleAdd = (e) => {
    e.preventDefault();
    setAddItem(!addItem);
  };

  return (
    <>
      {addItem && (
        <AddItem type="resource" component={<AddResource {...props} />} />
      )}
      <PageBody>
        <Table
          showAdd={isSuperAdmin}
          handleAdd={handleAdd}
          title="Resources"
          count={resources?.length}
          rowDataProp={resources}
          colDefProp={ResourceSchema}
        />
      </PageBody>
    </>
  );
};

export default Resources;
