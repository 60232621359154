import React, { useState, useEffect } from "react";
import { useQuery, useMutation } from "@apollo/client";
import { toast } from "react-toastify";
import { Table, AdvancedInput, InfoCard } from "../../components";
import {
  GET_CONTACTTYPES,
  CREATE_CONTACTTYPE,
  UPDATE_CONTACTTYPE,
  DELETE_CONTACTTYPE,
} from "../../api";
import { DateFormatter } from "../../utilities";
import { SubmitButton, PageBody } from "../../theme";

const AdminIndustries = (props: any) => {
  const { data } = useQuery(GET_CONTACTTYPES);
  const [name, setName] = useState("");
  const [modal, setModal] = useState(false);
  const [contactTypes, setContactTypes] = useState([]);
  const [addContactType] = useMutation(CREATE_CONTACTTYPE);
  const [columnDefs] = useState([
    {
      field: "name",
      headerName: "Name",
      filter: true,
      sortable: true,
      minWidth: 500,
      width: 500,
    },
    {
      field: "formattedDate",
      headerName: "Created At",
      minWidth: 150,
      width: 150,
    },
    {
      field: "id",
      headerName: "Actions",
      width: 100,
      cellRendererSelector: () => ({
        component: "actionsRenderer",
        params: {
          id: "id",
          refetchQuery: GET_CONTACTTYPES,
          updateMutation: UPDATE_CONTACTTYPE,
          deleteMutation: DELETE_CONTACTTYPE,
        },
      }),
    },
  ]);

  const showModal = () => setModal(!modal);

  useEffect(() => {
    if (data) {
      setContactTypes(
        data?.getAllContactTypes.contactTypes.map((item) => ({
          ...item,
          formattedDate: DateFormatter({ createdAt: item.createdAt }),
        }))
      );
    }
  }, [data]);

  const handleAdd = (e) => {
    e.preventDefault();
    const notify = () => toast.success("Contact Type successfully added");
    addContactType({
      variables: {
        newContactType: {
          name: name,
        },
      },
      refetchQueries: [{ query: GET_CONTACTTYPES }],
    });
    notify();
    setName("");
    setModal(false);
  };

  return (
    <PageBody>
      {modal && (
        <div
          className={modal ? "admin-add-modal" : "admin-add-modal is-notActive"}
        >
          <InfoCard
            title="Create new Contact Type"
            width="350px"
            headerOption="modal"
            onBtnClick={showModal}
            backgroundColor="white"
            fields={[
              <form className="admin-edit-add-new-item" onSubmit={handleAdd}>
                <AdvancedInput
                  {...props}
                  name="Contact Type Name"
                  fieldName="name"
                  type="text"
                  width="95%"
                  isRequired={false}
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
                <SubmitButton width="150px">Submit</SubmitButton>
              </form>,
            ]}
          />
        </div>
      )}
      <Table
        title="Contact Types"
        isAdmin
        showAdd
        handleAdd={showModal}
        count={contactTypes.length}
        rowDataProp={contactTypes}
        colDefProp={columnDefs}
      />
    </PageBody>
  );
};

export default AdminIndustries;
