/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useReducer, Reducer, useEffect } from "react";
import { Auth } from "aws-amplify";
import { useMutation } from "@apollo/client";
import { toast } from "react-toastify";
import { Button, SubmitButton } from "../../../theme";
import {
  AdvancedInput,
  CompanyNotFound,
  CategorySelect,
  DeleteItem,
} from "../../";
import { ProjectReducer, INITIAL_PROJECT_STATE } from "../../../utilities";
import {
  CREATE_PROJECT,
  UPDATE_PROJECT,
  GET_PROJECTS,
  DELETE_PROJECT,
} from "../../../api";
import { DataContext } from "../../../context/DataContext";

const AddProject = (props: any) => {
  const id = props.id;
  const editData = props.data;
  const {
    companies,
    contacts,
    industries,
    departments,
    programs,
    users,
    categories,
  } = React.useContext(DataContext);
  const [createNewProject] = useMutation(CREATE_PROJECT);
  const [updateProject] = useMutation(UPDATE_PROJECT);
  const [funders, setFunders] = useState([]);
  const [stage, setStage] = useState(1);
  const [input, setInput] = useState(null);
  const [currentUser, setCurrentUser] = useState(null);
  const [error, setError] = useState(null);
  const [successfullSubmit, setSuccessfullSubmit] = useState(false);
  const [state, dispatch] = useReducer<Reducer<any, any>, any>(
    ProjectReducer,
    INITIAL_PROJECT_STATE(),
    undefined
  );

  useEffect(() => {
    const getActiveUser = async () => {
      const activeUser = await Auth.currentAuthenticatedUser();
      setCurrentUser(activeUser.attributes.sub);
    };
    getActiveUser();
  }, []);

  useEffect(() => {
    if (companies) {
      const funders = companies.filter(
        (item) => item.isFunder === true && item.status !== "Inactive"
      );
      setFunders(funders);
    }
  }, []);

  useEffect(() => {
    const getInputState = async () => setInput(await state);
    getInputState();
  });

  const handlePrevStage = (e) => {
    e.preventDefault();
    setStage((stage) => stage - 1);
  };

  const handleNextStage = (e) => {
    e.preventDefault();
    setStage((stage) => stage + 1);
  };

  const handleValidation = () => {
    let formIsValid = true;
    let errors = {};

    if (!props.isEdit && !input.name) {
      formIsValid = false;
      errors["name"] = "name can not be empty";
    }
    if (input.description.length > 500) {
      formIsValid = false;
      errors["description"] =
        "Description can not be longer than 500 charactors";
    }
    if (!props.isEdit && !input.tagLine) {
      formIsValid = false;
      errors["tagLine"] = "Tagline can not be empty";
    }
    setError({ errors: errors });
    return formIsValid;
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    if (handleValidation()) {
      if (props.isEdit) {
        const updatedInputs = JSON.parse(
          JSON.stringify(input, (key, value) => {
            if (Array.isArray(value) && value.length === 0) {
              return undefined;
            } else if (value === null || value === "") {
              return undefined;
            } else {
              return value;
            }
          })
        );
        updateProject({
          variables: { id, input: updatedInputs },
          refetchQueries: [{ query: GET_PROJECTS }],
        });
        toast.success("Project has successfully been updated");
      } else {
        createNewProject({
          variables: {
            newProject: {
              ...input,
              status: "Pending",
              username: currentUser,
            },
          },
          refetchQueries: [{ query: GET_PROJECTS }],
        });
        toast.success(
          "Project has successfully been created. You can now close the window."
        );
        setSuccessfullSubmit(true);
      }
    } else {
      toast.error(
        "OOPS! that didn't work. Please verify the information and try again"
      );
      setStage(1);
    }
  };

  return (
    <form className="add-item-form" onSubmit={handleSubmit}>
      {stage === 1 && (
        <>
          <AdvancedInput
            {...props}
            name="Project Name"
            type="text"
            width="95%"
            isRequired={props.isEdit ? false : true}
            placeholder={editData?.name}
            value={state.name}
            onChange={(e) =>
              dispatch({ field: "name", payload: e.target.value })
            }
          />
          <span className="error-text">{error?.errors.name}</span>
          <AdvancedInput
            {...props}
            name="Tagline"
            type="text"
            width="95%"
            placeholder={editData?.tagLine}
            isRequired={props.isEdit ? false : true}
            value={state.tagLine}
            onChange={(e) =>
              dispatch({ field: "tagLine", payload: e.target.value })
            }
          />
          <span className="error-text">{error?.errors.tagLine}</span>
          <AdvancedInput
            {...props}
            name="Funder"
            type="dropdown"
            width="95%"
            fieldName="name"
            isMulti
            data={funders}
            value={state.funder}
            onChange={(selectedOptions) =>
              dispatch({
                field: "funder",
                payload: selectedOptions.map((i) => i.id),
              })
            }
          />
          <CompanyNotFound isFunder />
          <AdvancedInput
            {...props}
            name="Program"
            type="dropdown"
            width="95%"
            fieldName="name"
            data={programs}
            value={state.program}
            onChange={(e) =>
              dispatch({
                field: "program",
                payload: e.id,
              })
            }
          />
          <AdvancedInput
            {...props}
            name="team"
            type="dropdown"
            width="95%"
            fieldName="name"
            isMulti
            data={departments}
            value={state.department}
            onChange={(selectedOptions) =>
              dispatch({
                field: "department",
                payload: selectedOptions.map((i) => i.id),
              })
            }
          />
          <AdvancedInput
            {...props}
            name="Dovico Code"
            type="text"
            width="95%"
            value={state.code}
            onChange={(e) =>
              dispatch({ field: "code", payload: e.target.value })
            }
          />
        </>
      )}
      {stage === 2 && (
        <>
          <AdvancedInput
            {...props}
            name="start date"
            type="date"
            width="95%"
            value={state.startDate}
            onChange={(e) =>
              dispatch({ field: "startDate", payload: e.target.value })
            }
          />
          <AdvancedInput
            {...props}
            name="end date"
            type="date"
            width="95%"
            value={state.dueDate}
            onChange={(e) =>
              dispatch({ field: "dueDate", payload: e.target.value })
            }
          />

          <AdvancedInput
            {...props}
            name="Description"
            type="textArea"
            width="95%"
            value={state.description}
            onChange={(e) =>
              dispatch({ field: "description", payload: e.target.value })
            }
          />
          <span className="error-text">{error?.errors.description}</span>
        </>
      )}
      {stage === 3 && (
        <>
          <AdvancedInput
            {...props}
            name="Project Lead"
            type="dropdown"
            width="95%"
            fieldName="name"
            isMulti
            data={users}
            value={state.lead}
            onChange={(selectedOptions) =>
              dispatch({
                field: "lead",
                payload: selectedOptions.map((i) => i.id),
              })
            }
          />
          <AdvancedInput
            {...props}
            name="Team Members"
            data={users}
            isMulti
            fieldName="name"
            type="dropdown"
            width="95%"
            value={state.members}
            onChange={(selectedOptions) =>
              dispatch({
                field: "members",
                payload: selectedOptions.map((i) => i.id),
              })
            }
          />
          <AdvancedInput
            {...props}
            name="Co-operators"
            type="dropdown"
            width="95%"
            fieldName="name"
            isMulti
            data={companies}
            value={state.coops}
            onChange={(selectedOptions) =>
              dispatch({
                field: "coops",
                payload: selectedOptions.map((i) => i.id),
              })
            }
          />
          <AdvancedInput
            {...props}
            name="main funder contacts"
            type="dropdown"
            width="95%"
            fieldName="name"
            isMulti
            data={contacts}
            value={state.contacts}
            onChange={(selectedOptions) =>
              dispatch({
                field: "contacts",
                payload: selectedOptions.map((i) => i.id),
              })
            }
          />
          <AdvancedInput
            {...props}
            name="industries"
            data={industries}
            isMulti
            fieldName="name"
            type="dropdown"
            width="95%"
            value={state.industries}
            onChange={(selectedOptions) =>
              dispatch({
                field: "industries",
                payload: selectedOptions.map((i) => i.id),
              })
            }
          />
          <CategorySelect
            data={categories}
            value={state.categories}
            handleOnChange={(selectedCategories) =>
              dispatch({
                field: "categories",
                payload: selectedCategories,
              })
            }
          />
        </>
      )}
      <div className="add-item-button-container">
        {stage === 1 ? (
          <div style={{ width: "150px" }} />
        ) : (
          <Button width="150px" onClick={handlePrevStage}>
            Go Back
          </Button>
        )}
        {stage !== 3 ? (
          <Button width="150px" onClick={handleNextStage}>
            Next
          </Button>
        ) : successfullSubmit ? (
          <Button width="150px" disabled={successfullSubmit}>
            Success
          </Button>
        ) : (
          <SubmitButton width="150px" disabled={successfullSubmit}>
            Submit
          </SubmitButton>
        )}
      </div>
      {stage === 3 && props.isEdit ? (
        <DeleteItem
          id={id}
          user={props.user}
          mutation={DELETE_PROJECT}
          refetch={GET_PROJECTS}
          type="Project"
        />
      ) : null}
    </form>
  );
};

export default AddProject;
