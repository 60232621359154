import React from "react";
import { useNavigate } from "react-router-dom";

const Logo = () => {
  const navigate = useNavigate();
  const goHome = () => {
    navigate("/");
  };

  return (
    <div className="logo-background" data-testid="logo">
      <img
        className="logo"
        src={process.env.PUBLIC_URL + "/images/Perennia-icon.svg"}
        aria-label="Perennia Food and Agriculture"
        onClick={goHome}
      />
    </div>
  );
};

export default Logo;
