import React, { useState, useEffect } from "react";
import { useQuery, useMutation } from "@apollo/client";
import { toast } from "react-toastify";
import { Table, AdvancedInput, InfoCard } from "../../components";
import {
  GET_DEPARTMENTS,
  CREATE_DEPARTMENT,
  UPDATE_DEPARTMENT,
  DELETE_DEPARTMENT,
} from "../../api";
import { DateFormatter } from "../../utilities";
import { SubmitButton, PageBody } from "../../theme";

// July 21, 2022 change request
// Change name from Departments to Teams
// ** Only reflected in input and page name **

const AdminDepartments = (props: any) => {
  const { data } = useQuery(GET_DEPARTMENTS);
  const [departments, setDepartments] = useState([]);
  const [modal, setModal] = useState(false);
  const [name, setName] = useState("");
  const [code, setCode] = useState("");
  const [addIndustry] = useMutation(CREATE_DEPARTMENT);
  const [columnDefs] = useState([
    {
      field: "name",
      headerName: "Name",
      filter: true,
      sortable: true,
      minWidth: 500,
      width: 500,
    },
    {
      field: "code",
      headerName: "Code",
      minWidth: 150,
      width: 150,
    },
    {
      field: "formattedDate",
      headerName: "Created At",
      minWidth: 150,
      width: 150,
    },
    {
      field: "id",
      headerName: "Actions",
      width: 100,
      cellRendererSelector: () => ({
        component: "actionsRenderer",
        params: {
          id: "id",
          refetchQuery: GET_DEPARTMENTS,
          updateMutation: UPDATE_DEPARTMENT,
          deleteMutation: DELETE_DEPARTMENT,
          isTeams: true,
        },
      }),
    },
  ]);

  const showModal = () => setModal(!modal);

  useEffect(() => {
    if (data) {
      setDepartments(
        data?.getAllDepartments.departments.map((item) => ({
          ...item,
          formattedDate: DateFormatter({ createdAt: item.createdAt }),
        }))
      );
    }
  }, [data]);

  const handleAdd = (e) => {
    e.preventDefault();
    const notify = () => toast.success("Team successfully added");
    addIndustry({
      variables: {
        newDepartment: {
          name: name,
          code: code,
        },
      },
      refetchQueries: [{ query: GET_DEPARTMENTS }],
    });
    notify();
    setName("");
    setCode("");
    setModal(false);
  };

  return (
    <PageBody>
      {modal && (
        <div
          className={modal ? "admin-add-modal" : "admin-add-modal is-notActive"}
        >
          <InfoCard
            title="Create new Team"
            width="350px"
            headerOption="modal"
            onBtnClick={showModal}
            backgroundColor="white"
            fields={[
              <form className="admin-edit-add-new-item" onSubmit={handleAdd}>
                <AdvancedInput
                  {...props}
                  name="Team Name"
                  fieldName="name"
                  type="text"
                  width="95%"
                  isRequired={false}
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
                <AdvancedInput
                  {...props}
                  name="Code"
                  fieldName="code"
                  type="text"
                  width="95%"
                  isRequired={false}
                  value={code}
                  onChange={(e) => setCode(e.target.value)}
                />
                <SubmitButton width="150px">Submit</SubmitButton>
              </form>,
            ]}
          />
        </div>
      )}
      <Table
        title="Teams"
        isAdmin
        showAdd
        handleAdd={showModal}
        count={departments.length}
        rowDataProp={departments}
        colDefProp={columnDefs}
      />
    </PageBody>
  );
};

export default AdminDepartments;
