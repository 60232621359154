import React, {
  useEffect,
  useState,
  useRef,
  useCallback,
  useMemo,
} from "react";
import "ag-grid-enterprise";
import { LicenseManager } from "ag-grid-enterprise";
import { AgGridReact } from "ag-grid-react";
import * as AiIcons from "react-icons/ai";
import { PageBody, Button, SubmitButton } from "../../theme";
import {
  EyeRenderer,
  LinkRenderer,
  StatusRenderer,
  ActionsRenderer,
  FunderRenderer,
  StatusRendererPRS,
} from "..";

//  Enterprise License
LicenseManager.setLicenseKey(
  "CompanyName=Perennia Food and Agriculture,LicensedApplication=Perennia CRM,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=0,AssetReference=AG-028273,ExpiryDate=16_May_2023_[v2]_MTY4NDE5MTYwMDAwMA==3327d87add90c2a8d0603eee54681512"
);

const Table = (props: any) => {
  const gridRef = useRef(null);
  const [gridApi, setGridApi] = useState(null);
  const [columnSelect, setColumnSelect] = useState(false);
  const showColumnSelect = () => setColumnSelect(!columnSelect);

  useEffect(() => {
    if (gridApi) {
      gridApi.sizeColumnsToFit();
    }
  });

  const defaultColDef = useMemo(() => {
    return {
      sortable: true,
      filter: true,
      resizable: true,
      enableValue: true,
      enableRowGroup: true,
      enablePivot: true,
    };
  }, []);

  const onGridReady = (params: any) => {
    setGridApi(params.api);
  };

  const autoSizeColumns = useCallback(() => {
    gridRef.current.api.sizeColumnsToFit();
  }, []);

  const handleExport = useCallback(() => {
    gridRef.current.api.exportDataAsExcel({
      allColumns: true,
    });
  }, []);

  return (
    <PageBody>
      <div className="table-container">
        <div className="table-page-header">
          <p>{props.title}</p>
        </div>
        <div className="table-btn-container">
          {props.showAdd && (
            <SubmitButton
              style={{ minWidth: "150px" }}
              onClick={props.handleAdd}
            >
              Add {props.title}
            </SubmitButton>
          )}
          <Button width="100px" onClick={showColumnSelect}>
            Toolbar
          </Button>
          <Button width="75px" onClick={handleExport}>
            <span>
              <AiIcons.AiOutlineDownload />
            </span>
            Export
          </Button>
        </div>
        <div
          className={columnSelect ? "table-grid toolbar-active" : "table-grid"}
        >
          <AgGridReact
            {...props}
            className="ag-theme-alpine"
            ref={gridRef}
            rowData={props.rowDataProp}
            columnDefs={props.colDefProp}
            defaultColDef={defaultColDef}
            onGridReady={onGridReady}
            onFirstDataRendered={autoSizeColumns}
            suppressRowClickSelection
            pagination
            paginationAutoPageSize
            suppressRowTransform
            sideBar={columnSelect}
            treeData={props.treeData}
            frameworkComponents={{
              eyeRenderer: EyeRenderer,
              linkRenderer: LinkRenderer,
              statusRenderer: StatusRenderer,
              StatusRendererPRS: StatusRendererPRS,
              actionsRenderer: ActionsRenderer,
              funderRenderer: FunderRenderer,
            }}
          />
        </div>
      </div>
    </PageBody>
  );
};

export default Table;
