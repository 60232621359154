/* eslint-disable array-callback-return */
import React, { useState, useEffect } from "react";
import { useQuery } from "@apollo/client";
import {
  GET_INDUSTRIES,
  GET_CONTACTTYPES,
  GET_CATEGORIES,
  GET_CERTIFICATIONS,
  GET_USERS,
} from "../../api";

const Tags = (props: any) => {
  const { data: certificationsData } = useQuery(GET_CERTIFICATIONS);
  const { data: industryData } = useQuery(GET_INDUSTRIES);
  const { data: contactTypeData } = useQuery(GET_CONTACTTYPES);
  const { data: categoryData } = useQuery(GET_CATEGORIES);
  const { data: userData } = useQuery(GET_USERS);
  const [certifications, setCertifications] = useState([]);
  const [industries, setIndustries] = useState([]);
  const [contactTypes, setContactTypes] = useState([]);
  const [categories, setCategories] = useState([]);
  const [users, setUsers] = useState([]);

  useEffect(() => {
    const certificationsMatches =
      certificationsData?.getAllCertification.certifications.filter(
        (certifications) => {
          if (
            props.data?.certifications
              ?.map((item) => item)
              .includes(certifications.id)
          ) {
            return certifications;
          }
        }
      );
    const industryMatches = industryData?.getAllIndustries.industries.filter(
      (industry) => {
        if (props.data?.industries?.map((item) => item).includes(industry.id)) {
          return industry;
        }
      }
    );
    const contactTypeMatches =
      contactTypeData?.getAllContactTypes.contactTypes.filter((contactType) => {
        if (
          props.data?.contactTypes?.map((item) => item).includes(contactType.id)
        ) {
          return contactType;
        }
      });
    const categoryMatches = categoryData?.getAllCategories.categories.filter(
      (category) => {
        if (props.data?.categories?.map((item) => item).includes(category.id)) {
          return category;
        }
      }
    );
    const specialistMatches = userData?.getAllUsers.users.filter((user) => {
      if (props.data?.specialist?.map((item) => item).includes(user.id)) {
        return user;
      }
    });
    setCertifications(certificationsMatches);
    setIndustries(industryMatches);
    setContactTypes(contactTypeMatches);
    setCategories(categoryMatches);
    setUsers(specialistMatches);
  }, [
    certificationsData,
    industryData,
    contactTypeData,
    categoryData,
    userData,
    props.data,
  ]);

  return (
    <div className="tags-container">
      {props.type === "company" && (
        <div className="tags-item">
          <div className="tags-item-header">
            <h3>Certifications</h3>
          </div>
          <div>
            {certifications?.map((item) => (
              <p key={item.id}>{item.name}</p>
            ))}
          </div>
        </div>
      )}
      <div className="tags-item">
        <div className="tags-item-header">
          <h3>Industries</h3>
        </div>
        <div>
          {industries?.map((item) => (
            <p key={item.id}>{item.name}</p>
          ))}
        </div>
      </div>
      {props.type === "service" ||
      props.type === "project" ||
      props.type === "program" ? (
        <div />
      ) : (
        <>
          <div className="tags-item">
            <div className="tags-item-header">
              <h3>Contact Types</h3>
            </div>
            <div>
              {contactTypes?.map((item) => (
                <p key={item.id}>{item.name}</p>
              ))}
            </div>
          </div>
          <div className="tags-item">
            <div className="tags-item-header">
              <h3>Specialist</h3>
            </div>
            <div>
              {users?.map((item) => (
                <p key={item.id}>{item.name}</p>
              ))}
            </div>
          </div>
        </>
      )}
      {props.type === "program" ? (
        <div />
      ) : (
        <div className="tags-item">
          <div className="tags-item-header">
            <h3>Categories</h3>
          </div>
          <div>
            {categories?.map((item) => (
              <p key={item.id}>{item.name}</p>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default Tags;
