/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useContext, useEffect } from "react";
import { Category, CategoryModal } from "../../components";
import { Button, PageBody } from "../../theme";
import { DataContext } from "../../context/DataContext";

const AdminCategories = (props: any) => {
  const { categories, setCategories } = useContext(DataContext);
  const [modal, setModal] = useState(false);

  useEffect(() => {
    if (categories) {
      setCategories(
        categories.map((cat) => ({
          ...cat,
          hasChildren: categories.filter((i) => i.parent === cat.id).length > 0,
        }))
      );
    }
  }, []);

  return (
    <>
      {modal && <CategoryModal isAdd />}
      <PageBody>
        <div className="admin-cat-header">
          <p>Categories</p>
          <div className="admin-cat-header-btn-container">
            <Button width="150px" onClick={() => setModal(!modal)}>
              Add Category
            </Button>
          </div>
        </div>
        <Category categories={categories} />
      </PageBody>
    </>
  );
};

export default AdminCategories;
