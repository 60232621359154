import { gql } from "@apollo/client";

export const SEARCH_COMPANIES = gql`
    query getAllContacts {
        getAllCompanies {
            companies {
                id
                name
            }
        }
    }
`;

export const SEARCH_CONTACTS = gql`
    query getAllContacts {
        getAllContacts {
            contacts {
                id
                name
            }
        }
    }
`;

export const SEARCH_PROGRAMS = gql`
    query getAllPrograms {
        getAllPrograms {
            programs {
                id
                name
            }
        }
    }
`;

export const SEARCH_PROJECTS = gql`
    query  getAllProjects {
        getAllProjects {
            projects {
                id
                name
            }
        }
    }
`;

export const SEARCH_RESOURCES = gql`
    query getAllResources {
        getAllResources {
            resources {
                id
                name
            }
        }
    }
`;

export const SEARCH_SERVICES = gql`
    query getAllServices {
        getAllServices {
            services {
                id
                name
            }
        }
    }
`;