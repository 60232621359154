export const INITIAL_SERVICE_STATE = () => {
  return {
    name: "",
    tagLine: "",
    type: "",
    program: "",
    department: [],
    code: "",
    company: [],
    lead: [],
    members: [],
    status: "",
    contacts: [],
    approvedBy: "",
    approvedByDate: "",
    startDate: "",
    dueDate: "",
    industries: [],
    categories: [],
    notes: [],
    deliverables: [],
    description: "",
    qoutedAmount: "",
    invoicedAmount: "",
    invoices: [],
    paymentTerm: "",
    downPayment: false,
    hasNda: false,
    username: "",
  };
};

export const ServiceReducer = (state, action) => {
  switch (action.field) {
    case "name":
    case "tagLine":
    case "type":
    case "program":
    case "department":
    case "code":
    case "company":
    case "lead":
    case "members":
    case "status":
    case "contacts":
    case "approvedBy":
    case "approvedByDate":
    case "startDate":
    case "dueDate":
    case "industries":
    case "categories":
    case "notes":
    case "deliverables":
    case "description":
    case "qoutedAmount":
    case "invoicedAmount":
    case "invoices":
    case "paymentTerm":
    case "username":
      return {
        ...state,
        [action.field]: action.payload,
      };
    case "downPayment":
      return {
        ...state,
        downPayment: !state.downPayment,
      };
    case "paymentAlert":
      return {
        ...state,
        paymentAlert: !state.paymentAlert,
      };
    case "hasNda":
      return {
        ...state,
        hasNda: !state.hasNda,
      };
    default:
      return;
  }
};
