import React from "react";
import * as FaIcons from "react-icons/fa";
import * as MdIcons from "react-icons/md";
import * as RiIcons from "react-icons/ri";
import * as SiIcons from "react-icons/si";

export const AdminBtnData = [
  {
    title: "Categories",
    path: "categories-edit",
    icon: <FaIcons.FaTags />,
  },
  {
    title: "Industries",
    path: "industries-edit",
    icon: <FaIcons.FaBuilding />,
  },
  {
    title: "Teams",
    path: "departments-edit",
    icon: <FaIcons.FaBriefcase />,
  },
  {
    title: "Contact-Types",
    path: "contact-types-edit",
    icon: <FaIcons.FaBarcode />,
  },
  {
    title: "Certifications",
    path: "certifications-edit",
    icon: <FaIcons.FaAward />,
  },
  {
    title: "Interaction-Categories",
    path: "interaction-category-edit",
    icon: <SiIcons.SiGithubactions />,
  },
  {
    title: "Interaction-Types",
    path: "interaction-type-edit",
    icon: <RiIcons.RiChatNewLine />,
  },
  {
    title: "Resource-Types",
    path: "resource-types-edit",
    icon: <RiIcons.RiStackLine />,
  },
  {
    title: "Service-Types",
    path: "service-types-edit",
    icon: <MdIcons.MdMiscellaneousServices />,
  },
  {
    title: "Users",
    path: "users-edit",
    icon: <FaIcons.FaUsers />,
    userLevel: "Uber User",
  },
];
