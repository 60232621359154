/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import React, { useState, useEffect, useRef, useContext } from "react";
import { AgGridReact } from "ag-grid-react";
import { DataContext } from "../../context/DataContext";
import { MultipleIdToNameFormatter, IdToNameFormatter } from "../../utilities";
import { EyeRenderer } from "..";

const InteractionToSingleItem = (props: any) => {
  const { interactions, contacts, interactionCategories } =
    useContext(DataContext);
  const [items, setItems] = useState([]);
  const [gridApi, setGridApi] = useState(null);
  const gridRef = useRef(null);
  const [columnDefs] = useState([
    {
      field: "formattedDate",
      headerName: "Date",
      filter: true,
      sortable: true,
      minWidth: 100,
      width: 100,
    },
    {
      field: "username",
      headerName: "Team Member",
      filter: true,
      sortable: true,
      minWidth: 100,
      width: 100,
    },
    {
      field: "actionType",
      headerName: "Type",
      filter: true,
      sortable: true,
      minWidth: 100,
      width: 100,
    },
    {
      field: "formattedContact.name",
      headerName: "Contact",
      filter: true,
      sortable: true,
      minWidth: 150,
      width: 150,
      hide: props.type === "contact" ? true : false,
    },
    {
      field: "formattedCategories",
      headerName: "Category",
      filter: true,
      sortable: true,
      minWidth: 150,
      width: 150,
    },
    {
      headerName: "View",
      field: "sourceId",
      cellRendererSelector: (params) => {
        return {
          component: "eyeRenderer",
          params: {
            url: "/directory/contacts",
          },
        };
      },
      width: 100,
      minWidth: 100,
    },
  ]);

  useEffect(() => {
    if (props.type === "company") {
      const filteredContacts = contacts.filter((item) => {
        if (item?.companyName === props.id) {
          return item;
        }
      });
      const linkedItems = interactions.filter((item) => {
        if (filteredContacts?.some((el) => el.id === item.contact)) {
          return item;
        }
      });
      setItems(
        linkedItems?.map((item) => ({
          ...item,
          formattedDate: item.createdAt?.slice(0, 10),
          formattedContact: IdToNameFormatter({
            data: filteredContacts,
            fieldToMatch: item.contact,
          }),
          formattedCategories: MultipleIdToNameFormatter({
            data: interactionCategories,
            fieldToMatch: item.category,
          }),
        }))
      );
    }
    if (props.type === "program/project") {
      const linkedItems = interactions.filter((item) => {
        if (item?.programOrProject === props?.id) {
          return item;
        }
      });
      setItems(
        linkedItems?.map((item) => ({
          ...item,
          formattedDate: item.createdAt?.slice(0, 10),
          formattedContact: IdToNameFormatter({
            data: contacts,
            fieldToMatch: item.contact,
          }),
          formattedCategories: MultipleIdToNameFormatter({
            data: interactionCategories,
            fieldToMatch: item.category,
          }),
        }))
      );
    }
  }, []);

  const onGridReady = (params: any) => {
    setGridApi(params.api);
  };

  useEffect(() => {
    if (gridApi) {
      gridApi.sizeColumnsToFit();
    }
  });

  return (
    <div className="ag-theme-alpine" id="activePRS-table">
      <AgGridReact
        ref={gridRef}
        rowData={items}
        columnDefs={columnDefs}
        onGridReady={onGridReady}
        suppressRowClickSelection={true}
        frameworkComponents={{
          eyeRenderer: EyeRenderer,
        }}
      />
    </div>
  );
};

export default InteractionToSingleItem;
