import React, { useState, useEffect } from "react";
import { Auth } from "aws-amplify";
import { useMutation } from "@apollo/client";
import { toast } from "react-toastify";
import * as AiIcons from "react-icons/ai";
import { AddNote, Note } from "..";
import { CREATE_NOTE, UPDATE_NOTE, DELETE_NOTE } from "../../api";

const Notes = (props: any) => {
  const [notes, setNotes] = useState([]);
  const [activeNote, setActiveNote] = useState(null);
  const [modal, setModal] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);
  const [createNote] = useMutation(CREATE_NOTE);
  const [editNote] = useMutation(UPDATE_NOTE);
  const [removeNote] = useMutation(DELETE_NOTE);
  const rootNotes = notes.filter((note) => note?.parent === null);

  useEffect(() => {
    getActiveUser();
  }, [currentUser]);

  useEffect(() => {
    if (props.data) setNotes(props.data);
  }, [props.data]);

  const getActiveUser = async () => {
    const activeUser = await Auth.currentAuthenticatedUser();
    setCurrentUser(activeUser.attributes["custom:fullName"]);
  };

  const showModal = () => setModal(!modal);

  const getReplies = (noteId) =>
    notes
      .filter((note) => note.parent === noteId)
      .sort(
        (a, b) =>
          new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
      );

  const addNote = (description, parent) => {
    const notify = () =>
      toast.success(`Thank you ${currentUser}, your note has been recorded`);
    createNote({
      variables: {
        sourceId: props.id,
        newNote: {
          username: currentUser,
          body: description,
          parent: parent,
        },
      },
      refetchQueries: [{ query: props.refetchQuery }],
    }).then((note) => {
      setNotes([note.data.createNote, ...notes]);
      setActiveNote(null);
    });
    notify();
  };

  const updateNote = (description, noteId) => {
    const notify = () =>
      toast.success(`Thank you ${currentUser}, your note has been updated`);
    editNote({
      variables: {
        sourceId: props.id,
        id: noteId,
        input: { body: description },
      },
      refetchQueries: [{ query: props.refetchQuery }],
    }).then(() => {
      const updatedNotes = notes.map((note) => {
        if (note.id === noteId) {
          return {
            ...note,
            body: description,
          };
        }
        return note;
      });
      setNotes(updatedNotes);
      setActiveNote(null);
    });
    notify();
  };

  const deleteNote = (noteId) => {
    if (window.confirm("Are you sure you want to remove this Note?")) {
      removeNote({
        variables: {
          sourceId: props.id,
          id: noteId,
        },
        refetchQueries: [{ query: props.refetchQuery }],
      }).then(() => {
        setNotes((notes) => notes.filter((note) => note.id !== noteId));
      });
    }
  };

  return (
    <>
      {modal && <AddNote handleSubmit={addNote} submitLabel="Submit" />}
      <div className="notes-container">
        <div className="notes-header">
          <div className="notes-button-container">
            <div />
            {/* <p>
                        <AiIcons.AiOutlineSearch />
                    </p> */}
            <p>
              <AiIcons.AiOutlineAppstoreAdd onClick={showModal} />
            </p>
          </div>
        </div>
        {rootNotes.map((item) => (
          <Note
            key={item.id}
            note={item}
            replies={getReplies(item.id)}
            currentUser={currentUser}
            addNote={addNote}
            activeNote={activeNote}
            setActiveNote={setActiveNote}
            updateNote={updateNote}
            deleteNote={deleteNote}
            parent={item.id}
          />
        ))}
      </div>
    </>
  );
};

export default Notes;
