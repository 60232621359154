import { Link } from "react-router-dom";
import styled from "styled-components";

export const TableIcon = styled(Link).attrs((props) => ({
  color: props.color,
}))`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 75px;
  height: 42px;
  color: ${(props) => props.color};
`;
