import React, { useState, useRef, useEffect } from "react";
import { Auth } from "aws-amplify";
import IdleTimer from "react-idle-timer";
import { InfoCard } from "..";
import { Button, SubmitButton } from "../../theme";

const IdleSession = () => {
  const [modal, setModal] = useState(false);
  const [count, setCount] = useState(10);
  const [isIdle, setIsIdle] = useState(false);
  const showModal = () => setModal(!modal);
  const idleRef = useRef(null);
  const autoLogoutRef = useRef(null);

  useEffect(() => {
    if (isIdle === true) {
      const countDown = setInterval(() => {
        setCount(count - 1);
      }, 1000);
      return () => clearInterval(countDown);
    }
  }, [count, isIdle]);

  const onIdle = () => {
    showModal();
    setIsIdle(true);
    console.log("session is idle");
    autoLogoutRef.current = setTimeout(handleLogout, 10 * 1000);
  };

  const handleLogout = async () => {
    clearTimeout(autoLogoutRef.current);
    setIsIdle(false);
    setCount(10);
    await Auth.signOut();
    window.location.href = "/";
    console.log("Signed out");
  };

  const handleClose = (e) => {
    e.preventDefault();
    setIsIdle(false);
    setCount(10);
    clearTimeout(autoLogoutRef.current);
    setModal(!modal);
  };

  return (
    <>
      <div className={modal ? "idle-session is-active" : "idle-session"}>
        <InfoCard
          className="idle-session-modal"
          title=""
          headerOption="modal"
          height="auto"
          width="350px"
          backgroundColor="white"
          onBtnClick={handleClose}
          fields={[
            <div key="idle-timer" className="idle-timer-warning-container">
              <div className="idle-session-warning">
                <img
                  className="idle-icon"
                  src={process.env.PUBLIC_URL + "/images/sessionTimeout.jpg"}
                  alt=""
                />
                <p>
                  You've been away for a while. You will be automatically logged
                  out in {count} seconds.
                </p>
              </div>
              <div className="idle-session-btn-container">
                <Button width="150px" onClick={handleLogout}>
                  Log out
                </Button>
                <SubmitButton width="150px" onClick={handleClose}>
                  Stay Logged In
                </SubmitButton>
              </div>
            </div>,
          ]}
        />
      </div>
      <IdleTimer ref={idleRef as any} onIdle={onIdle} timeout={60 * 20000} />
    </>
  );
};

export default IdleSession;
