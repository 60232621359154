/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import React, { useState, useEffect, useRef, useCallback } from "react";
import { AgGridReact } from "ag-grid-react";
import { IdToNameFormatter, MultipleIdToNameFormatter } from "../../utilities";
import { DataContext } from "../../context/DataContext";
import { EyeRenderer } from "..";
import * as AiIcons from "react-icons/ai";

const ActiveInteractions = (props: any) => {
  const {
    interactions,
    contacts,
    interactionCategories,
    programs,
    projects,
    services,
  } = React.useContext(DataContext);
  const [toolbar, setToolbar] = useState(false);
  const [items, setItems] = useState([]);
  const [programOrProjects, setProgramOrProjects] = useState([]);
  const [gridApi, setGridApi] = useState(null);
  const gridRef = useRef(null);
  const [columnDefs] = useState([
    {
      headerName: "View",
      field: "sourceId",
      cellRendererSelector: (params) => {
        return {
          component: "eyeRenderer",
          params: {
            url: "/directory/contacts",
          },
        };
      },
      width: 100,
      minWidth: 100,
      hide: false,
    },
    {
      field: "username",
      headerName: "Team Member",
      filter: true,
      sortable: true,
      minWidth: 100,
      width: 100,
      hide: true,
    },
    {
      field: "actionType",
      headerName: "Type",
      filter: true,
      sortable: true,
      minWidth: 100,
      width: 100,
    },
    {
      field: "formattedContact.name",
      headerName: "Contact",
      filter: true,
      sortable: true,
      minWidth: 150,
      width: 150,
    },
    {
      field: "formattedCategories",
      headerName: "Category",
      filter: true,
      sortable: true,
      minWidth: 150,
      width: 150,
    },
    {
      field: "formattedProgramOrProject",
      headerName: "Program / Project / Consultation",
      filter: true,
      sortable: true,
      minWidth: 150,
      width: 150,
    },
    {
      field: "formattedDate",
      headerName: "Date",
      filter: true,
      sortable: true,
      minWidth: 100,
      width: 100,
    },
  ]);

  // Combine program and project data into one array
  useEffect(() => {
    if (programs && projects && services) {
      setProgramOrProjects([...programs, ...projects, ...services]);
    }
  }, [programs, projects]);

  // Get the program or project name from the id
  const getProgramOrProjectName = useCallback(
    (id) => {
      const programOrProject = programOrProjects.find(
        (programOrProject) => programOrProject.id === id
      );
      return programOrProject?.name;
    },
    [programOrProjects]
  );

  // Item pair handler
  useEffect(() => {
    if (!props.isManager) {
      const linkedItem = interactions.filter((item) => {
        if (item?.username?.includes(props.user?.attributes["custom:fullName"]))
          return item;
      });

      setFormattedItems(linkedItem);
    } else {
      const linkedItems = interactions.filter((item) => {
        // Check if the username matches one of the teamMembers or the user themselves
        return (
          props?.teamMembers.some((value) => item?.username === value.name) ||
          item?.username?.includes(props.user?.attributes["custom:fullName"])
        );
      });

      setFormattedItems(linkedItems);
    }
  }, [
    props.isManager,
    props.user?.attributes["custom:fullName"],
    interactions,
    contacts,
    interactionCategories,
    getProgramOrProjectName,
    props.teamMembers,
  ]);

  const setFormattedItems = (itemsArray) => {
    setItems(
      itemsArray?.map((item) => ({
        ...item,
        formattedDate: item.createdAt?.slice(0, 10),
        formattedContact: IdToNameFormatter({
          data: contacts,
          fieldToMatch: item.contact,
        }),
        formattedProgramOrProject: getProgramOrProjectName(
          item.programOrProject
        ),
        formattedCategories: MultipleIdToNameFormatter({
          data: interactionCategories,
          fieldToMatch: item.category,
        }),
      }))
    );
  };

  const showToolbar = () => setToolbar(!toolbar);

  // Table auto-scaler
  const onGridReady = (params: any) => {
    setGridApi(params.api);
  };

  useEffect(() => {
    if (gridApi) {
      gridApi.sizeColumnsToFit();
    }
  });

  const handleExport = useCallback(() => {
    gridRef.current.api.exportDataAsExcel({
      allColumns: true,
    });
  }, []);

  return (
    <div
      className={
        props.isManager ? "items-table-container" : "prs-table-container"
      }
    >
      {props.isManager && (
        <div className="items-table-header">
          <div className="items-table-button-container">
            <div />
            <p title="search">
              <AiIcons.AiOutlineSearch onClick={showToolbar} />
            </p>
            <p title="export">
              <AiIcons.AiOutlineDownload onClick={handleExport} />
            </p>
          </div>
        </div>
      )}
      <div
        className="ag-theme-alpine"
        id={props.isManager ? "items-table" : "activePRS-table"}
      >
        <AgGridReact
          ref={gridRef}
          rowData={items}
          columnDefs={columnDefs}
          onGridReady={onGridReady}
          suppressRowClickSelection={true}
          sideBar={toolbar ? "filters" : toolbar}
          frameworkComponents={{
            eyeRenderer: EyeRenderer,
          }}
        />
      </div>
    </div>
  );
};

export default ActiveInteractions;
