import React, { useState, useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import { SwiperSlide, Swiper } from "swiper/react";
import SwiperCore, { Navigation } from "swiper/core";
import { DataContext } from "../../context/DataContext";

SwiperCore.use([Navigation]);

const ContactToCompany = (props: any) => {
  const { contacts } = useContext(DataContext);
  const [pairedContacts, setPairedContacts] = useState([]);
  const [data, setData] = useState(props.data);

  useEffect(() => {
    setData(props.data);
  }, [props.data]);

  useEffect(() => {
    if (data && contacts && contacts.length > 0) {
      const filteredContacts = contacts.filter((contact: any) => {
        return contact?.companyName?.includes(data);
      });
      setPairedContacts(filteredContacts);
    }
  }, [data, contacts]);

  return (
    <div>
      <Swiper
        className="contact-swiper"
        slidesPerView={1}
        spaceBetween={30}
        slidesPerGroup={1}
        loopFillGroupWithBlank
        navigation
      >
        {pairedContacts.map((contact: any) => (
          <SwiperSlide className="contact-card" key={contact.id}>
            <div className="contact-card-header">
              <p>{contact.name}</p>
              <Link key={contact.id} to={`/directory/contacts/${contact.id}`}>
                View Contact
              </Link>
            </div>
            <div className="contact-card-body">
              {contact.isPrimary ? (
                <div className="contact-isPrimary">
                  <h6>Primary Contact</h6>
                </div>
              ) : (
                <div className="contact-isNotPrimary" />
              )}
              <p>
                Division:
                <span>{contact.division}</span>
              </p>
              <p>
                Job Title:
                <span>{contact.jobTitle}</span>
              </p>
              <p>
                Phone:
                <span>
                  <a href={`tel://${contact.phone}`}>{contact.phone}</a>
                </span>
              </p>
              <p>
                Email:
                <span>
                  <a href={`mailto://${contact.primaryEmail}`}>
                    {contact.primaryEmail}
                  </a>
                </span>
              </p>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default ContactToCompany;
