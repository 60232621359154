export const ServiceSchema = [
  {
    headerName: "Status",
    field: "status",
    cellRenderer: "statusRenderer",
    textAlign: "center",
    width: 24,
    minWidth: 24,
    hide: false,
    sortable: true,
  },
  {
    headerName: "View",
    field: "id",
    cellRendererSelector: (params) => {
      return {
        component: "eyeRenderer",
        params: { url: "/directory/services" },
      };
    },
    textAlign: "center",
    width: 100,
    minWidth: 100,
    hide: false,
  },
  {
    headerName: "Service Name",
    field: "name",
    renderer: "linkRenderer",
    url: "directory/services/",
    filter: true,
    value: "name",
    width: 300,
    minWidth: 300,
    hide: false,
  },
  {
    headerName: "Tagline",
    field: "tagLine",
    filter: true,
    width: 200,
    minWidth: 200,
    hide: false,
  },
  {
    headerName: "Service Code",
    field: "code",
    filter: true,
    width: 250,
    minWidth: 250,
    hide: false,
  },
  {
    headerName: "Type",
    field: "type",
    filter: true,
    width: 250,
    minWidth: 250,
    hide: false,
  },
  {
    headerName: "Program",
    field: "formattedProgram.name",
    filter: true,
    width: 200,
    minWidth: 200,
    hide: true,
  },
  {
    headerName: "Team",
    field: "formattedTeam.name",
    filter: true,
    width: 200,
    minWidth: 200,
    hide: false,
  },
  {
    headerName: "Service Lead",
    field: "formattedLead",
    filter: true,
    width: 300,
    minWidth: 300,
    hide: false,
  },
  {
    headerName: "Team Members",
    field: "formattedTeamMembers",
    filter: true,
    width: 300,
    minWidth: 300,
    hide: false,
  },
  {
    headerName: "Company",
    field: "formattedCompany",
    filter: true,
    width: 300,
    minWidth: 300,
    hide: false,
  },
  {
    headerName: "Industries",
    field: "formattedIndustries",
    filter: true,
    width: 200,
    minWidth: 200,
    hide: true,
  },
  {
    headerName: "Categories",
    field: "formattedCategories",
    filter: true,
    width: 200,
    minWidth: 200,
    hide: true,
  },
  {
    headerName: "Qouted Amount",
    field: "qoutedAmount",
    filter: true,
    width: 200,
    minWidth: 200,
    hide: true,
  },
  {
    headerName: "Start Date",
    field: "startDate",
    filter: true,
    width: 150,
    minWidth: 150,
    hide: false,
  },
  {
    headerName: "End Date",
    field: "dueDate",
    filter: true,
    width: 150,
    minWidth: 150,
    hide: false,
  },
];
