import React, { useState, useEffect } from "react";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import AsyncSelect from "react-select";
import * as FiIcons from "react-icons/fi";

const AdvancedInput = (props: any) => {
  const options = props.data;
  const animatedComponents = makeAnimated();
  const [countries, setCountries] = useState([]);
  const [charCount, setCharCount] = useState(0);

  useEffect(() => {
    let isMounted = true;
    const getCountries = async () => {
      const response = await fetch(
        `https://restcountries.com/v2/all?fields=${props.fieldName}`
      );
      const data = await response.json();
      if (isMounted) setCountries(data);
    };
    getCountries();

    return () => {
      isMounted = false;
    };
  }, [props.fieldName]);

  return (
    <div
      style={{ width: props.width }}
      data-testid="advInput"
      className={props.conditional ? "inputField is-notActive" : "inputField"}
    >
      {props.type === "select" ? (
        <div className="inputField-select">
          {props.name}?
          <div className="toggle-switch">
            <input
              type="checkbox"
              className="checkbox"
              name={props.name}
              id={props.name}
              onChange={props.onChange}
              value={props.value}
              checked={props.checked}
            />
            <label className="label" htmlFor={props.name}>
              <span className="inner" />
              <span className="switch" />
            </label>
          </div>
        </div>
      ) : (
        <>
          <label htmlFor="inputField">
            {props.name}:
            {props.isRequired && (
              <span className="inputField-isRequired">
                <FiIcons.FiAlertCircle /> Required
              </span>
            )}
          </label>
          {props.type === "text" && (
            <input
              type="text"
              className="inputField-input"
              placeholder={props.placeholder}
              onChange={props.onChange}
              onBlur={props.onBlur}
              value={props.value}
              required={props.isRequired}
            />
          )}
          {props.type === "email" && (
            <input
              type="email"
              className="inputField-input"
              placeholder={props.placeholder}
              onChange={props.onChange}
              onBlur={props.onBlur}
              value={props.value}
              required={props.isRequired}
            />
          )}
          {props.type === "password" && (
            <input
              type="password"
              className="inputField-input"
              placeholder={props.placeholder}
              onChange={props.onChange}
              value={props.value}
            />
          )}
          {props.type === "date" && (
            <input
              type="date"
              className="inputField-input"
              placeholder={props.placeholder}
              onChange={props.onChange}
              value={props.value}
            />
          )}
          {props.type === "phone" && (
            <>
              {props.hasExt && (
                <div className="phone-input">
                  <input
                    type="tel"
                    className="inputField-input"
                    onChange={props.onChange}
                    onBlur={props.onBlur}
                    value={props.value}
                    placeholder={props.placeholder}
                    required={props.isRequired}
                  />
                  <div>
                    <p>Ext:</p>
                    <input
                      type="text"
                      className="inputField-input ext"
                      placeholder={props.extPlaceholder}
                      value={props.extValue}
                      onChange={props.extOnChange}
                      onBlur={props.onBlur}
                    />
                  </div>
                </div>
              )}{" "}
              {!props.hasExt && (
                <input
                  type="tel"
                  className="inputField-input"
                  onChange={props.onChange}
                  onBlur={props.onBlur}
                  value={props.value}
                  placeholder={props.placeholder}
                  required={props.isRequired}
                />
              )}
            </>
          )}
          {props.type === "textArea" && (
            <div className="textarea">
              <textarea
                className="inputField-input-textarea"
                rows={7}
                onChange={(e) => {
                  setCharCount(e.target.value.length);
                  props.onChange(e);
                }}
                value={props.value}
              />
              <div className="charCount">
                <p>
                  <span className={charCount > 500 ? "danger" : ""}>
                    {charCount}
                  </span>
                  /500
                </p>
              </div>
            </div>
          )}
          {props.type === "dropdown" && (
            <>
              {" "}
              {props.isMulti ? (
                <Select
                  className="inputField-input-select"
                  placeholder={props.placeholder}
                  closeMenuOnSelect={false}
                  components={animatedComponents}
                  defaultValue={[props.placeholder]}
                  onChange={props.onChange}
                  isMulti
                  maxMenuHeight={115}
                  options={options}
                  getOptionValue={(option) => `${option[`${props.fieldName}`]}`}
                  getOptionLabel={(option) => `${option[`${props.fieldName}`]}`}
                />
              ) : (
                <Select
                  className="inputField-input-select"
                  placeholder={props.placeholder}
                  options={options}
                  closeMenuOnSelect={true}
                  defaultValue={props.placeholder}
                  onChange={props.onChange}
                  maxMenuHeight={115}
                  getOptionValue={(option) => `${option[`${props.fieldName}`]}`}
                  getOptionLabel={(option) => `${option[`${props.fieldName}`]}`}
                />
              )}
            </>
          )}
          {props.type === "countries" && (
            <AsyncSelect
              className="inputField-input-select"
              placeholder={props.placeholder}
              options={countries}
              defaultValue={props.value}
              onChange={props.onChange}
              maxMenuHeight={115}
              getOptionValue={(option) => `${option[`${props.fieldName}`]}`}
              getOptionLabel={(option) => `${option[`${props.fieldName}`]}`}
            />
          )}
          {props.isRequired && (
            <span className="inputField-error-message">
              {props.errorMessage}
            </span>
          )}
        </>
      )}
    </div>
  );
};

export default AdvancedInput;
