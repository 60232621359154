/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Auth } from "aws-amplify";
import { Table, AddItem, AddService } from "../../components";
import {
  ServiceSchema,
  MultipleIdToNameFormatter,
  IdToNameFormatter,
} from "../../utilities";
import { PageBody } from "../../theme";
import { DataContext } from "../../context/DataContext";

const Services = (props: any) => {
  const [addItem, setAddItem] = useState(false);
  const [isSuperAdmin, setIsSuperAdmin] = useState(false);
  const {
    services,
    setServices,
    users,
    companies,
    industries,
    categories,
    departments,
    programs,
  } = React.useContext(DataContext);

  useEffect(() => {
    if (services) {
      setServices(
        services
          .map((item: any) => {
            const formattedLead = item.lead
              ? MultipleIdToNameFormatter({
                  data: users,
                  fieldToMatch: item.lead,
                })
              : [];
            const formattedTeam = item.department
              ? IdToNameFormatter({
                  data: departments,
                  fieldToMatch: item.department,
                })
              : "";
            const formattedTeamMembers = item.members
              ? MultipleIdToNameFormatter({
                  data: users,
                  fieldToMatch: item.members,
                })
              : [];
            const formattedProgram = item.program
              ? IdToNameFormatter({
                  data: programs,
                  fieldToMatch: item.program,
                })
              : "";
            const formattedCompany = item.company
              ? MultipleIdToNameFormatter({
                  data: companies,
                  fieldToMatch: item.company,
                })
              : [];
            const formattedIndustries = item.industries
              ? MultipleIdToNameFormatter({
                  data: industries,
                  fieldToMatch: item.industries,
                })
              : [];
            const formattedCategories = item.categories
              ? MultipleIdToNameFormatter({
                  data: categories,
                  fieldToMatch: item.categories,
                })
              : [];

            return {
              ...item,
              formattedLead,
              formattedTeam,
              formattedTeamMembers,
              formattedProgram,
              formattedCompany,
              formattedIndustries,
              formattedCategories,
            };
          })
          .sort((a: any, b: any) => {
            if (a.status === "Pending") {
              return -1;
            } else if (b.status === "Pending") {
              return 1;
            } else if (a.status === "Active") {
              return -1;
            } else if (b.status === "Active") {
              return 1;
            } else {
              return 0;
            }
          })
      );
    }
  }, []);

  useEffect(() => {
    const getUserDetails = async () => {
      const authedUser = await Auth.currentAuthenticatedUser();
      if (authedUser?.attributes["custom:userGroup"].includes("Uber User")) {
        setIsSuperAdmin(true);
      } else {
        setIsSuperAdmin(false);
      }
    };
    getUserDetails();
  }, []);

  const handleAdd = (e) => {
    e.preventDefault();
    setAddItem(!addItem);
  };

  return (
    <>
      {addItem && (
        <AddItem type="service" component={<AddService {...props} />} />
      )}
      <PageBody>
        <Table
          showAdd={isSuperAdmin}
          handleAdd={handleAdd}
          title="Service Contracts"
          count={services?.length}
          rowDataProp={services}
          colDefProp={ServiceSchema}
        />
      </PageBody>
    </>
  );
};

export default Services;
