import { gql } from "@apollo/client";

export const CREATE_DELIVERABLE = gql`
  mutation CreateDeliverable(
    $sourceId: ID!
    $newDeliverable: DeliverableInput
  ) {
    createDeliverable(sourceId: $sourceId, newDeliverable: $newDeliverable) {
      id
      sourceId
      name
      responsible
      dueDate
      status
      createdAt
    }
  }
`;

export const UPDATE_DELIVERABLE = gql`
  mutation UpdateDeliverable(
    $sourceId: ID!
    $id: ID!
    $input: DeliverableInput
  ) {
    updateDeliverable(sourceId: $sourceId, id: $id, input: $input) {
      id
      sourceId
      name
      responsible
      dueDate
      status
      createdAt
    }
  }
`;

export const DELETE_DELIVERABLE = gql`
  mutation DeleteDeliverable($sourceId: ID!, $id: ID!) {
    deleteDeliverable(sourceId: $sourceId, id: $id) {
      id
      sourceId
      name
      responsible
      dueDate
      status
      createdAt
    }
  }
`;
