import React from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "../../theme";

const Error = (props: any) => {
  const navigate = useNavigate();

  return (
    <div
      className="error-page-container"
      style={{
        backgroundImage: `url(${
          process.env.PUBLIC_URL + "/images/app-bg.svg"
        })`,
      }}
    >
      <div className="error-text">
        <h1>{props.errorType}</h1>
      </div>
      {props.errorType === "401" && (
        <>
          <h2>unauthorized access</h2>
          <p>If this is a mistake, please contact your system admin</p>
          <Button width="250px" onClick={() => navigate(-1)}>
            Go Back
          </Button>
        </>
      )}
      {props.errorType === "no-route" && (
        <>
          <h2>We can't seem to find that page.</h2>
          <Button width="250px" onClick={() => navigate(-1)}>
            Go Back
          </Button>
        </>
      )}
    </div>
  );
};

export default Error;
