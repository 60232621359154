/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { useApolloClient } from "@apollo/client";
import {
  GET_COMPANIES,
  GET_CONTACTS,
  GET_ALL_PROGRAMS,
  GET_PROJECTS,
  GET_RESOURCES,
  GET_SERVICES,
  GET_CERTIFICATIONS,
  GET_CATEGORIES,
  GET_INDUSTRIES,
  GET_DEPARTMENTS,
  GET_CONTACTTYPES,
  GET_USERS,
  GET_RESOURCETYPES,
  GET_SERVICETYPES,
  GET_INTERACTIONS,
  GET_INTERACTIONCATEGORIES,
  GET_INTERACTION_TYPES,
} from "../api";

type DataContextType = {
  contacts: any;
  setContacts: React.Dispatch<React.SetStateAction<any>> | undefined;
  companies: any;
  setCompanies: React.Dispatch<React.SetStateAction<any>> | undefined;
  programs: any;
  setPrograms: React.Dispatch<React.SetStateAction<any>> | undefined;
  projects: any;
  setProjects: React.Dispatch<React.SetStateAction<any>> | undefined;
  resources: any;
  setResources: React.Dispatch<React.SetStateAction<any>> | undefined;
  services: any;
  setServices: React.Dispatch<React.SetStateAction<any>> | undefined;
  certifications: any;
  setCertifications: React.Dispatch<React.SetStateAction<any>> | undefined;
  categories: any;
  setCategories: React.Dispatch<React.SetStateAction<any>> | undefined;
  industries: any;
  setIndustries: React.Dispatch<React.SetStateAction<any>> | undefined;
  departments: any;
  setDepartments: React.Dispatch<React.SetStateAction<any>> | undefined;
  contactTypes: any;
  setContactTypes: React.Dispatch<React.SetStateAction<any>> | undefined;
  users: any;
  setUsers: React.Dispatch<React.SetStateAction<any>> | undefined;
  fetchUsers: (nextToken: string | null) => Promise<any>;
  resourceTypes: any;
  setResourceTypes: React.Dispatch<React.SetStateAction<any>> | undefined;
  serviceTypes: any;
  setServiceTypes: React.Dispatch<React.SetStateAction<any>> | undefined;
  interactions: any;
  setInteractions: React.Dispatch<React.SetStateAction<any>> | undefined;
  interactionCategories: any;
  setInteractionCategories:
    | React.Dispatch<React.SetStateAction<any>>
    | undefined;
  interactionTypes: any;
  setInteractionTypes: React.Dispatch<React.SetStateAction<any>> | undefined;
};

export const DataContext = React.createContext<DataContextType>({
  contacts: [],
  setContacts: undefined,
  companies: [],
  setCompanies: undefined,
  programs: [],
  setPrograms: undefined,
  projects: [],
  setProjects: undefined,
  resources: [],
  setResources: undefined,
  services: [],
  setServices: undefined,
  certifications: [],
  setCertifications: undefined,
  categories: [],
  setCategories: undefined,
  industries: [],
  setIndustries: undefined,
  departments: [],
  setDepartments: undefined,
  contactTypes: [],
  setContactTypes: undefined,
  users: [],
  setUsers: undefined,
  fetchUsers: () => Promise.resolve(),
  resourceTypes: [],
  setResourceTypes: undefined,
  serviceTypes: [],
  setServiceTypes: undefined,
  interactions: [],
  setInteractions: undefined,
  interactionCategories: [],
  setInteractionCategories: undefined,
  interactionTypes: [],
  setInteractionTypes: undefined,
});

const DataProvider = ({ children }: { children: React.ReactNode }) => {
  const client = useApolloClient();
  const [contacts, setContacts] = React.useState([]);
  const [companies, setCompanies] = React.useState([]);
  const [programs, setPrograms] = React.useState([]);
  const [projects, setProjects] = React.useState([]);
  const [resources, setResources] = React.useState([]);
  const [services, setServices] = React.useState([]);
  const [certifications, setCertifications] = React.useState([]);
  const [categories, setCategories] = React.useState([]);
  const [industries, setIndustries] = React.useState([]);
  const [departments, setDepartments] = React.useState([]);
  const [contactTypes, setContactTypes] = React.useState([]);
  const [users, setUsers] = React.useState([]);
  const [resourceTypes, setResourceTypes] = React.useState([]);
  const [serviceTypes, setServiceTypes] = React.useState([]);
  const [interactions, setInteractions] = React.useState([]);
  const [interactionCategories, setInteractionCategories] = React.useState([]);
  const [interactionTypes, setInteractionTypes] = React.useState([]);

  // Primary data fetch functions
  const fetchCompanies = async (nextToken: string | null) => {
    const { data } = await client.query({
      query: GET_COMPANIES,
      variables: { nextToken },
      fetchPolicy: "network-only",
    });

    return data.getAllCompanies;
  };

  const fetchContacts = async (nextToken: string | null) => {
    const { data } = await client.query({
      query: GET_CONTACTS,
      variables: { nextToken },
      fetchPolicy: "network-only",
    });
    return data.getAllContacts;
  };

  const fetchPrograms = async (nextToken: string | null) => {
    const { data } = await client.query({
      query: GET_ALL_PROGRAMS,
      variables: { nextToken },
      fetchPolicy: "network-only",
    });
    return data.getAllPrograms;
  };

  const fetchProjects = async (nextToken: string | null) => {
    const { data } = await client.query({
      query: GET_PROJECTS,
      variables: { nextToken },
      fetchPolicy: "network-only",
    });
    return data.getAllProjects;
  };

  const fetchResources = async (nextToken: string | null) => {
    const { data } = await client.query({
      query: GET_RESOURCES,
      variables: { nextToken },
      fetchPolicy: "network-only",
    });
    return data.getAllResources;
  };

  const fetchServices = async (nextToken: string | null) => {
    const { data } = await client.query({
      query: GET_SERVICES,
      variables: { nextToken },
      fetchPolicy: "network-only",
    });
    return data.getAllServices;
  };

  React.useEffect(() => {
    const fetchAllCompanies = async (nextToken) => {
      try {
        const allCompanies = await fetchCompanies(nextToken);
        setCompanies((prevCompanies) => [
          ...prevCompanies,
          ...allCompanies.companies.filter(
            (company) => !prevCompanies.some((c) => c.id === company.id)
          ),
        ]);
        return allCompanies.nextToken;
      } catch (error) {
        console.error(error);
        return null;
      }
    };
    const getAllCompanies = async () => {
      let nextToken = null;
      do {
        nextToken = await fetchAllCompanies(nextToken);
      } while (nextToken !== null);
    };
    getAllCompanies();
  }, []);

  React.useEffect(() => {
    const fetchAllContacts = async (nextToken) => {
      try {
        const allContacts = await fetchContacts(nextToken);
        setContacts((prevContacts) => [
          ...prevContacts,
          ...allContacts.contacts.filter(
            (contact) => !prevContacts.some((c) => c.id === contact.id)
          ),
        ]);
        return allContacts.nextToken;
      } catch (error) {
        console.error(error);
        return null;
      }
    };

    const getAllContacts = async () => {
      let nextToken = null;
      do {
        nextToken = await fetchAllContacts(nextToken);
      } while (nextToken !== null);
    };

    getAllContacts();
  }, []);

  React.useEffect(() => {
    const fetchAllPrograms = async (nextToken) => {
      try {
        const allPrograms = await fetchPrograms(nextToken);
        setPrograms((prevPrograms) => [
          ...prevPrograms,
          ...allPrograms.programs.filter(
            (program) => !prevPrograms.some((p) => p.id === program.id)
          ),
        ]);
        return allPrograms.nextToken;
      } catch (error) {
        console.error(error);
        return null;
      }
    };

    const getAllPrograms = async () => {
      let nextToken = null;
      do {
        nextToken = await fetchAllPrograms(nextToken);
      } while (nextToken !== null);
    };

    getAllPrograms();
  }, []);

  React.useEffect(() => {
    const fetchAllProjects = async (nextToken) => {
      try {
        const allProjects = await fetchProjects(nextToken);
        setProjects((prevProjects) => [
          ...prevProjects,
          ...allProjects.projects.filter(
            (project) => !prevProjects.some((p) => p.id === project.id)
          ),
        ]);
        return allProjects.nextToken;
      } catch (error) {
        console.error(error);
        return null;
      }
    };

    const getAllProjects = async () => {
      let nextToken = null;
      do {
        nextToken = await fetchAllProjects(nextToken);
      } while (nextToken !== null);
    };

    getAllProjects();
  }, []);

  React.useEffect(() => {
    const fetchAllResources = async (nextToken) => {
      try {
        const allResources = await fetchResources(nextToken);
        setResources((prevResources) => [
          ...prevResources,
          ...allResources.resources.filter(
            (resource) => !prevResources.some((r) => r.id === resource.id)
          ),
        ]);
        return allResources.nextToken;
      } catch (error) {
        console.error(error);
        return null;
      }
    };

    const getAllResources = async () => {
      let nextToken = null;
      do {
        nextToken = await fetchAllResources(nextToken);
      } while (nextToken !== null);
    };

    getAllResources();
  }, []);

  React.useEffect(() => {
    const fetchAllServices = async (nextToken) => {
      try {
        const allServices = await fetchServices(nextToken);
        setServices((prevServices) => [
          ...prevServices,
          ...allServices.services.filter(
            (service) => !prevServices.some((s) => s.id === service.id)
          ),
        ]);
        return allServices.nextToken;
      } catch (error) {
        console.error(error);
        return null;
      }
    };

    const getAllServices = async () => {
      let nextToken = null;
      do {
        nextToken = await fetchAllServices(nextToken);
      } while (nextToken !== null);
    };

    getAllServices();
  }, []);

  // // Secondary Data fetch functions
  const fetchCertifications = async (nextToken: string | null) => {
    const { data } = await client.query({
      query: GET_CERTIFICATIONS,
      variables: { nextToken },
      fetchPolicy: "network-only",
    });
    return data.getAllCertification;
  };

  const fetchCategories = async (nextToken: string | null) => {
    const { data } = await client.query({
      query: GET_CATEGORIES,
      variables: { nextToken },
      fetchPolicy: "network-only",
    });
    return data.getAllCategories;
  };

  const fetchIndustries = async (nextToken: string | null) => {
    const { data } = await client.query({
      query: GET_INDUSTRIES,
      variables: { nextToken },
      fetchPolicy: "network-only",
    });
    return data.getAllIndustries;
  };

  const fetchDepartments = async (nextToken: string | null) => {
    const { data } = await client.query({
      query: GET_DEPARTMENTS,
      variables: { nextToken },
      fetchPolicy: "network-only",
    });
    return data.getAllDepartments;
  };

  const fetchContactTypes = async (nextToken: string | null) => {
    const { data } = await client.query({
      query: GET_CONTACTTYPES,
      variables: { nextToken },
      fetchPolicy: "network-only",
    });
    return data.getAllContactTypes;
  };

  const fetchUsers = async (nextToken: string | null) => {
    const { data } = await client.query({
      query: GET_USERS,
      variables: { nextToken },
      fetchPolicy: "network-only",
    });
    return data.getAllUsers;
  };

  const fetchResourceTypes = async (nextToken: string | null) => {
    const { data } = await client.query({
      query: GET_RESOURCETYPES,
      variables: { nextToken },
      fetchPolicy: "network-only",
    });
    return data.getAllResourceTypes;
  };

  const fetchServiceTypes = async (nextToken: string | null) => {
    const { data } = await client.query({
      query: GET_SERVICETYPES,
      variables: { nextToken },
      fetchPolicy: "network-only",
    });
    return data.getAllServiceTypes;
  };

  const fetchInteractions = async (nextToken: string | null) => {
    const { data } = await client.query({
      query: GET_INTERACTIONS,
      variables: { nextToken },
      fetchPolicy: "network-only",
    });
    return data.getAllInteractions;
  };

  const fetchInteractionCategories = async (nextToken: string | null) => {
    const { data } = await client.query({
      query: GET_INTERACTIONCATEGORIES,
      variables: { nextToken },
      fetchPolicy: "network-only",
    });
    return data.getAllInteractionCategories;
  };

  const fetchInteractionTypes = async (nextToken: string | null) => {
    const { data } = await client.query({
      query: GET_INTERACTION_TYPES,
      variables: { nextToken },
      fetchPolicy: "network-only",
    });
    return data.getAllInteractionTypes;
  };

  React.useEffect(() => {
    const fetchAllCertifications = async (nextToken) => {
      try {
        const allCertifications = await fetchCertifications(nextToken);
        setCertifications((prevCertifications) => [
          ...prevCertifications,
          ...allCertifications.certifications.filter(
            (certification) =>
              !prevCertifications.some((c) => c.id === certification.id)
          ),
        ]);
        return allCertifications.nextToken;
      } catch (error) {
        console.error(error);
        return null;
      }
    };

    const getAllCertifications = async () => {
      let nextToken = null;
      do {
        nextToken = await fetchAllCertifications(nextToken);
      } while (nextToken !== null);
    };

    getAllCertifications();
  }, []);

  React.useEffect(() => {
    const fetchAllCategories = async (nextToken) => {
      try {
        const allCategories = await fetchCategories(nextToken);
        setCategories((prevCategories) => [
          ...prevCategories,
          ...allCategories.categories.filter(
            (category) => !prevCategories.some((c) => c.id === category.id)
          ),
        ]);
        return allCategories.nextToken;
      } catch (error) {
        console.error(error);
        return null;
      }
    };

    const getAllCategories = async () => {
      let nextToken = null;
      do {
        nextToken = await fetchAllCategories(nextToken);
      } while (nextToken !== null);
    };

    getAllCategories();
  }, []);

  React.useEffect(() => {
    const fetchAllIndustries = async (nextToken) => {
      try {
        const allIndustries = await fetchIndustries(nextToken);
        setIndustries((prevIndustries) => [
          ...prevIndustries,
          ...allIndustries.industries.filter(
            (industry) => !prevIndustries.some((i) => i.id === industry.id)
          ),
        ]);
        return allIndustries.nextToken;
      } catch (error) {
        console.error(error);
        return null;
      }
    };

    const getAllIndustries = async () => {
      let nextToken = null;
      do {
        nextToken = await fetchAllIndustries(nextToken);
      } while (nextToken !== null);
    };

    getAllIndustries();
  }, []);

  React.useEffect(() => {
    const fetchAllDepartments = async (nextToken) => {
      try {
        const allDepartments = await fetchDepartments(nextToken);
        setDepartments((prevDepartments) => [
          ...prevDepartments,
          ...allDepartments.departments.filter(
            (department) => !prevDepartments.some((d) => d.id === department.id)
          ),
        ]);
        return allDepartments.nextToken;
      } catch (error) {
        console.error(error);
        return null;
      }
    };

    const getAllDepartments = async () => {
      let nextToken = null;
      do {
        nextToken = await fetchAllDepartments(nextToken);
      } while (nextToken !== null);
    };

    getAllDepartments();
  }, []);

  React.useEffect(() => {
    const fetchAllContactTypes = async (nextToken) => {
      try {
        const allContactTypes = await fetchContactTypes(nextToken);
        setContactTypes((prevContactTypes) => [
          ...prevContactTypes,
          ...allContactTypes.contactTypes.filter(
            (contactType) =>
              !prevContactTypes.some((c) => c.id === contactType.id)
          ),
        ]);
        return allContactTypes.nextToken;
      } catch (error) {
        console.error(error);
        return null;
      }
    };

    const getAllContactTypes = async () => {
      let nextToken = null;
      do {
        nextToken = await fetchAllContactTypes(nextToken);
      } while (nextToken !== null);
    };

    getAllContactTypes();
  }, []);

  React.useEffect(() => {
    const fetchAllUsers = async (nextToken) => {
      try {
        const allUsers = await fetchUsers(nextToken);
        setUsers((prevUsers) => [
          ...prevUsers,
          ...allUsers.users.filter(
            (user) => !prevUsers.some((u) => u.id === user.id)
          ),
        ]);
        return allUsers.nextToken;
      } catch (error) {
        console.error(error);
        return null;
      }
    };

    const getAllUsers = async () => {
      let nextToken = null;
      do {
        nextToken = await fetchAllUsers(nextToken);
      } while (nextToken !== null);
    };

    getAllUsers();
  }, []);

  React.useEffect(() => {
    const fetchAllResourceTypes = async (nextToken) => {
      try {
        const allResourceTypes = await fetchResourceTypes(nextToken);
        setResourceTypes((prevResourceTypes) => [
          ...prevResourceTypes,
          ...allResourceTypes.resourceTypes.filter(
            (resourceType) =>
              !prevResourceTypes.some((r) => r.id === resourceType.id)
          ),
        ]);
        return allResourceTypes.nextToken;
      } catch (error) {
        console.error(error);
        return null;
      }
    };

    const getAllResourceTypes = async () => {
      let nextToken = null;
      do {
        nextToken = await fetchAllResourceTypes(nextToken);
      } while (nextToken !== null);
    };

    getAllResourceTypes();
  }, []);

  React.useEffect(() => {
    const fetchAllServiceTypes = async (nextToken) => {
      try {
        const allServiceTypes = await fetchServiceTypes(nextToken);
        setServiceTypes((prevServiceTypes) => [
          ...prevServiceTypes,
          ...allServiceTypes.serviceTypes.filter(
            (serviceType) =>
              !prevServiceTypes.some((s) => s.id === serviceType.id)
          ),
        ]);
        return allServiceTypes.nextToken;
      } catch (error) {
        console.error(error);
        return null;
      }
    };

    const getAllServiceTypes = async () => {
      let nextToken = null;
      do {
        nextToken = await fetchAllServiceTypes(nextToken);
      } while (nextToken !== null);
    };

    getAllServiceTypes();
  }, []);

  React.useEffect(() => {
    const fetchAllInteractions = async (nextToken) => {
      try {
        const allInteractions = await fetchInteractions(nextToken);
        setInteractions((prevInteractions) => [
          ...prevInteractions,
          ...allInteractions.interactions.filter(
            (interaction) =>
              !prevInteractions.some((i) => i.id === interaction.id)
          ),
        ]);
        return allInteractions.nextToken;
      } catch (error) {
        console.error(error);
        return null;
      }
    };

    const getAllInteractions = async () => {
      let nextToken = null;
      do {
        nextToken = await fetchAllInteractions(nextToken);
      } while (nextToken !== null);
    };

    getAllInteractions();
  }, []);

  React.useEffect(() => {
    const fetchAllInteractionCategories = async (nextToken) => {
      try {
        const allInteractionCategories = await fetchInteractionCategories(
          nextToken
        );
        setInteractionCategories((prevInteractionCategories) => [
          ...prevInteractionCategories,
          ...allInteractionCategories.interactionCategories.filter(
            (interactionCategory) =>
              !prevInteractionCategories.some(
                (i) => i.id === interactionCategory.id
              )
          ),
        ]);
        return allInteractionCategories.nextToken;
      } catch (error) {
        console.error(error);
        return null;
      }
    };

    const getAllInteractionCategories = async () => {
      let nextToken = null;
      do {
        nextToken = await fetchAllInteractionCategories(nextToken);
      } while (nextToken !== null);
    };

    getAllInteractionCategories();
  }, []);

  React.useEffect(() => {
    const fetchAllInteractionTypes = async (nextToken) => {
      try {
        const allInteractionTypes = await fetchInteractionTypes(nextToken);
        setInteractionTypes((prevInteractionTypes) => [
          ...prevInteractionTypes,
          ...allInteractionTypes.interactionTypes.filter(
            (interactionType) =>
              !prevInteractionTypes.some((i) => i.id === interactionType.id)
          ),
        ]);
        return allInteractionTypes.nextToken;
      } catch (error) {
        console.error(error);
        return null;
      }
    };

    const getAllInteractionTypes = async () => {
      let nextToken = null;
      do {
        nextToken = await fetchAllInteractionTypes(nextToken);
      } while (nextToken !== null);
    };

    getAllInteractionTypes();
  }, []);

  return (
    <DataContext.Provider
      value={{
        contacts,
        setContacts,
        companies,
        setCompanies,
        programs,
        setPrograms,
        projects,
        setProjects,
        resources,
        setResources,
        services,
        setServices,
        certifications,
        setCertifications,
        categories,
        setCategories,
        industries,
        setIndustries,
        departments,
        setDepartments,
        contactTypes,
        setContactTypes,
        users,
        setUsers,
        fetchUsers,
        resourceTypes,
        setResourceTypes,
        serviceTypes,
        setServiceTypes,
        interactions,
        setInteractions,
        interactionCategories,
        setInteractionCategories,
        interactionTypes,
        setInteractionTypes,
      }}
    >
      {children}
    </DataContext.Provider>
  );
};

export default DataProvider;
