import React, { useState, useEffect, useRef } from 'react';
import { Link } from "react-router-dom";
import * as AiIcons from "react-icons/ai";

const SearchField = (props: any) => {
    const Data = props.data;
    const searchTerm = props.searchTerm;

    const [filteredData, setFilteredData] = useState([]);
    const [wordEntered, setWordEntered] = useState("");
    const resultsRef = useRef(null);
  
    const handleFilter = (e) => {
      const searchWord = e.target.value;
      setWordEntered(searchWord);
      const newFilter = Data.filter((value: any) => {
        return (
            value[`${searchTerm}`].toLowerCase().includes(searchWord.toLowerCase())
        );
      });
  
      if (searchWord === "") {
        setFilteredData([]);
      } else {
        setFilteredData(newFilter);
      }
    };

    const clearInput = () => {
        setFilteredData([]);
        setWordEntered("");
    };

    useEffect(() => {
        document.addEventListener("click", handleClickOutside);
        return () => {
            document.removeEventListener("click", handleClickOutside);
        };
    });

    const handleClickOutside = (e) => {
        if (resultsRef.current && !resultsRef.current.contains(e.target)) {
            clearInput();
        } 
    };
    
    return (
        <div className="searchbar-input-container" ref={resultsRef}>
            <div className="searchbar-input is-mobile">
                <input 
                type="text"
                placeholder="Search..."
                value={wordEntered}
                onChange={handleFilter}
                />
                <div className="searchbar-icon">
                    {filteredData.length === 0 
                    ? <AiIcons.AiOutlineSearch />
                    : <AiIcons.AiOutlineClose 
                        className="searchbar-icon-close"
                        onClick={clearInput}
                    />
                    }
                </div>
            </div>
            {filteredData.length !== 0 && (
                <div className="searchbar-data-results">
                    {filteredData.slice(0, 15).map((item) => {
                        return (
                            <Link 
                            key={item.id}
                            className="searchbar-data-result"
                            onClick={clearInput}
                            to={`/directory/${props.breadcrumb}/`+ item.id}
                            >
                                <p>
                                {item[`${searchTerm}`]}
                                </p>
                            </Link>
                        );
                    })}
                </div>
            )}
        </div>
    )
};

export default SearchField;
