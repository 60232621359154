import { gql } from "@apollo/client";

export const GET_FAQS = gql`
  query getAllFaqs {
    getAllFaqs(limit: 10000) {
      faqs {
        id
        question
        answer
      }
    }
  }
`;

export const GET_FAQ_BY_ID = gql`
  query getFaqById($id: ID!) {
    getFaqById(id: $id) {
      id
      question
      answer
    }
  }
`;

export const CREATE_FAQ = gql`
  mutation createFaq($newFaq: FaqInput!) {
    createFaq(newFaq: $newFaq) {
      id
      question
      answer
    }
  }
`;

export const UPDATE_FAQ = gql`
  mutation updateFaq($id: ID!, $input: FaqInput!) {
    updateFaq(id: $id, input: $input) {
      id
      question
      answer
    }
  }
`;

export const DELETE_FAQ = gql`
  mutation deleteFaq($id: ID!) {
    deleteFaq(id: $id) {
      id
      question
      answer
    }
  }
`;
