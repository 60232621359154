import styled from "styled-components";

export const PageBody = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: center;
  width: 100%;
  height: 100%;
  margin: 5vh;
  margin-top: 75px;
  animation-name: fadeIn;
  animation-iteration-count: 1;
  animation-timing-function: ease-out 0s 1;
  animation-duration: 0.5s;
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @media (max-width: 1400px) {
    width: 100%;
    margin: 2vh;
    margin-top: 25px;
    margin-bottom: 75px;
  }
  @media (max-width: 1100px) {
    width: 100%;
    margin: 2vh;
    margin-top: 50px;
    margin-bottom: 75px;
  }
`;
