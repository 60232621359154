import { gql } from "@apollo/client";

export const GET_INTERACTIONS = gql`
  query getAllInteractions($nextToken: String) {
    getAllInteractions(nextToken: $nextToken) {
      interactions {
        username
        sourceId
        contact
        category
        id
        body
        actionType
        programOrProject
        createdAt
      }
      nextToken
    }
  }
`;

export const CREATE_INTERACTION = gql`
  mutation createInteraction(
    $sourceId: ID!
    $newInteraction: InteractionInput
  ) {
    createInteraction(sourceId: $sourceId, newInteraction: $newInteraction) {
      username
      sourceId
      contact
      category
      id
      body
      actionType
      programOrProject
      createdAt
    }
  }
`;

export const UPDATE_INTERACTION = gql`
  mutation updateInteraction(
    $sourceId: ID!
    $id: ID!
    $input: InteractionInput!
  ) {
    updateInteraction(sourceId: $sourceId, id: $id, input: $input) {
      username
      sourceId
      contact
      category
      id
      body
      actionType
      programOrProject
      createdAt
    }
  }
`;

export const DELETE_INTERACTION = gql`
  mutation deleteInteraction($sourceId: ID!, $id: ID!) {
    deleteInteraction(sourceId: $sourceId, id: $id) {
      username
      sourceId
      contact
      category
      id
      body
      actionType
      programOrProject
      createdAt
    }
  }
`;
