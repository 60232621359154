import { gql } from "@apollo/client";

export const CREATE_BUDGET = gql`
  mutation CreateBudget($sourceId: ID!, $newBudget: BudgetInput) {
    createBudget(sourceId: $sourceId, newBudget: $newBudget) {
      id
      sourceId
      expenses
      adminFees
      budget
      file
      createdAt
    }
  }
`;
export const UPDATE_BUDGET = gql`
  mutation UpdateBudget($sourceId: ID!, $id: ID!, $input: BudgetInput) {
    updateBudget(sourceId: $sourceId, id: $id, input: $input) {
      id
      sourceId
      expenses
      adminFees
      budget
      file
      createdAt
    }
  }
`;
export const DELETE_BUDGET = gql`
  mutation DeleteBudget($sourceId: ID!, $id: ID!) {
    deleteBudget(sourceId: $sourceId, id: $id) {
      id
      sourceId
      expenses
      adminFees
      budget
      file
      createdAt
    }
  }
`;
