import * as FaIcons from "react-icons/fa";
import * as IoIcons from "react-icons/io5";
import * as MdIcons from "react-icons/md";
import * as RiIcons from "react-icons/ri";

export const NavData = [
  {
    title: "Dashboard",
    path: "/",
    icon: <FaIcons.FaHome />,
    showToolbar: false,
    cName: "navItem",
    userGroup: ["Team Member", "Manager", "Uber User", "Finance"],
  },
  {
    title: "Contacts",
    path: "/directory/contacts",
    icon: <FaIcons.FaUsers />,
    showToolbar: false,
    cName: "navItem",
    userGroup: ["Team Member", "Manager", "Uber User", "Finance"],
  },
  {
    title: "Companies",
    path: "/directory/companies",
    icon: <IoIcons.IoBusinessSharp />,
    showToolbar: false,
    cName: "navItem",
    userGroup: ["Team Member", "Manager", "Uber User", "Finance"],
  },
  {
    title: "Programs",
    path: "/directory/programs",
    icon: <IoIcons.IoAppsOutline />,
    showToolbar: false,
    cName: "navItem",
    userGroup: ["Team Member", "Manager", "Uber User", "Finance"],
  },
  {
    title: "Projects",
    path: "/directory/projects",
    icon: <IoIcons.IoBuild />,
    showToolbar: false,
    cName: "navItem",
    userGroup: ["Team Member", "Manager", "Uber User", "Finance"],
  },
  {
    title: "Resources",
    path: "/directory/resources",
    icon: <RiIcons.RiStackLine />,
    showToolbar: false,
    cName: "navItem",
    userGroup: ["Team Member", "Manager", "Uber User", "Finance"],
  },
  {
    title: "Services",
    path: "/directory/services",
    icon: <MdIcons.MdMiscellaneousServices />,
    showToolbar: false,
    cName: "navItem",
    userGroup: ["Team Member", "Manager", "Uber User", "Finance"],
  },
  {
    title: "Admin Panel",
    path: "/admin",
    icon: <FaIcons.FaShieldAlt />,
    showToolbar: false,
    cName: "navItem",
    userGroup: ["Uber User"],
  },
];
