import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Auth } from "aws-amplify";
import * as AiIcons from "react-icons/ai";

const Breadcrumbs = () => {
  const navigate = useNavigate();
  const currentURL = window.location.href;
  const [user, setUser] = useState(null);
  const [time, setTime] = useState(null);
  const [greetings, setGreetings] = useState(null);

  const getTimeOfDay = () => {
    const date = new Date();
    const hour = date.getHours();
    return hour;
  };

  useEffect(() => {
    setTime(getTimeOfDay());
  }, []);

  const getUser = async () => {
    const user = await Auth.currentUserInfo();
    setUser(
      user.attributes["custom:fullName"].split(" ").slice(0, -1).join(" ")
    );
  };

  useEffect(() => {
    getUser().then(() => {
      if (time < 12) setGreetings("Good Morning");
      else setGreetings("Good Afternoon");
    });
  }, [user, time]);

  const handleClick = () => navigate(-1);

  if (
    currentURL === "http://localhost:3000/" ||
    currentURL === "https://www.crm.perennia.ca/"
  ) {
    return (
      <div className="breadcrumb-container">
        <p className="username" data-testid="welcomeMsg">
          {greetings} {user}
        </p>
      </div>
    );
  } else
    return (
      <div className="breadcrumb-container" aria-label="Breadcrumb">
        <p className="breadcrumb-go-back-btn" onClick={handleClick}>
          <span>
            <AiIcons.AiOutlineArrowLeft />
          </span>
          Go Back
        </p>
      </div>
    );
};

export default Breadcrumbs;
