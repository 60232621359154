import { gql } from "@apollo/client";

export const GET_CATEGORIES = gql`
  query getAllCategories($nextToken: String) {
    getAllCategories(nextToken: $nextToken) {
      categories {
        id
        name
        parent
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const GET_CATEGORY_BY_ID = gql`
  query getCategoryById($id: ID!) {
    getCategoryById(id: $id) {
      id
      name
      parent
      createdAt
      updatedAt
    }
  }
`;

export const CREATE_CATEGORY = gql`
  mutation createCategory($newCategory: CategoryInput!) {
    createCategory(newCategory: $newCategory) {
      id
      name
      parent
      createdAt
      updatedAt
    }
  }
`;

export const UPDATE_CATEGORY = gql`
  mutation updateCategory($id: ID!, $input: CategoryInput!) {
    updateCategory(id: $id, input: $input) {
      id
      name
      parent
      createdAt
      updatedAt
    }
  }
`;

export const DELETE_CATEGORY = gql`
  mutation deleteCategory($id: ID!) {
    deleteCategory(id: $id) {
      id
      name
      parent
      createdAt
      updatedAt
    }
  }
`;
