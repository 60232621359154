import React from "react";

const LoginBtn = (props: any) => {
  return (
    <button
      style={{ background: props.disabled ? "#d6d6d6" : "" }}
      className="login-btn"
      type="submit"
      disabled={props.disabled}
      onClick={props.onClick}
      data-testid="login-btn"
    >
      {props.text}
    </button>
  );
};

export default LoginBtn;
