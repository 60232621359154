import React, { useState } from "react";
import { useMutation } from "@apollo/client";
import { toast } from "react-toastify";
import * as FaIcons from "react-icons/fa";
import { DELETE_CATEGORY, GET_CATEGORIES } from "../../api";
import { CategoryModal } from "..";

const CategoryCard = ({ item, level, children }) => {
  const [modal, setModal] = useState(false);
  const [dropdown, setDropdown] = useState(false);
  const [deleteItem] = useMutation(DELETE_CATEGORY);

  const showDropdown = () => setDropdown(!dropdown);

  const showModal = () => setModal(!modal);

  const handleDelete = (e) => {
    e.preventDefault();
    if (item.hasChildren === true) {
      if (
        window.confirm(
          "Sub categories will be deleted if not already moved. Are you sure you want to delete this category?"
        )
      ) {
        const notify = () =>
          toast.error(`${item.name} has been successfully removed`);
        deleteItem({
          variables: { id: item.id },
          refetchQueries: [{ query: GET_CATEGORIES }],
        });
        notify();
      }
    } else if (
      window.confirm("Are you sure you want to delete this category?")
    ) {
      deleteItem({
        variables: { id: item.id },
        refetchQueries: [{ query: GET_CATEGORIES }],
      });
      const notify = () =>
        toast.error(`${item.name} has been successfully removed`);
      notify();
    }
  };

  return (
    <>
      {modal && <CategoryModal id={item.id} />}
      <div className="cat-container">
        <div className="cat-card">
          <div className={`cat-card-header level-${level}`}>
            <p onClick={showDropdown}>
              {!item.hasChildren ? null : (
                <>
                  {dropdown ? (
                    <FaIcons.FaCaretDown />
                  ) : (
                    <FaIcons.FaCaretRight />
                  )}
                </>
              )}
              <span>{item.name}</span>
            </p>
            <div className="cat-card-header-btn-container">
              <p>
                <FaIcons.FaEdit onClick={showModal} />
              </p>
              <p>
                <FaIcons.FaRegTrashAlt
                  className="cat-delete-btn"
                  onClick={handleDelete}
                />
              </p>
            </div>
          </div>
          {dropdown && <div className="children">{children}</div>}
        </div>
      </div>
    </>
  );
};

export default CategoryCard;
