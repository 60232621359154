import React, { useState, useEffect } from "react";
import { useQuery, useMutation } from "@apollo/client";
import { toast } from "react-toastify";
import { Table, AdvancedInput, AddItem } from "../../components";
import {
  GET_INDUSTRIES,
  CREATE_INDUSTRY,
  UPDATE_INDUSTRY,
  DELETE_INDUSTRY,
} from "../../api";
import { DateFormatter } from "../../utilities";
import { SubmitButton, PageBody } from "../../theme";

const AdminIndustries = (props: any) => {
  const { data } = useQuery(GET_INDUSTRIES);
  const [addIndustry] = useMutation(CREATE_INDUSTRY);
  const [modal, setModal] = useState(false);
  const [name, setName] = useState("");
  const [industries, setIndustries] = useState([]);
  const [columnDefs] = useState([
    {
      field: "name",
      headerName: "Name",
      filter: true,
      sortable: true,
      minWidth: 500,
      width: 500,
    },
    {
      field: "formattedDate",
      headerName: "Created At",
      minWidth: 150,
      width: 150,
    },
    {
      field: "id",
      headerName: "Actions",
      width: 100,
      cellRendererSelector: () => ({
        component: "actionsRenderer",
        params: {
          id: "id",
          refetchQuery: GET_INDUSTRIES,
          updateMutation: UPDATE_INDUSTRY,
          deleteMutation: DELETE_INDUSTRY,
        },
      }),
    },
  ]);

  useEffect(() => {
    if (data) {
      setIndustries(
        data?.getAllIndustries.industries.map((item) => ({
          ...item,
          formattedDate: DateFormatter({ createdAt: item.createdAt }),
        }))
      );
    }
  }, [data]);

  const showModal = () => setModal(!modal);

  const handleAdd = (e) => {
    e.preventDefault();
    const notify = () => toast.success("Industry successfully added");
    addIndustry({
      variables: {
        newIndustry: {
          name: name,
        },
      },
      refetchQueries: [{ query: GET_INDUSTRIES }],
    });
    notify();
    setName("");
    setModal(false);
  };

  return (
    <PageBody>
      {modal && (
        <AddItem
          type="Industry"
          component={[
            <form
              className="add-item-form"
              key="add-industry"
              onSubmit={handleAdd}
            >
              <AdvancedInput
                {...props}
                name="Industry Name"
                fieldName="name"
                type="text"
                width="95%"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
              <div className="add-item-button-container">
                <div style={{ width: "150px" }} />
                <SubmitButton width="150px">Submit</SubmitButton>
              </div>
            </form>,
          ]}
        />
      )}
      <Table
        title="Industries"
        isAdmin
        showAdd
        handleAdd={showModal}
        count={industries.length}
        rowDataProp={industries}
        colDefProp={columnDefs}
      />
    </PageBody>
  );
};

export default AdminIndustries;
