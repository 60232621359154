import React, { useState, useReducer, Reducer, useEffect } from "react";
import { Auth } from "aws-amplify";
import { useMutation } from "@apollo/client";
import { toast } from "react-toastify";
import { Button, SubmitButton } from "../../../theme";
import {
  AdvancedInput,
  CompanyNotFound,
  CategorySelect,
  DeleteItem,
} from "../..";
import {
  ServiceReducer,
  INITIAL_SERVICE_STATE,
  PaymentTerms,
} from "../../../utilities";
import {
  CREATE_SERVICE,
  UPDATE_SERVICE,
  GET_SERVICES,
  DELETE_SERVICE,
} from "../../../api";
import { DataContext } from "../../../context/DataContext";

const AddService = (props: any) => {
  const id = props.id;
  const editData = props.data;
  const {
    companies,
    industries,
    contacts,
    categories,
    departments,
    programs,
    serviceTypes,
    users,
  } = React.useContext(DataContext);
  const [createNewService] = useMutation(CREATE_SERVICE);
  const [updateService] = useMutation(UPDATE_SERVICE);
  const [stage, setStage] = useState(null);
  const [input, setInput] = useState(null);
  const [currentUser, setCurrentUser] = useState(null);
  const [error, setError] = useState(null);
  const [successfullSubmit, setSuccessfullSubmit] = useState(false);
  const [showProgramProjectInput, setShowProgramProjectInput] = useState(false);
  const [state, dispatch] = useReducer<Reducer<any, any>, any>(
    ServiceReducer,
    INITIAL_SERVICE_STATE(),
    undefined
  );

  useEffect(() => {
    if (!props.isEdit) {
      setStage(1);
    } else if (props.isEdit && props.isManager) {
      setStage(1);
    } else if (props.isEdit && props.isLead) {
      setStage(2);
    }
  }, [props.isEdit, props.isManager, props.isLead]);

  useEffect(() => {
    const getActiveUser = async () => {
      const activeUser = await Auth.currentAuthenticatedUser();
      setCurrentUser(activeUser.attributes.sub);
    };
    getActiveUser();
  }, []);

  useEffect(() => {
    const getInputState = async () => setInput(await state);
    getInputState();
  });

  const handleNDA = () => {
    if (!state?.hasNda) {
      window.confirm(
        "By selecting 'Yes' you confirm this service is NDA protected and certain fields will be hidden from the public view. Are you sure you want to continue?"
      );
    }
  };

  const handlePrevStage = (e) => {
    e.preventDefault();
    setStage((stage) => stage - 1);
  };

  const handleNextStage = (e) => {
    e.preventDefault();
    setStage((stage) => stage + 1);
  };

  const handleValidation = () => {
    const int = new RegExp(/^[0-9,]*(\.\d{1,2})?$/);
    let formIsValid = true;
    let errors = {};

    if (!props.isEdit && !input.name) {
      formIsValid = false;
      errors["name"] = "name can not be empty";
    }
    if (!int.test(input.qoutedAmount)) {
      formIsValid = false;
      errors["qoutedAmount"] =
        "Qouted Amount must only contain numbers and commas";
    }
    if (!props.isEdit && !input.tagLine) {
      formIsValid = false;
      errors["tagLine"] = "Tagline can not be empty";
    }
    setError({ errors: errors });
    return formIsValid;
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    if (handleValidation()) {
      if (props.isEdit) {
        const updatedInputs = JSON.parse(
          JSON.stringify(input, (key, value) => {
            if (Array.isArray(value) && value.length === 0) {
              return undefined;
            } else if (value === null || value === "") {
              return undefined;
            } else {
              return value;
            }
          })
        );
        updateService({
          variables: { id, input: updatedInputs },
          refetchQueries: [{ query: GET_SERVICES }],
        });
        toast.success("Service has successfully been updated");
        setSuccessfullSubmit(true);
      } else {
        createNewService({
          variables: {
            newService: {
              ...input,
              status: "Pending",
              username: currentUser,
            },
          },
          refetchQueries: [{ query: GET_SERVICES }],
        });
        toast.success(
          "Service has successfully been added. You can now close the window."
        );
        setSuccessfullSubmit(true);
      }
    } else {
      toast.error(
        "OOPS! that didn't work. Please verify the information and try again"
      );
      setStage(1);
    }
    setInput(INITIAL_SERVICE_STATE());
  };

  return (
    <form className="add-item-form" onSubmit={handleSubmit}>
      {stage === 1 && (
        <>
          <AdvancedInput
            {...props}
            name="Service Name"
            type="text"
            width="95%"
            isRequired={props.isEdit ? false : true}
            placeholder={editData?.name}
            value={state.name}
            onChange={(e) =>
              dispatch({ field: "name", payload: e.target.value })
            }
          />
          <span className="error-text">{error?.errors.name}</span>
          <AdvancedInput
            {...props}
            name="Tagline"
            type="text"
            width="95%"
            placeholder={editData?.tagLine}
            isRequired={props.isEdit ? false : true}
            value={state.tagLine}
            onChange={(e) =>
              dispatch({ field: "tagLine", payload: e.target.value })
            }
          />
          <span className="error-text">{error?.errors.tagLine}</span>
          <AdvancedInput
            {...props}
            name="type"
            type="dropdown"
            width="95%"
            fieldName="name"
            data={serviceTypes}
            placeholder={editData?.type}
            value={state.type}
            onChange={(e) => dispatch({ field: "type", payload: e.name })}
          />
          <AdvancedInput
            {...props}
            name="Is this a subsidized service"
            type="select"
            width="95%"
            value={showProgramProjectInput}
            onChange={() => {
              setShowProgramProjectInput(!showProgramProjectInput);
            }}
          />
          {showProgramProjectInput && (
            <AdvancedInput
              {...props}
              name="Program"
              type="dropdown"
              width="95%"
              fieldName="name"
              data={programs}
              value={state.program}
              onChange={(e) => dispatch({ field: "program", payload: e.id })}
            />
          )}
          <AdvancedInput
            {...props}
            name="Company"
            type="dropdown"
            width="95%"
            fieldName="name"
            data={companies}
            value={state.company}
            onChange={(e) => {
              dispatch({ field: "company", payload: e.id });
            }}
          />
          <CompanyNotFound />
          <AdvancedInput
            {...props}
            name="Team"
            type="dropdown"
            width="95%"
            fieldName="name"
            isMulti
            data={departments}
            value={state.department}
            onChange={(selectedOptions) =>
              dispatch({
                field: "department",
                payload: selectedOptions.map((i) => i.id),
              })
            }
          />
          <AdvancedInput
            {...props}
            name="Dovico Code"
            type="text"
            width="95%"
            value={state.code}
            onChange={(e) =>
              dispatch({ field: "code", payload: e.target.value })
            }
          />
        </>
      )}
      {stage === 2 && (
        <>
          <AdvancedInput
            {...props}
            name="Date Signed"
            type="date"
            width="95%"
            value={state.approvedByDate}
            onChange={(e) =>
              dispatch({ field: "approvedByDate", payload: e.target.value })
            }
          />
          <AdvancedInput
            {...props}
            name="start date"
            type="date"
            width="95%"
            value={state.startDate}
            onChange={(e) =>
              dispatch({ field: "startDate", payload: e.target.value })
            }
          />
          <AdvancedInput
            {...props}
            name="end date"
            type="date"
            width="95%"
            value={state.dueDate}
            onChange={(e) =>
              dispatch({ field: "dueDate", payload: e.target.value })
            }
          />
          <AdvancedInput
            {...props}
            name="Description"
            type="textArea"
            width="95%"
            value={state.description}
            placeholder={editData?.description}
            onChange={(e) =>
              dispatch({ field: "description", payload: e.target.value })
            }
          />
          {/* <p id="fine-print">Tax not included</p> */}
          <span className="error-text">{error?.errors.qoutedAmount}</span>
          {!props.isEdit ? (
            <>
              <AdvancedInput
                {...props}
                name="Quoted Amount"
                type="text"
                width="95%"
                placeholder={editData?.qoutedAmount}
                value={state.qoutedAmount}
                onChange={(e) =>
                  dispatch({ field: "qoutedAmount", payload: e.target.value })
                }
              />
              <AdvancedInput
                {...props}
                name="Payment Terms / Downpayment"
                type="dropdown"
                width="95%"
                data={PaymentTerms}
                fieldName="name"
                value={state.paymentTerm}
                onChange={(e) =>
                  dispatch({ field: "paymentTerm", payload: e.name })
                }
              />
              <AdvancedInput
                {...props}
                name="Downpayment has been made"
                type="select"
                value={state.downPayment}
                checked={state.downPayment}
                onChange={() => dispatch({ field: "downPayment" })}
              />
            </>
          ) : props.isEdit && props.isManager ? (
            <>
              <AdvancedInput
                {...props}
                name="Quoted Amount"
                type="text"
                width="95%"
                placeholder={editData?.qoutedAmount}
                value={state.qoutedAmount}
                onChange={(e) =>
                  dispatch({ field: "qoutedAmount", payload: e.target.value })
                }
              />
              <AdvancedInput
                {...props}
                name="Payment Terms / Downpayment"
                type="dropdown"
                width="95%"
                data={PaymentTerms}
                fieldName="name"
                value={state.paymentTerm}
                onChange={(e) =>
                  dispatch({ field: "paymentTerm", payload: e.name })
                }
              />
              <AdvancedInput
                {...props}
                name="Downpayment has been made"
                type="select"
                value={state.downPayment}
                checked={state.downPayment}
                onChange={() => dispatch({ field: "downPayment" })}
              />
            </>
          ) : null}
        </>
      )}
      {stage === 3 && (
        <>
          <AdvancedInput
            {...props}
            name="Service Lead"
            type="dropdown"
            width="95%"
            fieldName="name"
            isMulti
            data={users}
            value={state.lead}
            onChange={(selectedOptions) =>
              dispatch({
                field: "lead",
                payload: selectedOptions.map((i) => i.id),
              })
            }
          />
          <AdvancedInput
            {...props}
            name="Team Members"
            data={users}
            isMulti
            fieldName="name"
            type="dropdown"
            width="95%"
            value={state.members}
            onChange={(selectedOptions) =>
              dispatch({
                field: "members",
                payload: selectedOptions.map((i) => i.id),
              })
            }
          />
          <AdvancedInput
            {...props}
            name="main client contacts"
            type="dropdown"
            width="95%"
            fieldName="name"
            isMulti
            data={contacts}
            placeholder={editData?.contacts}
            value={state.contacts}
            onChange={(selectedOptions) =>
              dispatch({
                field: "contacts",
                payload: selectedOptions.map((i) => i.id),
              })
            }
          />
          <AdvancedInput
            {...props}
            name="industries"
            data={industries}
            isMulti
            fieldName="name"
            type="dropdown"
            width="95%"
            value={state.industries}
            onChange={(selectedOptions) =>
              dispatch({
                field: "industries",
                payload: selectedOptions.map((i) => i.id),
              })
            }
          />
          <CategorySelect
            data={categories}
            value={state.categories}
            handleOnChange={(selectedCategories) =>
              dispatch({
                field: "categories",
                payload: selectedCategories,
              })
            }
          />
          {!props.isEdit ? (
            <AdvancedInput
              {...props}
              name="Has an NDA"
              type="select"
              value={state.hasNda}
              checked={state.hasNda}
              onChange={() => {
                dispatch({ field: "hasNda" });
                handleNDA();
              }}
            />
          ) : props.isEdit && props.isManager ? (
            <AdvancedInput
              {...props}
              name="Has an NDA"
              type="select"
              value={state.hasNda}
              checked={state.hasNda}
              onChange={() => {
                dispatch({ field: "hasNda" });
                handleNDA();
              }}
            />
          ) : null}
        </>
      )}
      <div className="add-item-button-container">
        {stage === 1 ? (
          <div style={{ width: "150px" }} />
        ) : (
          <Button width="150px" onClick={handlePrevStage}>
            Go Back
          </Button>
        )}
        {stage !== 3 ? (
          <Button width="150px" onClick={handleNextStage}>
            Next
          </Button>
        ) : successfullSubmit ? (
          <Button width="150px" disabled={successfullSubmit}>
            Success
          </Button>
        ) : (
          <SubmitButton width="150px" disabled={successfullSubmit}>
            Submit
          </SubmitButton>
        )}
      </div>
      {stage === 3 && props.isEdit ? (
        <DeleteItem
          id={id}
          user={props.user}
          mutation={DELETE_SERVICE}
          refetch={GET_SERVICES}
          type="Service"
        />
      ) : null}
    </form>
  );
};

export default AddService;
