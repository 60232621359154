import { gql } from "@apollo/client";

// July 21, 2022 change request
// Change name from Departments to Teams
// ** Only input and page name changes **

export const GET_DEPARTMENTS = gql`
  query getAllDepartments($nextToken: String) {
    getAllDepartments(nextToken: $nextToken) {
      departments {
        id
        name
        code
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const GET_DEPARTMENT_BY_ID = gql`
  query getDepartmentById($id: ID!) {
    getDepartmentTypeById(id: $id) {
      id
      name
      code
      createdAt
      updatedAt
    }
  }
`;

export const CREATE_DEPARTMENT = gql`
  mutation createDepartment($newDepartment: DepartmentInput!) {
    createDepartment(newDepartment: $newDepartment) {
      id
      name
      code
      createdAt
      updatedAt
    }
  }
`;

export const UPDATE_DEPARTMENT = gql`
  mutation updateDepartment($id: ID!, $input: DepartmentInput!) {
    updateDepartment(id: $id, input: $input) {
      id
      name
      code
      createdAt
      updatedAt
    }
  }
`;

export const DELETE_DEPARTMENT = gql`
  mutation deleteDepartment($id: ID!) {
    deleteDepartment(id: $id) {
      id
      name
      code
      createdAt
      updatedAt
    }
  }
`;
