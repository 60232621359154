export const CompanySchema = [
  {
    headerName: "Status",
    field: "status",
    cellRenderer: "statusRenderer",
    textAlign: "center",
    width: 24,
    minWidth: 24,
    hide: false,
    sortable: true,
  },
  {
    headerName: "View",
    field: "id",
    cellRendererSelector: (params) => {
      return {
        component: "eyeRenderer",
        params: { url: "/directory/companies" },
      };
    },
    textAlign: "center",
    width: 100,
    minWidth: 100,
    hide: false,
  },
  {
    headerName: "Company Name",
    field: "name",
    renderer: "linkRenderer",
    url: "directory/company/",
    filter: true,
    value: "name",
    width: 250,
    minWidth: 250,
    hide: false,
  },
  {
    headerName: "Street",
    field: "street",
    filter: true,
    sortable: true,
    width: 200,
    minWidth: 200,
    hide: false,
  },
  {
    headerName: "Country",
    field: "country",
    filter: true,
    width: 150,
    minWidth: 150,
    sortable: true,
    hide: false,
  },
  {
    headerName: "Province",
    textAlign: "center",
    field: "province",
    filter: true,
    width: 100,
    minWidth: 150,
    sortable: true,
    hide: false,
  },
  {
    headerName: "City",
    field: "city",
    filter: true,
    sortable: true,
    width: 150,
    minWidth: 150,
    hide: false,
  },
  {
    headerName: "County",
    field: "county",
    filter: true,
    width: 200,
    minWidth: 200,
    sortable: true,
    hide: false,
  },
  {
    headerName: "Postal Code",
    field: "postal",
    filter: true,
    width: 150,
    minWidth: 150,
    hide: false,
  },
  {
    headerName: "Website",
    field: "website",
    cellRendererSelector: () => {
      return {
        component: "linkRenderer",
        params: { url: "" },
      };
    },
    filter: true,
    width: 250,
    minWidth: 250,
    hide: false,
  },
  {
    headerName: "Phone Number",
    field: "phone",
    cellRendererSelector: () => {
      return {
        component: "linkRenderer",
        params: { url: "tel://" },
      };
    },
    filter: true,
    width: 150,
    minWidth: 150,
    hide: false,
  },
  {
    headerName: "Extension",
    field: "ext",
    textAlign: "center",
    cellRendererSelector: () => {
      return {
        component: "linkRenderer",
        params: { url: "tel://" },
      };
    },
    filter: true,
    width: 150,
    minWidth: 150,
    hide: true,
  },
  {
    headerName: "Fax",
    field: "fax",
    textAlign: "center",
    cellRendererSelector: () => {
      return {
        component: "linkRenderer",
        params: { url: "tel://" },
      };
    },
    filter: true,
    width: 150,
    minWidth: 150,
    hide: true,
  },
  {
    headerName: "Email",
    field: "email",
    cellRendererSelector: () => {
      return {
        component: "linkRenderer",
        params: { url: "mailto://" },
      };
    },
    filter: true,
    width: 300,
    minWidth: 300,
    hide: false,
  },
  {
    headerName: "certifications",
    field: "formattedCertifications",
    width: 150,
    minWidth: 150,
    hide: true,
  },
  {
    headerName: "Industries",
    field: "formattedIndustries",
    width: 150,
    minWidth: 150,
    hide: true,
  },
  {
    headerName: "Contact Types",
    field: "formattedContactTypes",
    width: 150,
    minWidth: 150,
    hide: true,
  },
  {
    headerName: "Specialist",
    field: "formattedSpecialist",
    filter: true,
    width: 150,
    minWidth: 150,
    hide: true,
  },
  {
    headerName: "Categories",
    field: "formattedCategories",
    width: 150,
    minWidth: 150,
    hide: true,
  },
  {
    headerName: "Funder",
    field: "isFunder",
    textAlign: "center",
    cellRendererSelector: () => {
      return {
        component: "funderRenderer",
      };
    },
    width: 150,
    minWidth: 150,
    hide: false,
  },
];
