import React, { useState } from "react";
import { AddItem, AdvancedInput } from "..";
import { SubmitButton } from "../../theme";

const AddNote = (props: any) => {
  const [modal, setModal] = useState(true);
  const [description, setDescription] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    props.handleSubmit(description);
    setModal(false);
  };

  return (
    <>
      {modal && (
        <AddItem
          type="note"
          component={[
            <form
              className="add-item-form"
              key="add-file"
              onSubmit={handleSubmit}
            >
              <AdvancedInput
                {...props}
                name="description"
                type="textArea"
                width="95%"
                value={description}
                onChange={(e) => {
                  setDescription(e.target.value);
                }}
              />
              <div className="add-item-button-container">
                <div />
              </div>
              <div className="add-item-button-container">
                <div style={{ width: "150px" }} />
                <SubmitButton width="150px">Submit</SubmitButton>
              </div>
            </form>,
          ]}
        />
      )}
    </>
  );
};

export default AddNote;
