import styled from "styled-components";

export const FormBody = styled.form`
  display: grid;
  grid-template-columns: 3fr 3fr 3fr;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  margin-top: 50px;
  @media (max-width: 1100px) {
    grid-template-columns: auto;
  }
  @media (max-width: 480px) {
    grid-template-columns: auto;
  }
`;
