import styled from "styled-components";

export const Button = styled.button.attrs((props) => ({
  width: props.width,
}))`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 36px !important;
  width: ${(props) => props.width};
  border-radius: 5px;
  border: none;
  outline: none;
  margin: 10px;
  &:hover {
    cursor: pointer;
    transition: 0.5s ease-in-out;
    background-color: #009999;
    color: #ffffff;
  }
  &.is-active {
    background-color: #009999;
    color: #ffffff;
    &:hover {
      background-color: #f2f2f2;
      color: #363636;
    }
  }
  & span {
    margin-right: 5px;
  }
`;
