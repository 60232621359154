import { gql } from "@apollo/client";

export const GET_CONTACTTYPES = gql`
  query getAllContactTypes($nextToken: String) {
    getAllContactTypes(nextToken: $nextToken) {
      contactTypes {
        id
        name
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const GET_CONTACTTYPE_BY_ID = gql`
  query getContactTypeById($id: ID!) {
    getContactTypeById(id: $id) {
      id
      name
      createdAt
      updatedAt
    }
  }
`;

export const CREATE_CONTACTTYPE = gql`
  mutation createContactType($newContactType: ContactTypeInput!) {
    createContactType(newContactType: $newContactType) {
      id
      name
      createdAt
      updatedAt
    }
  }
`;

export const UPDATE_CONTACTTYPE = gql`
  mutation updateContactType($id: ID!, $input: ContactTypeInput!) {
    updateContactType(id: $id, input: $input) {
      id
      name
      createdAt
      updatedAt
    }
  }
`;

export const DELETE_CONTACTTYPE = gql`
  mutation deleteContactType($id: ID!) {
    deleteContactType(id: $id) {
      id
      name
      createdAt
      updatedAt
    }
  }
`;
