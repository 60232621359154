/* eslint-disable array-callback-return */
export const IdToNameFormatter = ({ data, fieldToMatch }) => {
  // Column Schema field vaule must include .name
  // Example: field: "formattedName.name",
  const formattedItem = data.find((item: any) => {
    if (item?.id.includes(fieldToMatch)) {
      return item.name;
    }
  });
  return formattedItem;
};

export const MultipleIdToNameFormatter = ({ data, fieldToMatch }) => {
  const formattedItems = data.filter((item: any) => {
    if (fieldToMatch?.includes(item.id)) {
      return item;
    }
  });
  if (formattedItems?.length > 0) {
    const names = formattedItems.map((item) => item.name);
    return names;
  }
};

export const DateFormatter = ({ createdAt }) => {
  const formattedDate = createdAt?.substring(0, 10);
  return formattedDate;
};

export const CodeFormatter = ({
  teamData,
  teamIds,
  tagline,
  companyData,
  companyIds,
}) => {
  const date = new Date();
  const teamCodes = teamIds
    ?.map((id) => {
      const selectedTeam = teamData?.find((item: any) => item.id === id);
      return selectedTeam?.code;
    })
    .join("-");

  // Loop through each companyId and concatenate corresponding company codes with hyphens
  const companyCodes = companyIds?.map((id: any) => {
    const selectedCompanis = companyData?.find((item: any) => item.id === id);
    return selectedCompanis?.name
      .replace(/(^|\W)of(\W|$)/gi, "")
      .match(/\b(\w)/g)
      .join("");
  });

  const code =
    teamCodes +
    "-" +
    date.getFullYear() +
    "-" +
    companyCodes?.join("-") +
    "-" +
    tagline;

  return code;
};

export const ServiceCodeFormatter = ({
  teamData,
  teamId,
  tagline,
  serviceTypeData,
  serviceTypeId,
}) => {
  const date = new Date();
  const selectedTeam = teamData?.find((item: any) => item.id === teamId);
  const teamCode = selectedTeam.code;
  const selectedType = serviceTypeData?.find((item: any) => {
    if (serviceTypeId?.includes(item.id)) return item;
  });
  const serviceTypeCode = selectedType?.name.match(/\b(\w)/g).join("") || "";

  const code =
    teamCode + "-" + date.getFullYear() + "-" + serviceTypeCode + "-" + tagline;

  return code;
};
