export const ContactSchema = [
  {
    headerName: "Status",
    field: "status",
    cellRenderer: "statusRenderer",
    textAlign: "center",
    width: 24,
    minWidth: 24,
    hide: false,
    sortable: true,
  },
  {
    headerName: "View",
    field: "id",
    textAlign: "center",
    cellRendererSelector: () => {
      return {
        component: "eyeRenderer",
        params: { url: "/directory/contacts" },
      };
    },
    width: 95,
    minWidth: 95,
    hide: false,
  },
  {
    headerName: "Name",
    field: "name",
    filter: true,
    width: 200,
    minWidth: 200,
    sortable: true,
    hide: false,
  },
  {
    headerName: "Company Name",
    field: "formattedCompanyName.name",
    renderer: "linkRenderer",
    url: "directory/company/",
    value: "companyId",
    filter: true,
    width: 250,
    minWidth: 250,
    sortable: true,
    hide: false,
  },
  {
    headerName: "Job Title",
    field: "jobtitle",
    filter: true,
    width: 150,
    minWidth: 150,
    hide: true,
  },
  {
    headerName: "Street",
    field: "street",
    filter: true,
    sortable: true,
    width: 200,
    minWidth: 200,
    hide: false,
  },
  {
    headerName: "Country",
    field: "country",
    filter: true,
    width: 150,
    minWidth: 150,
    sortable: true,
    hide: false,
  },
  {
    headerName: "Province",
    textAlign: "center",
    field: "province",
    filter: true,
    width: 100,
    minWidth: 150,
    sortable: true,
    hide: false,
  },
  {
    headerName: "City",
    field: "city",
    filter: true,
    sortable: true,
    width: 150,
    minWidth: 150,
    hide: false,
  },
  {
    headerName: "County",
    field: "county",
    filter: true,
    width: 150,
    minWidth: 150,
    sortable: true,
    hide: false,
  },
  {
    headerName: "Postal Code",
    field: "postal",
    filter: true,
    width: 150,
    minWidth: 150,
    hide: false,
  },
  {
    headerName: "Primary Email",
    field: "primaryEmail",
    textAlign: "center",
    cellRendererSelector: () => {
      return {
        component: "linkRenderer",
        params: { url: "mailto://" },
      };
    },
    filter: true,
    width: 300,
    minWidth: 300,
    hide: false,
  },
  {
    headerName: "Secondary Email",
    field: "secondaryEmail",
    textAlign: "center",
    cellRendererSelector: () => {
      return {
        component: "linkRenderer",
        params: { url: "mailto://" },
      };
    },
    filter: true,
    width: 300,
    minWidth: 300,
    hide: true,
  },
  {
    headerName: "Phone Number",
    field: "phone",
    textAlign: "center",
    cellRendererSelector: () => {
      return {
        component: "linkRenderer",
        params: { url: "tel://" },
      };
    },
    filter: true,
    width: 150,
    minWidth: 150,
    hide: false,
  },
  {
    headerName: "Mobile",
    field: "mobile",
    textAlign: "center",
    cellRendererSelector: () => {
      return {
        component: "linkRenderer",
        params: { url: "tel://" },
      };
    },
    filter: true,
    width: 150,
    minWidth: 150,
    hide: true,
  },
  {
    headerName: "Work",
    field: "work",
    textAlign: "center",
    cellRendererSelector: () => {
      return {
        component: "linkRenderer",
        params: { url: "tel://" },
      };
    },
    filter: true,
    width: 150,
    minWidth: 150,
    hide: true,
  },
  {
    headerName: "Fax",
    field: "fax",
    textAlign: "center",
    cellRendererSelector: () => {
      return {
        component: "linkRenderer",
        params: { url: "tel://" },
      };
    },
    filter: true,
    width: 150,
    minWidth: 150,
    hide: true,
  },
  {
    headerName: "Industries",
    field: "formattedIndustries",
    width: 250,
    minWidth: 250,
    hide: true,
  },
  {
    headerName: "Contact Types",
    field: "formattedContactTypes",
    width: 250,
    minWidth: 250,
    hide: true,
  },
  {
    headerName: "Specialist",
    field: "formattedSpecialist",
    filter: true,
    width: 250,
    minWidth: 250,
    hide: true,
  },
  {
    headerName: "Categories",
    field: "formattedCategories",
    width: 250,
    minWidth: 250,
    hide: true,
  },
  {
    headerName: "Primary",
    field: "isPrimary",
    cellRenderer: "booleanToYesNoRenderer",
    width: 150,
    minWidth: 150,
    hide: true,
  },
];
