import { gql } from "@apollo/client";

export const GET_INVOICES = gql`
  query getAllInvoices {
    getAllInvoices {
      invoices {
        username
        invoiceNumber
        sourceId
        paid
        id
        date
        createdAt
      }
    }
  }
`;

export const CREATE_INVOICE = gql`
  mutation CreateInvoice($sourceId: ID!, $newInvoice: InvoiceInput) {
    createInvoice(sourceId: $sourceId, newInvoice: $newInvoice) {
      id
      username
      sourceId
      date
      invoiceNumber
      paid
      createdAt
    }
  }
`;

export const UPDATE_INVOICE = gql`
  mutation UpdateInvoice($sourceId: ID!, $id: ID!, $input: InvoiceInput) {
    updateInvoice(sourceId: $sourceId, id: $id, input: $input) {
      id
      username
      sourceId
      date
      invoiceNumber
      paid
      createdAt
    }
  }
`;

export const DELETE_INVOICE = gql`
  mutation DeleteInvoice($sourceId: ID!, $id: ID!) {
    deleteInvoice(sourceId: $sourceId, id: $id) {
      id
      username
      sourceId
      date
      invoiceNumber
      paid
      createdAt
    }
  }
`;
