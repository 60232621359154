import { useQuery } from "@apollo/client";
import { GET_USERS } from "../../api";

const useGetUserFullName = (userId: string) => {
  const { data, loading, error } = useQuery(GET_USERS);

  if (loading) return null;

  if (error) {
    console.error({
      message: "Error fetching user data",
      error: error,
    });
  }

  const user = data?.getAllUsers?.users?.find(
    (user: any) => user.id === userId
  );

  return user ? user.name : null;
};

export default useGetUserFullName;
