/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Auth } from "aws-amplify";
import { Table, AddItem, AddProject } from "../../components";
import { ProjectSchema, MultipleIdToNameFormatter } from "../../utilities";
import { PageBody } from "../../theme";
import { DataContext } from "../../context/DataContext";

const Projects = (props: any) => {
  const [addItem, setAddItem] = useState(false);
  const [isSuperAdmin, setIsSuperAdmin] = useState(false);
  const { projects, setProjects, users, companies, industries, categories } =
    React.useContext(DataContext);

  useEffect(() => {
    if (projects) {
      setProjects(
        projects
          .map((item: any) => {
            const formattedLead = item.lead
              ? MultipleIdToNameFormatter({
                  data: users,
                  fieldToMatch: item.lead,
                })
              : [];
            const formattedTeamMembers = item.members
              ? MultipleIdToNameFormatter({
                  data: users,
                  fieldToMatch: item.members,
                })
              : [];
            const formattedFunders = item.funder
              ? MultipleIdToNameFormatter({
                  data: companies,
                  fieldToMatch: item.funder,
                })
              : [];
            const formattedCoops = item.coops
              ? MultipleIdToNameFormatter({
                  data: companies,
                  fieldToMatch: item.coops,
                })
              : [];
            const formattedIndustries = item.industries
              ? MultipleIdToNameFormatter({
                  data: industries,
                  fieldToMatch: item.industries,
                })
              : [];
            const formattedCategories = item.categories
              ? MultipleIdToNameFormatter({
                  data: categories,
                  fieldToMatch: item.categories,
                })
              : [];

            return {
              ...item,
              formattedLead,
              formattedTeamMembers,
              formattedFunders,
              formattedCoops,
              formattedIndustries,
              formattedCategories,
            };
          })
          .sort((a: any, b: any) => {
            if (a.status === "Pending") {
              return -1;
            } else if (b.status === "Pending") {
              return 1;
            } else if (a.status === "Active") {
              return -1;
            } else if (b.status === "Active") {
              return 1;
            } else {
              return 0;
            }
          })
      );
    }
  }, []);

  useEffect(() => {
    const getUserDetails = async () => {
      const authedUser = await Auth.currentAuthenticatedUser();
      if (authedUser?.attributes["custom:userGroup"].includes("Uber User")) {
        setIsSuperAdmin(true);
      } else {
        setIsSuperAdmin(false);
      }
    };
    getUserDetails();
  }, []);

  const handleAdd = (e) => {
    e.preventDefault();
    setAddItem(!addItem);
  };

  return (
    <>
      {addItem && (
        <AddItem type="project" component={<AddProject {...props} />} />
      )}
      <PageBody>
        <Table
          showAdd={isSuperAdmin}
          handleAdd={handleAdd}
          title="Projects"
          count={projects?.length}
          rowDataProp={projects}
          colDefProp={ProjectSchema}
        />
      </PageBody>
    </>
  );
};

export default Projects;
