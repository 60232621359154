import React from "react";
import { Link } from "react-router-dom";
import { PageBody } from "../../theme";
import { AdminBtnData } from "../../utilities";

const AdminDashboard = () => {
  return (
    <PageBody>
      <div className="admin-dashboard-container">
        {AdminBtnData.map((item, index) => {
          return (
            <Link
              key={index}
              className="admin-btn"
              to={`/admin/${item.path}`}
              aria-label={`Edit ${item.title}`}
            >
              <div className="admin-btn-bg-circle">
                <div className="admin-btn-icon">{item.icon}</div>
              </div>
              <p className="admin-btn-title">{item.title}</p>
            </Link>
          );
        })}
      </div>
    </PageBody>
  );
};

export default AdminDashboard;
