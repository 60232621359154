import React, { useState } from "react";
import { AddItem, AdvancedInput } from "..";
import { SubmitButton } from "../../theme";

const AddClaimAndInvocie = (props: any) => {
  const [modal, setModal] = useState(true);
  const [date, setDate] = useState("");
  const [invoiceNumber, setInvoiceNumber] = useState("");
  const [paid, setPaid] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    props.handleSubmit(date, invoiceNumber, paid);
    setModal(false);
  };

  return (
    <>
      {modal && (
        <AddItem
          type={props.type === "service" ? "Invoice" : "Claim"}
          component={[
            <form
              className="add-item-form"
              key="add-file"
              onSubmit={handleSubmit}
            >
              <AdvancedInput
                {...props}
                name={
                  props.type === "service" ? "Invoice Number" : "Claim Number"
                }
                type="text"
                width="95%"
                value={invoiceNumber}
                onChange={(e) => setInvoiceNumber(e.target.value)}
              />
              <AdvancedInput
                {...props}
                name="Date"
                type="date"
                width="95%"
                value={date}
                onChange={(e) => setDate(e.target.value)}
              />
              <div className="invoices-radio-container">
                <AdvancedInput
                  {...props}
                  name="Paid"
                  type="select"
                  value={paid}
                  checked={paid}
                  onChange={() => setPaid(!paid)}
                />
                <div className="add-item-button-container">
                  <div style={{ width: "150px" }} />
                  <SubmitButton width="150px">Submit</SubmitButton>
                </div>
              </div>
            </form>,
          ]}
        />
      )}
    </>
  );
};

export default AddClaimAndInvocie;
