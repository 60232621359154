import { gql } from "@apollo/client";

export const GET_INTERACTION_TYPES = gql`
  query getAllInteractionTypes($nextToken: String) {
    getAllInteractionTypes(nextToken: $nextToken) {
      interactionTypes {
        id
        name
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const GET_INTERACTION_TYPE_BY_ID = gql`
  query getInteractionTypeById($id: ID!) {
    getInteractionTypeById(id: $id) {
      id
      name
      createdAt
      updatedAt
    }
  }
`;

export const CREATE_INTERACTION_TYPE = gql`
  mutation createInteractionType($newInteractionType: InteractionTypeInput) {
    createInteractionType(newInteractionType: $newInteractionType) {
      id
      name
      createdAt
      updatedAt
    }
  }
`;

export const UPDATE_INTERACTION_TYPE = gql`
  mutation updateInteractionType($id: ID!, $input: InteractionTypeInput) {
    updateInteractionType(id: $id, input: $input) {
      id
      name
      createdAt
      updatedAt
    }
  }
`;

export const DELETE_INTERACTION_TYPE = gql`
  mutation deleteInteractionType($id: ID!) {
    deleteInteractionType(id: $id) {
      id
      name
      createdAt
      updatedAt
    }
  }
`;
