import React, { useState, useEffect } from "react";
import { useQuery, useMutation } from "@apollo/client";
import { toast } from "react-toastify";
import { GET_CATEGORIES, CREATE_CATEGORY, UPDATE_CATEGORY } from "../../api";
import { AddItem, AdvancedInput } from "..";
import { Button, SubmitButton } from "../../theme";

const CategoryModal = (props: any) => {
  const [modal, setModal] = useState(true);
  const [name, setName] = useState(null);
  const [subCat, setSubCat] = useState(false);
  const [parent, setParent] = useState(null);
  const { data } = useQuery(GET_CATEGORIES);
  const [categories, setCategories] = useState([]);
  const [addCategory] = useMutation(CREATE_CATEGORY);
  const [updateCategory] = useMutation(UPDATE_CATEGORY);

  useEffect(() => {
    if (data) setCategories(data?.getAllCategories.categories);
  }, [data]);

  const showSubCat = (e) => {
    e.preventDefault();
    setSubCat(!subCat);
  };

  const handleAdd = (e) => {
    e.preventDefault();
    const notify = () => toast.success(`${name} has successfully been added.`);
    addCategory({
      variables: {
        newCategory: {
          name: name,
          parent: parent,
        },
      },
      refetchQueries: [{ query: GET_CATEGORIES }],
    });
    notify();
    setModal(false);
    setSubCat(false);
    setName("");
    setParent("");
  };

  const handleUpdate = (e) => {
    e.preventDefault();
    const notify = () =>
      toast.success(`${name} has successfully been updated.`);
    updateCategory({
      variables: {
        id: props.id,
        input: {
          name: name,
          parent: parent,
        },
      },
      refetchQueries: [{ query: GET_CATEGORIES }],
    });
    notify();
    setModal(false);
  };

  return (
    <>
      {modal && (
        <AddItem
          type="category"
          component={[
            <form
              className="add-item-form"
              key="add-category"
              onSubmit={props.isAdd ? handleAdd : handleUpdate}
            >
              <AdvancedInput
                {...props}
                name="Category Name"
                fieldName="name"
                type="text"
                width="90%"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
              {subCat && (
                <AdvancedInput
                  {...props}
                  name="Parent Name"
                  fieldName="name"
                  type="dropdown"
                  width="90%"
                  data={categories}
                  value={parent}
                  onChange={(e) => setParent(e.id)}
                />
              )}
              <div className="add-item-button-container">
                <Button width="150px" onClick={showSubCat}>
                  Add to parent
                </Button>
                <SubmitButton width="150px">Submit</SubmitButton>
              </div>
            </form>,
          ]}
        />
      )}
    </>
  );
};

export default CategoryModal;
