import React, { useState, useEffect, useRef, useCallback } from "react";
import { Auth } from "aws-amplify";
import { useMutation } from "@apollo/client";
import { AgGridReact } from "ag-grid-react";
import { toast } from "react-toastify";
import { AddDeliverable } from "..";
import {
  CREATE_DELIVERABLE,
  UPDATE_DELIVERABLE,
  DELETE_DELIVERABLE,
} from "../../api";
import { IdToNameFormatter } from "../../utilities";
import { DataContext } from "../../context/DataContext";
import * as FaIcons from "react-icons/fa";
import * as AiIcons from "react-icons/ai";

const Deliverables = (props: any) => {
  const sourceId = props.id;
  const { users } = React.useContext(DataContext);
  const [itemId, setItemId] = useState(null);
  const [refetchQuery] = useState(props.refetchQuery);
  const [modal, setModal] = useState(false);
  const [toolbar, setToolbar] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);
  const [items, setItems] = useState([]);
  const [edit, setEdit] = useState(false);
  const [createItem] = useMutation(CREATE_DELIVERABLE);
  const [updateItem] = useMutation(UPDATE_DELIVERABLE);
  const [deleteItem] = useMutation(DELETE_DELIVERABLE);
  const [gridApi, setGridApi] = useState(null);
  const gridRef = useRef(null);
  const [columnDefs] = useState([
    {
      field: "name",
      headerName: "Name",
      filter: true,
      sortable: true,
      minWidth: 150,
      width: 285,
    },
    {
      field: "formattedResponsible.name",
      headerName: "Responsible",
      filter: true,
      sortable: true,
      minWidth: 150,
      width: 285,
    },
    {
      field: "dueDate",
      headerName: "Due Date",
      filter: true,
      sortable: true,
      minWidth: 150,
      width: 285,
    },
    {
      field: "status",
      headerName: "Status",
      filter: true,
      sortable: true,
      minWidth: 150,
      width: 285,
    },
    {
      field: "id",
      headerName: "Actions",
      textAlign: "center",
      minWidth: 150,
      width: 150,
      cellRendererSelector: () => ({
        component: "actionsRenderer",
        params: {
          id: "id",
          refetchQuery: props.refetchQuery,
          updateMutation: UPDATE_DELIVERABLE,
          deleteMutation: DELETE_DELIVERABLE,
        },
      }),
    },
  ]);
  const showModal = () => setModal(!modal);
  const showToolbar = () => setToolbar(!toolbar);

  useEffect(() => {
    if (props.data)
      setItems(
        props.data.map((item) => ({
          ...item,
          formattedResponsible: IdToNameFormatter({
            data: users,
            fieldToMatch: item.responsible,
          }),
        }))
      );
  }, [props.data, users]);

  useEffect(() => {
    getActiveUser();
  }, [currentUser]);

  useEffect(() => {
    if (gridApi) {
      gridApi.sizeColumnsToFit();
    }
  });

  const getActiveUser = async () => {
    const activeUser = await Auth.currentAuthenticatedUser();
    setCurrentUser(activeUser.username);
  };

  const onGridReady = (params: any) => {
    setGridApi(params.api);
  };

  const manageItems = (props: any) => {
    const handleEdit = () => {
      setItemId(props.value);
      setEdit(!edit);
    };

    const onDelete = () => {
      handleDelete(props.value);
    };

    return (
      <>
        <div className="action-btns-container">
          <p>
            <FaIcons.FaEdit onClick={handleEdit} />
          </p>
          <p>
            <FaIcons.FaTrash onClick={onDelete} />
          </p>
        </div>
      </>
    );
  };

  // User Options
  const handleAdd = (name, responsible, dueDate, status) => {
    const notify = () =>
      toast.success(
        `Thank you ${currentUser}, your ${props.field} has been recorded.`
      );
    createItem({
      variables: {
        sourceId: sourceId,
        newDeliverable: {
          username: currentUser,
          name: name,
          responsible: responsible,
          dueDate: dueDate,
          status: status,
        },
      },
      refetchQueries: [{ query: refetchQuery }],
    }).then((item) => {
      notify();
      setItems([item.data.createDeliverable, ...items]);
    });
  };

  const handleUpdate = (name, responsible, dueDate, status) => {
    const notify = () =>
      toast.success(`${props.field} has been successfully updated.`);
    updateItem({
      variables: {
        sourceId: sourceId,
        id: itemId,
        input: {
          name: name,
          responsible: responsible,
          dueDate: dueDate,
          status: status,
        },
      },
      refetchQueries: [{ query: props.refetchQuery }],
    }).then(() => {
      const updatedItems = items.map((item) => {
        if (item.id === itemId) {
          return {
            ...item,
            name: name,
            responsible: responsible,
            dueDate: dueDate,
            status: status,
          };
        }
        return item;
      });
      notify();
      setItems(updatedItems);
    });
  };

  const handleDelete = (itemId) => {
    if (
      window.confirm(`Are you sure you want to remove this ${props.field}?`)
    ) {
      deleteItem({
        variables: {
          sourceId: sourceId,
          id: itemId,
        },
        refetchQueries: [{ query: refetchQuery }],
      }).then(() => {
        setItems((items) => items.filter((item) => item.id !== itemId));
      });
    }
  };

  const handleExport = useCallback(() => {
    gridRef.current.api.exportDataAsExcel({
      allColumns: true,
    });
  }, []);

  return (
    <>
      {modal && (
        <AddDeliverable
          {...props}
          handleSubmit={handleAdd}
          submitLabel="Submit"
        />
      )}
      {edit && (
        <AddDeliverable
          {...props}
          handleSubmit={(name, responsible, dueDate, status) =>
            handleUpdate(name, responsible, dueDate, status)
          }
          submitLabel="Update"
        />
      )}
      <div className="items-table-container">
        <div className="items-table-header">
          <div className="items-table-button-container">
            <p title="search">
              <AiIcons.AiOutlineSearch onClick={showToolbar} />
            </p>
            <p title="export">
              <AiIcons.AiOutlineDownload onClick={handleExport} />
            </p>
            <p title={`Add ${props.type}`}>
              <AiIcons.AiOutlineAppstoreAdd
                onClick={
                  props.type === "service" && props.isManager
                    ? showModal
                    : props.type !== "service"
                    ? showModal
                    : null
                }
              />
            </p>
          </div>
        </div>
        <div className="ag-theme-alpine" id="items-table">
          <AgGridReact
            ref={gridRef}
            rowData={items}
            columnDefs={columnDefs}
            onGridReady={onGridReady}
            suppressRowClickSelection={true}
            sideBar={toolbar ? "filters" : toolbar}
            frameworkComponents={{
              actionsRenderer: manageItems,
            }}
          />
        </div>
      </div>
    </>
  );
};

export default Deliverables;
