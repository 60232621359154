import React, { useState, useEffect, useRef } from "react";
import { Auth } from "aws-amplify";
import { Logo, Searchbar } from "..";
import * as AiIcons from "react-icons/ai";
import * as FiIcons from "react-icons/fi";
import { Link } from "react-router-dom";

const Header = (props: any) => {
  const signoutRef = useRef(null);
  const [user, setUser] = useState(null);
  const [dropdown, setDropdown] = useState(false);
  const [showSearch, setShowSearch] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (window.innerWidth <= 1024) {
      setIsMobile(true);
    } else setIsMobile(false);
  }, []);

  useEffect(() => {
    const getUser = async () => {
      const authedUser = await Auth.currentAuthenticatedUser();
      if (authedUser) setUser(authedUser);
    };
    getUser();
  }, []);

  const handleLogout = async (e) => {
    e.preventDefault();
    try {
      await Auth.signOut();
    } catch (err) {
      console.error(err);
    }
    window.location.href = "/";
    console.log("Signed out");
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const handleClickOutside = (e) => {
    if (signoutRef.current && !signoutRef.current.contains(e.target)) {
      setDropdown(false);
    }
  };

  return (
    <>
      <div className="header-container is-mobile" data-testd="header">
        <div className="header-logo-container is-mobile">
          <Logo />
        </div>
        {isMobile ? (
          <>{showSearch && <Searchbar />}</>
        ) : (
          <div className="header-searchbar-container is-mobile">
            <Searchbar />
          </div>
        )}

        <div className="header-btn-options-container" ref={signoutRef}>
          {isMobile && (
            <h3 onClick={() => setShowSearch(!showSearch)}>
              <AiIcons.AiOutlineSearch />
            </h3>
          )}
          <h3 onClick={() => setDropdown(!dropdown)}>
            {!isMobile ? (
              user?.attributes["custom:fullName"]
            ) : (
              <AiIcons.AiOutlineUser />
            )}
            <span>
              {dropdown ? (
                <AiIcons.AiFillCaretUp />
              ) : (
                <AiIcons.AiFillCaretDown />
              )}
            </span>
          </h3>
          <div
            className={
              dropdown ? "header-btn-dropdown is-active" : "header-btn-dropdown"
            }
          >
            <Link to="/FAQ" onClick={() => setDropdown(!dropdown)}>
              <p>
                <FiIcons.FiHelpCircle />
                <span>Help & support</span>
              </p>
            </Link>
            <p onClick={handleLogout}>
              <FiIcons.FiLogOut />
              <span>Log out</span>
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
