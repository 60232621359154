/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Auth } from "aws-amplify";
import { toast } from "react-toastify";
import { Table, AdvancedInput, AddItem } from "../../components";
import { SubmitButton, PageBody } from "../../theme";
import { UserLevels, DateFormatter } from "../../utilities";
import { GET_USERS, UPDATE_USER, DELETE_USER } from "../../api";
import { DataContext } from "../../context/DataContext";

const AdminUsers = (props: any) => {
  const { users, setUsers, departments } = React.useContext(DataContext);
  const [username, setUsername] = useState("");
  const [userGroup, setUserGroup] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [fullName, setFullName] = useState("");
  const [team, setTeam] = useState("");
  const [modal, setModal] = useState(false);
  const [columnDefs] = useState([
    {
      field: "name",
      headerName: "Name",
      filter: true,
      sortable: true,
      minWidth: 150,
      width: 150,
    },
    {
      field: "username",
      headerName: "Username",
      filter: true,
      sortable: true,
      minWidth: 150,
      width: 150,
    },
    {
      field: "email",
      headerName: "Email Address",
      cellRendererSelector: () => {
        return {
          component: "linkRenderer",
          params: { url: "mailto://" },
        };
      },
      filter: true,
      sortable: true,
      minWidth: 150,
      width: 150,
    },
    {
      field: "phone",
      headerName: "Phone Number",
      cellRendererSelector: () => {
        return {
          component: "linkRenderer",
          params: { url: "tel://" },
        };
      },
      filter: true,
      sortable: true,
      minWidth: 150,
      width: 150,
    },
    {
      field: "userGroup",
      headerName: "Access Role",
      filter: true,
      sortable: true,
      minWidth: 150,
      width: 150,
    },
    {
      field: "team",
      headerName: "Team",
      filter: true,
      sortable: true,
      minWidth: 150,
      width: 150,
    },
    {
      field: "formattedDate",
      headerName: "Created At",
      minWidth: 150,
      width: 150,
    },
    {
      field: "id",
      headerName: "Actions",
      width: 100,
      cellRendererSelector: () => ({
        component: "actionsRenderer",
        params: {
          id: "id",
          refetchQuery: GET_USERS,
          updateMutation: UPDATE_USER,
          deleteMutation: DELETE_USER,
          isUser: true,
        },
      }),
    },
  ]);

  useEffect(() => {
    if (users)
      setUsers(
        users.map((item) => ({
          ...item,
          formattedDate: DateFormatter({ createdAt: item.createdAt }),
        }))
      );
  }, []);

  const handleAdd = async (e) => {
    e.preventDefault();
    try {
      const { user } = await Auth.signUp({
        username,
        password: "Perennia101!",
        attributes: {
          email: email,
          phone_number: `+1${phone}`,
          "custom:fullName": fullName,
          "custom:userGroup": userGroup,
          "custom:team": team,
        },
      });
      toast.success("Team Member successfully added");
      setUsers([...users, user]);
    } catch (err) {
      toast.error(
        "Error adding team member. Please ensure all fields are filled out."
      );
      console.log("error making user", err);
    }
    setModal(false);
  };

  return (
    <PageBody>
      {modal && (
        <AddItem
          type="User"
          component={[
            <form className="add-item-form" key="add-user" onSubmit={handleAdd}>
              <AdvancedInput
                {...props}
                name="Username"
                type="text"
                width="95%"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
              <AdvancedInput
                {...props}
                name="Full Name"
                type="text"
                width="95%"
                value={fullName}
                onChange={(e) => setFullName(e.target.value)}
              />
              <AdvancedInput
                {...props}
                name="User Level"
                type="dropdown"
                width="95%"
                data={UserLevels}
                fieldName="name"
                value={userGroup}
                onChange={(e) => setUserGroup(e.name)}
              />
              <AdvancedInput
                {...props}
                name="Team"
                type="dropdown"
                width="95%"
                data={departments}
                fieldName="name"
                value={team}
                onChange={(e) => setTeam(e.name)}
              />
              <AdvancedInput
                {...props}
                name="email"
                type="email"
                width="95%"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <AdvancedInput
                {...props}
                name="Phone"
                type="text"
                width="95%"
                value={phone}
                placeholder="+1"
                onChange={(e) => setPhone(e.target.value)}
              />
              <div className="add-item-button-container">
                <div style={{ width: "150px" }} />
                <SubmitButton width="150px">Submit</SubmitButton>
              </div>
            </form>,
          ]}
        />
      )}
      <Table
        title="Users"
        isAdmin
        showAdd
        handleAdd={() => setModal(!modal)}
        count={users.length}
        rowDataProp={users}
        colDefProp={columnDefs}
      />
    </PageBody>
  );
};

export default AdminUsers;
