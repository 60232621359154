import React, { useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import { AdvancedInput, AddItem } from "..";
import { GET_USERS } from "../../api";
import { SubmitButton } from "../../theme";

const AddDeliverable = (props: any) => {
  const { data: userData } = useQuery(GET_USERS);
  const [users, setUsers] = useState([]);
  const [modal, setModal] = useState(true);
  const [name, setName] = useState("");
  const [responsible, setResponsible] = useState("");
  const [dueDate, setDueDate] = useState("");
  const [status, setStatus] = useState("");

  const statusOptions = [
    {
      name: "Not Started",
    },
    {
      name: "In Progress",
    },
    {
      name: "Completed",
    },
  ];

  useEffect(() => {
    if (userData) setUsers(userData?.getAllUsers.users);
  }, [userData]);

  const handleSubmit = (e) => {
    e.preventDefault();
    props.handleSubmit(name, responsible, dueDate, status);
    setModal(false);
  };

  return (
    <>
      {modal && (
        <AddItem
          type="file"
          component={[
            <form
              className="add-item-form"
              key="add-file"
              onSubmit={handleSubmit}
            >
              <AdvancedInput
                {...props}
                name="Deliverable Title"
                fieldName="name"
                type="text"
                width="95%"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
              <AdvancedInput
                {...props}
                name="Responsible"
                fieldName="name"
                type="dropdown"
                data={users}
                width="95%"
                value={responsible}
                onChange={(e) => setResponsible(e.id)}
              />
              <AdvancedInput
                {...props}
                name="Due Date"
                fieldName="dueDate"
                type="date"
                width="95%"
                value={dueDate}
                onChange={(e) => setDueDate(e.target.value)}
              />
              <AdvancedInput
                {...props}
                name="Status"
                data={statusOptions}
                fieldName="name"
                type="dropdown"
                width="95%"
                value={status}
                onChange={(e) => setStatus(e.name)}
              />
              <div className="add-item-button-container">
                <div style={{ width: "150px" }} />
                <SubmitButton width="150px">Submit</SubmitButton>
              </div>
            </form>,
          ]}
        />
      )}
    </>
  );
};

export default AddDeliverable;
