import React, { useState, useReducer, Reducer, useEffect } from "react";
import { Auth } from "aws-amplify";
import { useMutation } from "@apollo/client";
import { toast } from "react-toastify";
import { Button, SubmitButton } from "../../../theme";
import { AdvancedInput, DeleteItem } from "../..";
import { INITIAL_RESOURCE_STATE, ResourceReducer } from "../../../utilities";
import {
  GET_RESOURCES,
  CREATE_RESOURCE,
  UPDATE_RESOURCE,
  DELETE_RESOURCE,
} from "../../../api";
import { DataContext } from "../../../context/DataContext";

const AddResource = (props: any) => {
  const id = props.id;
  const editData = props.data;
  const { programs, projects, users, resourceTypes } =
    React.useContext(DataContext);
  const [createResource] = useMutation(CREATE_RESOURCE);
  const [updateResource] = useMutation(UPDATE_RESOURCE);
  const [stage, setStage] = useState(1);
  const [input, setInput] = useState(null);
  const [programOrProject] = useState("");
  const [isProjects, setIsProjects] = useState(false);
  const [isProgram, setIsProgram] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);
  const [type, setType] = useState("");
  const [isCEU, setIsCEU] = useState(false);
  const [error, setError] = useState(null);
  const [successfullSubmit, setSuccessfullSubmit] = useState(false);
  const [state, dispatch] = useReducer<Reducer<any, any>, any>(
    ResourceReducer,
    INITIAL_RESOURCE_STATE(),
    undefined
  );

  useEffect(() => {
    const getActiveUser = async () => {
      const activeUser = await Auth.currentAuthenticatedUser();
      setCurrentUser(activeUser.attributes.sub);
    };
    getActiveUser();
  }, []);

  const programOrProjectOptions = [
    {
      name: "Program",
    },
    { name: "Project" },
  ];

  useEffect(() => {
    const getInputState = async () => setInput(await state);
    getInputState();
  });

  const handlePrevStage = (e) => {
    e.preventDefault();
    setStage((stage) => stage - 1);
  };

  const handleNextStage = (e) => {
    e.preventDefault();
    setStage((stage) => stage + 1);
  };

  const handleProgramOrProjectSelection = (e) => {
    if (e === "Program") {
      setIsProgram(true);
      setIsProjects(false);
    } else {
      setIsProjects(true);
      setIsProgram(false);
    }
  };

  const handleValidation = () => {
    let formIsValid = true;
    let errors = {};

    if (!props.isEdit && !input.name) {
      formIsValid = false;
      errors["name"] = "name can not be empty";
    }
    setError({ errors: errors });
    return formIsValid;
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    if (handleValidation()) {
      if (props.isEdit) {
        const updatedInputs = JSON.parse(
          JSON.stringify(input, (key, value) => {
            if (Array.isArray(value) && value.length === 0) {
              return undefined;
            } else if (value === null || value === "") {
              return undefined;
            } else {
              return value;
            }
          })
        );
        updateResource({
          variables: { id, input: updatedInputs },
          refetchQueries: [{ query: GET_RESOURCES }],
        });
        toast.success("Resource has successfully been updated");
      } else {
        createResource({
          variables: {
            newResource: {
              ...input,
              username: currentUser,
            },
          },
          refetchQueries: [{ query: GET_RESOURCES }],
        });
        toast.success(
          "Resource has successfully been added. You can now close the window."
        );
        setSuccessfullSubmit(true);
        console.log(input);
      }
      setInput(INITIAL_RESOURCE_STATE());
    } else {
      toast.error(
        "OOPS! that didn't work. Please verify the information and try again"
      );
      setStage(1);
    }
  };

  return (
    <form className="add-item-form" onSubmit={handleSubmit}>
      {stage === 1 && (
        <>
          <AdvancedInput
            {...props}
            name="Resource Name"
            type="text"
            width="95%"
            isRequired
            placeholder={editData?.name}
            value={state.name}
            onChange={(e) =>
              dispatch({ field: "name", payload: e.target.value })
            }
          />
          <span className="error-text">{error?.errors.name}</span>
          <AdvancedInput
            {...props}
            name="type"
            type="dropdown"
            width="95%"
            fieldName="name"
            data={resourceTypes}
            placeholder={editData?.type}
            value={state.type}
            onChange={(e) => {
              setType(e.name);
              dispatch({ field: "type", payload: e.name });
            }}
          />
          <AdvancedInput
            {...props}
            name="Program or Project"
            type="dropdown"
            width="95%"
            fieldName="name"
            data={programOrProjectOptions}
            placeholder={editData?.type}
            value={programOrProject}
            onChange={(e) => handleProgramOrProjectSelection(e.name)}
          />
          {isProgram && (
            <AdvancedInput
              {...props}
              name="Program"
              type="dropdown"
              width="95%"
              fieldName="name"
              data={programs}
              placeholder={editData?.program}
              value={state.program}
              onChange={(e) => dispatch({ field: "program", payload: e.id })}
            />
          )}
          {isProjects && (
            <AdvancedInput
              {...props}
              name="Project"
              type="dropdown"
              width="95%"
              fieldName="name"
              data={projects}
              placeholder={editData?.program}
              value={state.program}
              onChange={(e) => dispatch({ field: "program", payload: e.id })}
            />
          )}
          <AdvancedInput
            {...props}
            name="Link"
            type="text"
            width="95%"
            placeholder={editData?.link}
            value={state.link}
            onChange={(e) =>
              dispatch({ field: "link", payload: e.target.value })
            }
          />
          <AdvancedInput
            {...props}
            name="Date"
            type="date"
            width="95%"
            placeholder={editData?.name}
            value={state.dateHeld}
            onChange={(e) =>
              dispatch({ field: "dateHeld", payload: e.target.value })
            }
          />
          {type === "Field Day" ||
          type === "Workshop" ||
          type === "Webinar" ||
          type === "Presentation" ||
          type === "Conference" ? (
            <AdvancedInput
              {...props}
              name="Number of Participants"
              type="text"
              width="95%"
              placeholder={editData?.participants}
              value={state.participants}
              onChange={(e) =>
                dispatch({ field: "participants", payload: e.target.value })
              }
            />
          ) : type === "Publication" || type === "Fact Sheets" ? (
            <AdvancedInput
              {...props}
              name="Number of Copies"
              type="text"
              width="95%"
              placeholder={editData?.copies}
              value={state.copies}
              onChange={(e) =>
                dispatch({ field: "copies", payload: e.target.value })
              }
            />
          ) : type === "Blog" || type === "Webinar" ? (
            <AdvancedInput
              {...props}
              name="Number of Views"
              type="text"
              width="95%"
              placeholder={editData?.views}
              value={state.views}
              onChange={(e) =>
                dispatch({ field: "views", payload: e.target.value })
              }
            />
          ) : type === "Podcast" || type === "Videos" ? (
            <AdvancedInput
              {...props}
              name="Number of Listeners"
              type="text"
              width="95%"
              placeholder={editData?.listeners}
              value={state.listeners}
              onChange={(e) =>
                dispatch({ field: "listeners", payload: e.target.value })
              }
            />
          ) : null}
        </>
      )}
      {stage === 2 && (
        <>
          <AdvancedInput
            {...props}
            name="Author"
            type="dropdown"
            width="95%"
            fieldName="name"
            data={users}
            value={state.author}
            onChange={(e) => dispatch({ field: "author", payload: e.id })}
          />
          <AdvancedInput
            {...props}
            name="Description"
            type="textArea"
            width="95%"
            value={state.description}
            placeholder={editData?.description}
            onChange={(e) =>
              dispatch({ field: "description", payload: e.target.value })
            }
          />
          <AdvancedInput
            {...props}
            name="CEU"
            type="select"
            onChange={() => {
              setIsCEU(!isCEU);
              dispatch({ field: "ceu" });
            }}
          />
          {isCEU && (
            <AdvancedInput
              {...props}
              name="CEU Description"
              type="textArea"
              width="95%"
              value={state.ceuDescription}
              placeholder={editData?.ceuDescription}
              onChange={(e) =>
                dispatch({ field: "ceuDescription", payload: e.target.value })
              }
            />
          )}
        </>
      )}
      <div className="add-item-button-container">
        {stage === 1 ? (
          <div style={{ width: "150px" }} />
        ) : (
          <Button width="150px" onClick={handlePrevStage}>
            Go Back
          </Button>
        )}
        {stage !== 2 ? (
          <Button width="150px" onClick={handleNextStage}>
            Next
          </Button>
        ) : successfullSubmit ? (
          <Button width="150px" disabled={successfullSubmit}>
            Success
          </Button>
        ) : (
          <SubmitButton width="150px" disabled={successfullSubmit}>
            Submit
          </SubmitButton>
        )}
      </div>
      {stage === 2 && props.isEdit ? (
        <DeleteItem
          id={id}
          user={props.user}
          mutation={DELETE_RESOURCE}
          refetch={GET_RESOURCES}
          type="Resource"
        />
      ) : null}
    </form>
  );
};

export default AddResource;
