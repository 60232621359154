import React from "react";

const Loading = () => {
  return (
    <div
      className="load-body"
      style={{
        backgroundImage: `url(${
          process.env.PUBLIC_URL + "/images/app-bg.svg"
        })`,
      }}
    >
      <div className="load-animation" />
      <div className="load-text">
        <p>Working on it...</p>
      </div>
    </div>
  );
};

export default Loading;
