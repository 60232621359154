import { gql } from "@apollo/client";

export const GET_USERS = gql`
  query GetAllUsers($nextToken: String) {
    getAllUsers(nextToken: $nextToken) {
      users {
        id
        username
        email
        phone
        name
        team
        userGroup
        dashboardOrder
        notifications {
          id
          userId
          itemId
          type
          read
          createdBy
          createdAt
        }
        createdAt
      }
      nextToken
    }
  }
`;
export const GET_USER_BY_ID = gql`
  query GetUserById($id: ID!) {
    getUserById(id: $id) {
      id
      username
      email
      phone
      name
      team
      userGroup
      dashboardOrder
      notifications {
        id
        userId
        itemId
        type
        read
        createdBy
        createdAt
      }
      createdAt
    }
  }
`;

export const UPDATE_USER = gql`
  mutation updateUser($id: ID!, $input: UserInput) {
    updateUser(id: $id, input: $input) {
      id
      username
      email
      phone
      name
      team
      userGroup
      dashboardOrder
      notifications {
        id
        userId
        itemId
        type
        read
        createdBy
        createdAt
      }
      createdAt
    }
  }
`;

export const DELETE_USER = gql`
  mutation deleteUser($id: ID!) {
    deleteUser(id: $id) {
      id
      username
      email
      phone
      name
      team
      userGroup
      dashboardOrder
      notifications {
        id
        userId
        itemId
        type
        read
        createdBy
        createdAt
      }
      createdAt
    }
  }
`;
