import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { toast } from "react-toastify";

const DeleteItem = ({ id, mutation, refetch, type, user }: any) => {
  const navigate = useNavigate();
  const [deleteItem] = useMutation(mutation);
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    if (user?.attributes["custom:userGroup"] !== "Team Member") {
      setIsAdmin(true);
    }
  }, [user]);

  const handleDelete = (e) => {
    e.preventDefault();
    try {
      if (
        window.confirm(
          `Are you sure you want to delete this ${type}? All information will be removed.`
        )
      ) {
        deleteItem({
          variables: {
            id,
          },
          refetchQueries: [{ query: refetch }],
        });
        toast.success(`${type} deleted successfully`);
        navigate(-1);
      }
    } catch (err) {
      toast.error(`Error deleting ${type}`, err);
    }
  };

  return (
    <>
      {isAdmin && (
        <div className="delete-item-button" onClick={handleDelete}>
          <p>Delete {type}</p>
        </div>
      )}
    </>
  );
};

export default DeleteItem;
