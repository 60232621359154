import React, { useState } from "react";
import { InfoCard } from "..";

const Editor = (props: any) => {
  const [editor, setEditor] = useState(true);
  const showEditor = () => setEditor(!editor);

  return (
    <div className={editor ? "editor" : "editor is-notActive"}>
      <InfoCard
        className="editor-card"
        title={`Update ${props.type}`}
        headerOption="modal"
        // height="auto"
        width="350px"
        backgroundColor="white"
        onBtnClick={showEditor}
        fields={[<div className="editor-fields">{props.component}</div>]}
      />
    </div>
  );
};

export default Editor;
