import React, { useState, useEffect, useContext } from "react";
import { useMutation } from "@apollo/client";
import { Auth } from "aws-amplify";
import { CognitoIdentityProvider } from "@aws-sdk/client-cognito-identity-provider";
import { toast } from "react-toastify";
import { DataContext } from "../../context/DataContext";
import { InfoCard, AdvancedInput } from "../../components";
import {
  TableIcon,
  Button,
  SubmitButton,
  TableExternalLink,
} from "../../theme";
import { UserLevels } from "../../utilities";
import awsmobile from "../../aws-exports";
import * as FaIcons from "react-icons/fa";
import * as SiIcons from "react-icons/si";

export const EyeRenderer = (props: any) => {
  return (
    <TableIcon to={`${props.url}/${props.value}`}>
      <Button width="36px">
        <FaIcons.FaEye />
      </Button>
    </TableIcon>
  );
};

export const StatusRenderer = (props: any) => {
  return (
    <>
      {props.value === "Pending" ? (
        <p className="status-icon pending">
          <FaIcons.FaHourglassStart />
        </p>
      ) : props.value === "Active" ? (
        <p className="status-icon approved">
          <FaIcons.FaCheckSquare />
        </p>
      ) : props.value === "Inactive" ? (
        <p className="status-icon inactive">
          <FaIcons.FaTimesCircle />
        </p>
      ) : null}
    </>
  );
};

export const StatusRendererPRS = (props: any) => {
  return (
    <>
      {props.value === "Pending" && <div className="status-bar pending" />}
      {props.value === "Active" && <div className="status-bar active" />}
      {props.value === "Inactive" && <div className="status-bar inactive" />}
    </>
  );
};

export const OneDriveRenderer = (props: any) => {
  return (
    <TableExternalLink
      className="action-btns-container"
      href={`${props.value}`}
      target="_blank"
    >
      <Button
        width="36px"
        style={{
          backgroundColor: "#385cad",
          color: "#ffffff",
          fontSize: "16px",
        }}
      >
        <SiIcons.SiMicrosoftonedrive />
      </Button>
      <div style={{ width: "26px" }} />
    </TableExternalLink>
  );
};

export const FunderRenderer = (props: any) => {
  return (
    <>
      {props.value === true && (
        <div className="table-renderer-isFunder">
          <p>Funder</p>
        </div>
      )}
    </>
  );
};

export const LinkRenderer = (props: any) => {
  return (
    <a
      href={`${props.url}${props.value}`}
      target="_blank"
      style={{ fontStyle: "italic", color: "#009999" }}
      aria-label={`${props.value}`}
      rel="noopener noreferrer"
    >
      {props.value}
    </a>
  );
};

export const ActionsRenderer = (props: any) => {
  const updateMutation = props.updateMutation;
  const deleteMutation = props.deleteMutation;
  const refetchQuery = props.refetchQuery;
  const { departments, users } = useContext(DataContext);
  const [updateItem] = useMutation(updateMutation);
  const [deleteItem] = useMutation(deleteMutation);
  const [userToEdit, setUserToEdit] = useState(null);
  const [name, setName] = useState("");
  const [code, setCode] = useState("");
  const [userGroup, setUserGroup] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [fullName, setFullName] = useState("");
  const [team, setTeam] = useState("");
  const [modal, setModal] = useState(false);

  //Get selected USer
  useEffect(() => {
    if (props.isUser) {
      const user = users.find((user) => user.id === props.value);
      setUserToEdit(user);
    }
  }, [props.isUser, props.value, users]);

  const handleDelete = async (e) => {
    e.preventDefault();
    let idToDelete = await props.value;
    if (window.confirm("Are you sure you want to delete this item?")) {
      if (props.isUser) {
        // Delete user in Cognito
        const user = await Auth.currentCredentials();
        const cognitoIdentityServiceProvider = new CognitoIdentityProvider({
          region: awsmobile.aws_cognito_region,
          credentials: Auth.essentialCredentials(user),
        });
        const userPoolId = awsmobile.aws_user_pools_id;
        const params = {
          UserPoolId: userPoolId,
          Username: userToEdit.username,
        };
        cognitoIdentityServiceProvider.adminDeleteUser(
          params,
          function (err, data) {
            if (err) {
              console.log(err, err.stack);
            } else {
              console.log(data);
            }
          }
        );
      }
      deleteItem({
        variables: { id: idToDelete },
        refetchQueries: [{ query: refetchQuery }],
      });
      toast.success("Item has been successfully removed");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const idToUpdate = await props.value;

    if (props.isUser) {
      // Update user in DynamoDB
      updateItem({
        variables: {
          id: idToUpdate,
          input: {
            username: props.username,
            name: fullName,
            email: email,
            phone: `+1${phone}`,
            userGroup: userGroup,
            team: team,
          },
        },
        refetchQueries: [{ query: refetchQuery }],
      });
      const user = await Auth.currentCredentials();
      const cognitoIdentityServiceProvider = new CognitoIdentityProvider({
        region: awsmobile.aws_cognito_region,
        credentials: Auth.essentialCredentials(user),
      });
      const userPoolId = awsmobile.aws_user_pools_id;
      const params = {
        UserPoolId: userPoolId,
        Username: userToEdit.username,
        UserAttributes: [
          { Name: "custom:fullName", Value: fullName },
          { Name: "email", Value: email },
          { Name: "phone_number", Value: `+1${phone}` },
          { Name: "custom:team", Value: team },
          { Name: "custom:userGroup", Value: userGroup },
          { Name: "email_verified", Value: "true" },
          { Name: "phone_number_verified", Value: "true" },
        ],
      };
      cognitoIdentityServiceProvider.adminUpdateUserAttributes(
        params,
        (err) => {
          if (err) {
            console.log(err);
          }
        }
      );
    } else if (props.isTeams) {
      updateItem({
        variables: {
          id: idToUpdate,
          input: {
            name: name,
            code: code,
          },
        },
        refetchQueries: [{ query: refetchQuery }],
      });
    } else {
      updateItem({
        variables: {
          id: idToUpdate,
          input: {
            name: name,
          },
        },
        refetchQueries: [{ query: refetchQuery }],
      });
    }
    toast.success(
      "Item has been successfully updated. You can close the edit window now."
    );
    setModal(!modal);
  };

  const handleClose = (e) => {
    e.preventDefault();
    setModal(!modal);
  };

  return (
    <>
      {modal && (
        <div
          className={modal ? "edit-table-item is-Active" : "edit-table-item"}
        >
          <InfoCard
            className="edit-table-item-modal"
            title="Edit Item"
            headerOption="modal"
            height={props.isUser ? "325px" : "auto"}
            width="25vw"
            backgroundColor="white"
            onBtnClick={handleClose}
            fields={[
              <>
                <form
                  className={props.isUser ? "user-edit" : ""}
                  onSubmit={handleSubmit}
                >
                  {props.isUser ? (
                    <>
                      <AdvancedInput
                        {...props}
                        name="Full Name"
                        type="text"
                        width="95%"
                        value={fullName}
                        onChange={(e) => setFullName(e.target.value)}
                      />
                      <AdvancedInput
                        {...props}
                        name="User Level"
                        type="dropdown"
                        width="95%"
                        data={UserLevels}
                        fieldName="name"
                        value={userGroup}
                        onChange={(e) => setUserGroup(e.name)}
                      />
                      <AdvancedInput
                        {...props}
                        name="Team"
                        type="dropdown"
                        width="95%"
                        data={departments}
                        fieldName="name"
                        value={team}
                        onChange={(e) => setTeam(e.name)}
                      />
                      <AdvancedInput
                        {...props}
                        name="email"
                        type="email"
                        width="95%"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                      <AdvancedInput
                        {...props}
                        name="Phone"
                        type="text"
                        width="95%"
                        value={phone}
                        placeholder="+1"
                        onChange={(e) => setPhone(e.target.value)}
                      />
                    </>
                  ) : (
                    <>
                      <AdvancedInput
                        {...props}
                        type="text"
                        width="90%"
                        name="Name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                      />
                      {props.isTeams && (
                        <AdvancedInput
                          {...props}
                          type="text"
                          width="90%"
                          name="Code"
                          value={code}
                          onChange={(e) => setCode(e.target.value)}
                        />
                      )}
                    </>
                  )}
                  <SubmitButton width="200px" onClick={handleSubmit}>
                    Submit
                  </SubmitButton>
                </form>
              </>,
            ]}
          />
        </div>
      )}
      <div className="table-action-btns">
        <SubmitButton width="36px">
          <FaIcons.FaEdit onClick={() => setModal(true)} />
        </SubmitButton>
        <Button width="36px">
          <FaIcons.FaTrash onClick={handleDelete} />
        </Button>
      </div>
    </>
  );
};
