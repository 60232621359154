import React, { useState } from "react";
import { Auth } from "aws-amplify";
import { LoginBtn, ForgotPassword, AdvancedInput } from "../../components";

const Login = () => {
  const [mfa, setMFA] = useState(false);
  const [authedUser, setUser] = useState([]);
  const [email, setEmail] = useState("");
  const [userCode, setUserCode] = useState("");
  const [password, setPassword] = useState("");
  const [clicked, setClicked] = useState(false);
  const [forgotPassword, setForgotPassword] = useState(false);

  const showForgotPassword = () => setForgotPassword(!forgotPassword);

  const varificationIsEmpty = userCode.length < 6 || userCode.length > 6;

  const onSubmit = async (e) => {
    e.preventDefault();
    try {
      const user = await Auth.signIn(email, password);
      setUser(user);
      setClicked(true);
      setMFA(true);
    } catch (err) {
      console.log("Failed to login", err);
      alert("OOPS! That didn't work, please try again.");
    }
  };

  const handleCodeSumbit = () => {
    const validationIsNumber = new RegExp("^[0-9]*$");
    if (validationIsNumber.test(userCode)) {
      Auth.confirmSignIn(authedUser, userCode)
        .then(() => {
          console.log("success");
          setMFA(false);
          window.location.href = "/";
        })
        .catch((err) => {
          console.log(err);
          alert("OOPS! That didn't work, please try again.");
        });
    } else {
      alert("Validation Code must contain 6 numbers");
    }
  };

  const onForgotPW = (e) => {
    e.preventDefault();
    showForgotPassword();
  };

  return (
    <div className="login-container" data-testid="login">
      {forgotPassword && <ForgotPassword key="forgotPw" />}
      <div className="app-version">{process.env.REACT_APP_VERSION_NUMBER}</div>
      <div
        className="login-left-container"
        data-testid="login-splash"
        style={{
          backgroundImage: `url(/images/loginsplash.jpg)`,
        }}
      />
      <div className="login-right-container">
        <img
          className="main-logo"
          src={process.env.PUBLIC_URL + "/images/perennia-logo.png"}
          alt="Perennia"
          aria-label="Perennia Food and Agriculture"
          data-testid="login-main-logo"
        />
        <h2>Perennia CRM</h2>
        {mfa ? (
          <div className="MFA-items-container">
            <p>
              A verification code was sent to your phone. Please enter it below
            </p>
            <AdvancedInput
              type="text"
              width="225px"
              name="Temporary 6-digit code"
              value={userCode}
              onChange={(e) => setUserCode(e.target.value)}
            />
            <LoginBtn
              disabled={varificationIsEmpty}
              text={clicked ? "Confirm Code" : "waiting..."}
              onClick={handleCodeSumbit}
            />
          </div>
        ) : (
          <>
            <p>Welcome back! Please login with your provided credentials</p>
            <form
              className="login-form"
              onSubmit={onSubmit}
              data-testid="login-form"
            >
              <label htmlFor="email">Username</label>
              <input
                className="login-input"
                type="text"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <label htmlFor="password">Password</label>
              <input
                className="login-input"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <p className="forgot-pw" onClick={onForgotPW}>
                Forgot Password?
              </p>
              <LoginBtn text={clicked ? "waiting..." : "Login"} />
            </form>
          </>
        )}
      </div>
    </div>
  );
};

export default Login;
