import { gql } from "@apollo/client";

export const GET_CERTIFICATIONS = gql`
  query getAllCertification($nextToken: String) {
    getAllCertification(nextToken: $nextToken) {
      certifications {
        id
        name
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const GET_CERTIFICATION_BY_ID = gql`
  query getCertificationById($id: ID!) {
    getCertificationById(id: $id) {
      id
      name
      createdAt
      updatedAt
    }
  }
`;

export const CREATE_CERTIFICATION = gql`
  mutation createCertifcation($newCertification: CertificationInput!) {
    createCertification(newCertification: $newCertification) {
      id
      name
      createdAt
      updatedAt
    }
  }
`;

export const UPDATE_CERTIFICATION = gql`
  mutation updateCertification($id: ID!, $input: CertificationInput!) {
    updateCertification(id: $id, input: $input) {
      id
      name
      createdAt
      updatedAt
    }
  }
`;

export const DELETE_CERTIFICATION = gql`
  mutation deleteCertification($id: ID!) {
    deleteCertification(id: $id) {
      id
      name
      createdAt
      updatedAt
    }
  }
`;
