import React from 'react';

const ComingSoon = (props: any) => {
    return (
        <div className="coming-soon">
            <h3>Watch this Space!</h3>
            <p>
                The {props.fieldName} module is coming in relase {props.release}!
            </p>
        </div>
    )
};

export default ComingSoon;
