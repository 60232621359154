import React, { useState, useEffect, useRef, useCallback } from "react";
import { Auth } from "aws-amplify";
import { useMutation } from "@apollo/client";
import { AgGridReact } from "ag-grid-react";
import { toast } from "react-toastify";
import { AddClaimAndInvoice } from "..";
import * as FaIcons from "react-icons/fa";
import * as AiIcons from "react-icons/ai";

const ClaimsAndInvoices = (props: any) => {
  const sourceId = props.id;
  const [itemId, setItemId] = useState(null);
  const [refetchQuery] = useState(props.refetchQuery);
  const [modal, setModal] = useState(false);
  const [edit, setEdit] = useState(false);
  const [toolbar, setToolbar] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);
  const [items, setItems] = useState([]);
  const [createItem] = useMutation(props.createMutation);
  const [updateItem] = useMutation(props.updateMutation);
  const [deleteItem] = useMutation(props.deleteMutation);
  const [gridApi, setGridApi] = useState(null);
  const gridRef = useRef(null);
  const [invoiceColumnDefs] = useState([
    {
      field: "date",
      headerName: "Date",
      filter: true,
      sortable: true,
      minWidth: 150,
      width: 285,
    },
    {
      field: "invoiceNumber",
      headerName: "Invoice Number",
      filter: true,
      sortable: true,
      minWidth: 150,
      width: 285,
    },
    {
      field: "paid",
      headerName: "Paid",
      filter: true,
      sortable: true,
      minWidth: 150,
      width: 285,
    },
    {
      field: "id",
      headerName: "Actions",
      textAlign: "center",
      minWidth: 150,
      width: 150,
      cellRendererSelector: () => ({
        component: "actionsRenderer",
        params: {
          id: "id",
          refetchQuery: props.refetchQuery,
          updateMutation: props.updateMutation,
          deleteMutation: props.deleteMutation,
        },
      }),
    },
  ]);

  const [claimColumnDefs] = useState([
    {
      field: "date",
      headerName: "Date",
      filter: true,
      sortable: true,
      minWidth: 150,
      width: 285,
    },
    {
      field: "claimNumber",
      headerName: "Claims Number",
      filter: true,
      sortable: true,
      minWidth: 150,
      width: 285,
    },
    {
      field: "paid",
      headerName: "Paid",
      filter: true,
      sortable: true,
      minWidth: 150,
      width: 285,
    },
    {
      field: "id",
      headerName: "Actions",
      textAlign: "center",
      minWidth: 150,
      width: 150,
      cellRendererSelector: () => ({
        component: "actionsRenderer",
        params: {
          id: "id",
          refetchQuery: props.refetchQuery,
          updateMutation: props.updateMutation,
          deleteMutation: props.deleteMutation,
        },
      }),
    },
  ]);

  const showModal = () => setModal(!modal);
  const showToolbar = () => setToolbar(!toolbar);

  useEffect(() => {
    if (props.data)
      setItems(
        props.data.map((item) => ({
          ...item,
          formattedDate: item.date.slice(0, 10),
        }))
      );
  }, [props.data]);

  useEffect(() => {
    getActiveUser();
  }, [currentUser]);

  useEffect(() => {
    if (gridApi) {
      gridApi.sizeColumnsToFit();
    }
  });

  const getActiveUser = async () => {
    const activeUser = await Auth.currentAuthenticatedUser();
    setCurrentUser(activeUser.username);
  };

  const onGridReady = (params: any) => {
    setGridApi(params.api);
  };

  const manageItems = (props: any) => {
    const handleEdit = () => {
      setItemId(props.value);
      setEdit(!edit);
    };

    const onDelete = () => {
      handleDelete(props.value);
    };

    return (
      <>
        <div className="action-btns-container">
          <p>
            <FaIcons.FaEdit onClick={handleEdit} />
          </p>
          <p>
            <FaIcons.FaTrash onClick={onDelete} />
          </p>
        </div>
      </>
    );
  };

  // User Options
  const handleAdd = (date, invoiceNumber, paid) => {
    if (props.type === "service") {
      createItem({
        variables: {
          sourceId: sourceId,
          newInvoice: {
            username: currentUser,
            date: date,
            invoiceNumber: invoiceNumber,
            paid: paid,
          },
        },
        refetchQueries: [{ query: refetchQuery }],
      }).then((item) => {
        setItems([item.data.createInvoice, ...items]);
        toast.success(
          `Thank you ${currentUser}, your ${props.field} has been recorded.`
        );
      });
    } else {
      createItem({
        variables: {
          sourceId: sourceId,
          newClaim: {
            username: currentUser,
            date: date,
            claimNumber: invoiceNumber,
            paid: paid,
          },
        },
        refetchQueries: [{ query: refetchQuery }],
      }).then((item) => {
        setItems([item.data.createClaim, ...items]);
        toast.success(
          `Thank you ${currentUser}, your ${props.field} has been recorded.`
        );
      });
    }
  };

  const handleUpdate = (date, invoiceNumber, paid) => {
    if (props.type === "service") {
      updateItem({
        variables: {
          sourceId: sourceId,
          id: itemId,
          input: {
            date: date,
            invoiceNumber: invoiceNumber,
            paid: paid,
          },
        },
        refetchQueries: [{ query: props.refetchQuery }],
      }).then(() => {
        const updatedItems = items.map((item) => {
          if (item.id === itemId) {
            return {
              ...item,
              date: date,
              invoiceNumber: invoiceNumber,
              paid: paid,
            };
          }
          return item;
        });
        setItems(updatedItems);
        toast.success(`${props.field} has been successfully updated.`);
      });
    } else {
      updateItem({
        variables: {
          sourceId: sourceId,
          id: itemId,
          input: {
            date: date,
            claimsNumber: invoiceNumber,
            paid: paid,
          },
        },
        refetchQueries: [{ query: props.refetchQuery }],
      }).then(() => {
        const updatedItems = items.map((item) => {
          if (item.id === itemId) {
            return {
              ...item,
              date: date,
              claimsNumber: invoiceNumber,
              paid: paid,
            };
          }
          return item;
        });
        setItems(updatedItems);
        toast.success(`${props.field} has been successfully updated.`);
      });
    }
  };

  const handleDelete = (itemId) => {
    if (
      window.confirm(`Are you sure you want to remove this ${props.field}?`)
    ) {
      deleteItem({
        variables: {
          sourceId: sourceId,
          id: itemId,
        },
        refetchQueries: [{ query: refetchQuery }],
      }).then(() => {
        setItems((items) => items.filter((item) => item.id !== itemId));
      });
    }
  };

  const handleExport = useCallback(() => {
    gridRef.current.api.exportDataAsExcel({
      allColumns: true,
    });
  }, []);

  return (
    <>
      {modal && (
        <AddClaimAndInvoice
          {...props}
          handleSubmit={handleAdd}
          submitLabel="Submit"
        />
      )}
      {edit && (
        <AddClaimAndInvoice
          {...props}
          handleSubmit={
            props.type === "service"
              ? (date, invoiceNumber, paid) =>
                  handleUpdate(date, invoiceNumber, paid)
              : (date, invoiceNumber, paid) =>
                  handleUpdate(date, invoiceNumber, paid)
          }
          submitLabel="Update"
        />
      )}
      <div className="items-table-container">
        <div className="items-table-header">
          <div className="items-table-button-container">
            <p title="search">
              <AiIcons.AiOutlineSearch onClick={showToolbar} />
            </p>
            <p title="export">
              <AiIcons.AiOutlineDownload onClick={handleExport} />
            </p>
            <p title={`Add ${props.type}`}>
              <AiIcons.AiOutlineAppstoreAdd onClick={showModal} />
            </p>
          </div>
        </div>
        <div className="ag-theme-alpine" id="claims-and-invoices-table">
          <AgGridReact
            ref={gridRef}
            rowData={items}
            columnDefs={
              props.type === "service" ? invoiceColumnDefs : claimColumnDefs
            }
            onGridReady={onGridReady}
            suppressRowClickSelection={true}
            sideBar={toolbar ? "filters" : toolbar}
            frameworkComponents={{
              actionsRenderer: manageItems,
            }}
          />
        </div>
      </div>
    </>
  );
};

export default ClaimsAndInvoices;
