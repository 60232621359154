import React, { useState, useEffect } from "react";
import { useQuery, useMutation } from "@apollo/client";
import { toast } from "react-toastify";
import { Table, AdvancedInput, AddItem } from "../../components";
import {
  GET_RESOURCETYPES,
  CREATE_RESOURCETYPE,
  UPDATE_RESOURCETYPE,
  DELETE_RESOURCETYPE,
} from "../../api";
import { DateFormatter } from "../../utilities";
import { SubmitButton, PageBody } from "../../theme";

const AdminResourceTypes = (props: any) => {
  const { data: resourceData } = useQuery(GET_RESOURCETYPES);
  const [name, setName] = useState("");
  const [modal, setModal] = useState(false);
  const [items, setITems] = useState([]);
  const [addItem] = useMutation(CREATE_RESOURCETYPE);
  const [columnDefs] = useState([
    {
      field: "name",
      headerName: "Name",
      filter: true,
      sortable: true,
      minWidth: 500,
      width: 500,
    },
    {
      field: "formattedDate",
      headerName: "Created At",
      minWidth: 150,
      width: 150,
    },
    {
      field: "id",
      headerName: "Actions",
      width: 100,
      cellRendererSelector: () => ({
        component: "actionsRenderer",
        params: {
          id: "id",
          refetchQuery: GET_RESOURCETYPES,
          updateMutation: UPDATE_RESOURCETYPE,
          deleteMutation: DELETE_RESOURCETYPE,
        },
      }),
    },
  ]);

  const showModal = () => setModal(!modal);

  useEffect(() => {
    if (resourceData) {
      setITems(
        resourceData?.getAllResourceTypes.resourceTypes.map((item) => ({
          ...item,
          formattedDate: DateFormatter({ createdAt: item.createdAt }),
        }))
      );
    }
  }, [resourceData]);

  const handleAdd = (e) => {
    e.preventDefault();
    const notify = () => toast.success("Resource Type successfully added");
    addItem({
      variables: {
        newResourceType: {
          name: name,
        },
      },
      refetchQueries: [{ query: GET_RESOURCETYPES }],
    });
    notify();
    setName("");
    setModal(false);
  };

  return (
    <PageBody>
      {modal && (
        <AddItem
          type="Resource-Type"
          component={[
            <form
              className="add-item-form"
              key="add-service-type"
              onSubmit={handleAdd}
            >
              <AdvancedInput
                {...props}
                name="Resource-Type"
                fieldName="name"
                type="text"
                width="95%"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
              <div className="add-item-button-container">
                <div style={{ width: "150px" }} />
                <SubmitButton width="150px">Submit</SubmitButton>
              </div>
            </form>,
          ]}
        />
      )}
      <Table
        title="Resource Types"
        isAdmin
        showAdd
        handleAdd={showModal}
        count={items.length}
        rowDataProp={items}
        colDefProp={columnDefs}
      />
    </PageBody>
  );
};

export default AdminResourceTypes;
