/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
/* eslint-disable no-useless-escape */
import React, { useState, useReducer, Reducer, useEffect } from "react";
import { useMutation } from "@apollo/client";
import { toast } from "react-toastify";
import { Button, SubmitButton } from "../../../theme";
import {
  AdvancedInput,
  CategorySelect,
  CompanyNotFound,
  DeleteItem,
} from "../../";
import {
  CREATE_CONTACT,
  GET_CONTACTS,
  UPDATE_CONTACT,
  DELETE_CONTACT,
  CREATE_UPDATE,
} from "../../../api";
import {
  ContactTitles,
  ContactReducer,
  INITIAL_CONTACT_STATE,
} from "../../../utilities";
import { DataContext } from "../../../context/DataContext";

const AddContact = (props: any) => {
  const id = props.id;
  const editData = props.data;
  const { contacts, companies, industries, contactTypes, categories, users } =
    React.useContext(DataContext);
  const [createNewContact] = useMutation(CREATE_CONTACT);
  const [updateContact] = useMutation(UPDATE_CONTACT);
  const [createUpdate] = useMutation(CREATE_UPDATE);
  const [stage, setStage] = useState(1);
  const [moreFields, setMoreFields] = useState(true);
  const [useCompanyAddress, setUseCompanyAddress] = useState(false);
  const [useCompanyPhone, setUseCompanyPhone] = useState(false);
  const [useCompanyTags, setUseCompanyTags] = useState(false);
  const [pairedCompany, setPairedCompany] = useState(null);
  const [input, setInput] = useState(null);
  const [error, setError] = useState(null);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [successfullSubmit, setSuccessfullSubmit] = useState(false);
  const [state, dispatch] = useReducer<Reducer<any, any>, any>(
    ContactReducer,
    INITIAL_CONTACT_STATE(),
    undefined
  );

  useEffect(() => {
    const getInputState = async () => {
      const name =
        firstName && lastName ? `${firstName} ${lastName}` : state.name;
      setInput({
        ...(await state),
        name,
      });
    };
    getInputState();
  }, [state, firstName, lastName]);

  const ShowPhoneFields = (e) => {
    e.preventDefault();
    setMoreFields(!moreFields);
  };

  // Gets the selected companies ID and sets the company address to the selected company
  const prepareCompanyForAutofill = (id) => {
    const companyMatch = companies.find((item) => item.id === id);
    setPairedCompany(companyMatch);
  };

  const handlePrevStage = (e) => {
    e.preventDefault();
    setStage((stage) => stage - 1);
  };

  const handleNextStage = (e) => {
    e.preventDefault();
    setStage((stage) => stage + 1);
  };

  const handleUseCompanyAddress = (e) => {
    if (!pairedCompany) {
      e.preventDefault();
      toast.error("Please select a Company first");
    } else {
      setUseCompanyAddress(!useCompanyAddress);
    }
  };

  const handleUseCompanyPhone = (e) => {
    if (!pairedCompany) {
      e.preventDefault();
      toast.error("Please select a Company first");
    } else {
      setUseCompanyPhone(!useCompanyPhone);
    }
  };

  const handleUseCompanyTags = (e) => {
    if (!pairedCompany) {
      e.preventDefault();
      toast.error("Please select a Company first");
    } else {
      console.log("pairedCompany", pairedCompany.categories);
      setUseCompanyTags(!useCompanyTags);
    }
  };

  const handleValidation = () => {
    const int = new RegExp(/^[0-9,]*$/);

    const companyDuplicate = companies?.find((item) => {
      if (item.id?.includes(input.companyName)) {
        return item;
      }
    });
    const contactDuplicate = contacts?.find((item) => {
      if (item.name?.includes(input.name)) {
        return item;
      }
    });
    let formIsValid = true;
    let errors = {};

    if (!props.isEdit) {
      if (!input.name) {
        formIsValid = false;
        errors["name"] = "name can not be empty";
      }
    }

    if (!props.isEdit) {
      if (contactDuplicate && companyDuplicate) {
        formIsValid = false;
        errors["name"] = "Contact already exists with that company";
      }
    }

    if (!int.test(input.ext)) {
      formIsValid = false;
      errors["ext"] = "Must be a number";
    }

    setError({ errors: errors });
    return formIsValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (handleValidation()) {
      if (props.isEdit) {
        const updatedInputs = JSON.parse(
          JSON.stringify(input, (key, value) => {
            if (Array.isArray(value) && value.length === 0) {
              return undefined;
            } else if (value === null || value === "") {
              return undefined;
            } else if (typeof value === "boolean" || value === false || value) {
              return value;
            } else {
              return undefined;
            }
          })
        );
        updateContact({
          variables: {
            id,
            input: {
              ...updatedInputs,
              status: "Pending",
              updatedAt: new Date().toISOString(),
            },
          },
          refetchQueries: [{ query: GET_CONTACTS }],
        });
        createUpdate({
          variables: {
            sourceId: id,
            newUpdate: {
              userId: props.user?.attributes.sub,
            },
          },
        });
        toast.success("Contact has successfully been updated");
        setSuccessfullSubmit(true);
      } else {
        createNewContact({
          variables: {
            newContact: {
              ...input,
              status: "Pending",
              createdBy: props.user?.attributes["custom:fullName"],
            },
          },
          refetchQueries: [{ query: GET_CONTACTS }],
        });
        toast.success(
          "Contact has successfully been added. You can now close the window"
        );
        setSuccessfullSubmit(true);
      }
    } else {
      toast.error(
        "OOPS! that didn't work. Please verify the information and try again"
      );
      setStage(1);
    }
  };

  return (
    <form className="add-item-form" onSubmit={handleSubmit}>
      {stage === 1 && (
        <>
          <AdvancedInput
            {...props}
            name="title"
            data={ContactTitles}
            fieldName="name"
            type="dropdown"
            width="50%"
            placeholder={editData?.title}
            value={state.title}
            onChange={(e) => dispatch({ field: "title", payload: e.name })}
          />
          <AdvancedInput
            {...props}
            name="First Name"
            type="text"
            width="95%"
            isRequired={props.isEdit ? false : true}
            value={firstName}
            onChange={(e) => {
              setFirstName(e.target.value);
            }}
          />
          <AdvancedInput
            {...props}
            name="Last Name"
            type="text"
            width="95%"
            isRequired={props.isEdit ? false : true}
            value={lastName}
            onChange={(e) => {
              setLastName(e.target.value);
            }}
          />
          <span className="error-text">{error?.errors.name}</span>
          <AdvancedInput
            {...props}
            name="company"
            data={companies}
            fieldName="name"
            type="dropdown"
            width="95%"
            value={state.companyName}
            onChange={(e) => {
              prepareCompanyForAutofill(e.id);
              dispatch({ field: "companyName", payload: e.id });
            }}
          />
          <CompanyNotFound />,
          <AdvancedInput
            {...props}
            name="division"
            type="text"
            width="95%"
            placeholder={editData?.division}
            value={state.division}
            onChange={(e) =>
              dispatch({ field: "division", payload: e.target.value })
            }
          />
          <AdvancedInput
            {...props}
            name="job title"
            type="text"
            width="95%"
            placeholder={editData?.jobTitle}
            value={state.jobTitle}
            onChange={(e) =>
              dispatch({ field: "jobTitle", payload: e.target.value })
            }
          />
          <AdvancedInput
            {...props}
            name="primary contact"
            type="select"
            value={state.isPrimary}
            checked={state.isPrimary}
            onChange={() => dispatch({ field: "isPrimary" })}
          />
        </>
      )}
      {stage === 2 && (
        <>
          <AdvancedInput
            {...props}
            name="street"
            type="text"
            width="95%"
            placeholder={editData?.street}
            value={
              useCompanyAddress
                ? (state.street = pairedCompany?.street)
                : state.street
            }
            onChange={(e) =>
              dispatch({ field: "street", payload: e.target.value })
            }
          />
          <AdvancedInput
            {...props}
            name="street 2"
            type="text"
            width="95%"
            placeholder={editData?.street2}
            value={state.street2}
            onChange={(e) =>
              dispatch({ field: "street2", payload: e.target.value })
            }
          />
          <AdvancedInput
            {...props}
            name="city"
            type="text"
            width="95%"
            placeholder={editData?.city}
            value={
              useCompanyAddress
                ? (state.city = pairedCompany?.city)
                : state.city
            }
            onChange={(e) =>
              dispatch({ field: "city", payload: e.target.value })
            }
          />
          <AdvancedInput
            {...props}
            name="county"
            type="text"
            width="95%"
            placeholder={editData?.county}
            value={
              useCompanyAddress
                ? (state.county = pairedCompany?.county)
                : state.county
            }
            onChange={(e) =>
              dispatch({ field: "county", payload: e.target.value })
            }
          />
          <AdvancedInput
            {...props}
            name="Province/State"
            type="text"
            width="95%"
            placeholder={editData?.province}
            value={
              useCompanyAddress
                ? (state.province = pairedCompany?.province)
                : state.province
            }
            onChange={(e) =>
              dispatch({ field: "province", payload: e.target.value })
            }
          />
          <AdvancedInput
            {...props}
            name="postal code / zip"
            type="text"
            width="95%"
            placeholder={editData?.postal}
            value={
              useCompanyAddress
                ? (state.postal = pairedCompany?.postal)
                : state.postal
            }
            onChange={(e) =>
              dispatch({ field: "postal", payload: e.target.value })
            }
          />
          <AdvancedInput
            {...props}
            name="Country"
            type="countries"
            fieldName="name"
            width="95%"
            placeholder={editData?.country}
            value={
              useCompanyAddress
                ? (state.country = pairedCompany?.country)
                : state.country
            }
            onChange={(e) => dispatch({ field: "country", payload: e.name })}
          />
          <AdvancedInput
            {...props}
            name="use company address"
            type="select"
            onChange={handleUseCompanyAddress}
          />
        </>
      )}
      {stage === 3 && (
        <>
          <AdvancedInput
            {...props}
            name="Primary Phone"
            type="phone"
            width="95%"
            placeholder={editData?.phone}
            extPlaceholder={props.ext}
            value={
              useCompanyPhone
                ? (state.phone = pairedCompany.phone)
                : state.phone
            }
            onChange={(e) => {
              dispatch({ field: "phone", payload: e.target.value });
            }}
          />
          <span className="error-text">{error?.errors.phone}</span>
          <AdvancedInput
            {...props}
            conditional={moreFields}
            name="Mobile"
            type="phone"
            width="95%"
            placeholder={editData?.mobile}
            value={state.mobile}
            onChange={(e) => {
              dispatch({ field: "mobile", payload: e.target.value });
            }}
          />
          <AdvancedInput
            {...props}
            conditional={moreFields}
            name="office"
            type="phone"
            hasExt
            width="95%"
            placeholder={editData?.work}
            value={
              useCompanyPhone ? (state.work = pairedCompany?.phone) : state.work
            }
            onChange={(e) => {
              dispatch({ field: "work", payload: e.target.value });
            }}
            extValue={state.ext}
            extOnChange={(e) => {
              dispatch({ field: "ext", payload: e.target.value });
            }}
          />
          <AdvancedInput
            {...props}
            conditional={moreFields}
            name="fax"
            type="phone"
            width="95%"
            placeholder={editData?.fax}
            value={
              useCompanyPhone ? (state.fax = pairedCompany?.fax) : state.fax
            }
            onChange={(e) => {
              dispatch({ field: "fax", payload: e.target.value });
            }}
          />
          <AdvancedInput
            {...props}
            name="use company numbers"
            type="select"
            onChange={handleUseCompanyPhone}
          />
          <Button onClick={ShowPhoneFields}>Add phone</Button>
          <AdvancedInput
            {...props}
            name="primary email"
            type="email"
            width="95%"
            placeholder={editData?.primaryEmail}
            value={state.primaryEmail}
            onChange={(e) =>
              dispatch({ field: "primaryEmail", payload: e.target.value })
            }
          />
          <span className="error-text">{error?.errors.primaryEmail}</span>
          <AdvancedInput
            {...props}
            name="secondary email"
            type="email"
            width="95%"
            placeholder={editData?.secondaryEmail}
            value={state.secondaryEmail}
            onChange={(e) =>
              dispatch({ field: "secondaryEmail", payload: e.target.value })
            }
          />
        </>
      )}
      {stage === 4 && (
        <>
          <AdvancedInput
            {...props}
            name="industry"
            data={industries}
            isMulti={true}
            fieldName="name"
            type="dropdown"
            width="95%"
            value={
              useCompanyTags
                ? (state.industries = pairedCompany?.industries)
                : state.industries
            }
            onChange={(selectedOptions) =>
              dispatch({
                field: "industries",
                payload: selectedOptions.map((i) => i.id),
              })
            }
          />
          <CategorySelect
            data={categories}
            value={
              useCompanyTags
                ? (state.categories = pairedCompany?.categories)
                : state.categories
            }
            handleOnChange={(selectedCategories) =>
              dispatch({
                field: "categories",
                payload: selectedCategories,
              })
            }
          />
          <AdvancedInput
            {...props}
            name="specialist"
            data={users}
            isMulti={true}
            fieldName="name"
            type="dropdown"
            width="95%"
            value={
              useCompanyTags
                ? (state.specialist = pairedCompany?.specialist)
                : state.specialist
            }
            onChange={(selectedOptions) =>
              dispatch({
                field: "specialist",
                payload: selectedOptions.map((i) => i.id),
              })
            }
          />
          <AdvancedInput
            {...props}
            name="contact type"
            data={contactTypes}
            isMulti={true}
            fieldName="name"
            type="dropdown"
            width="95%"
            value={
              useCompanyTags
                ? (state.contactTypes = pairedCompany?.contactTypes)
                : state.contactTypes
            }
            onChange={(selectedOptions) =>
              dispatch({
                field: "contactTypes",
                payload: selectedOptions.map((i) => i.id),
              })
            }
          />
          <AdvancedInput
            {...props}
            name="use company tags"
            type="select"
            onChange={handleUseCompanyTags}
          />
          {useCompanyTags && (
            <span className="tag-use-text">
              Tags will be added at time of submission
            </span>
          )}
        </>
      )}
      <div className="add-item-button-container">
        {stage === 1 ? (
          <div style={{ width: "150px" }} />
        ) : (
          <Button width="150px" onClick={handlePrevStage}>
            Go Back
          </Button>
        )}
        {stage === 3 && props.isEdit && successfullSubmit ? (
          <Button width="150px" disabled={successfullSubmit}>
            Success
          </Button>
        ) : stage === 4 && props.isEdit ? (
          <SubmitButton width="150px" disabled={successfullSubmit}>
            Submit
          </SubmitButton>
        ) : stage !== 4 ? (
          <Button width="150px" onClick={handleNextStage}>
            Next
          </Button>
        ) : successfullSubmit ? (
          <Button width="150px" disabled={successfullSubmit}>
            Success
          </Button>
        ) : (
          <SubmitButton width="150px" disabled={successfullSubmit}>
            Submit
          </SubmitButton>
        )}
      </div>
      {stage === 4 && props.isEdit ? (
        <DeleteItem
          id={id}
          user={props.user}
          mutation={DELETE_CONTACT}
          refetch={GET_CONTACTS}
          type="Contact"
        />
      ) : null}
    </form>
  );
};

export default AddContact;
