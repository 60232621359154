/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useContext,
} from "react";
import { useMutation } from "@apollo/client";
import { AgGridReact } from "ag-grid-react";
import { toast } from "react-toastify";
import * as AiIcons from "react-icons/ai";
import * as FaIcons from "react-icons/fa";
import { AddInteraction, Interaction } from "..";
import { MultipleIdToNameFormatter } from "../../utilities";
import { CREATE_INTERACTION, GET_INTERACTIONS } from "../../api";
import { DataContext } from "../../context/DataContext";

const Interactions = (props: any) => {
  const sourceId = props.id;
  const gridRef = useRef(null);
  const { programs, projects, services, interactionCategories } =
    useContext(DataContext);
  const [createItem] = useMutation(CREATE_INTERACTION);
  const [modal, setModal] = useState(false);
  const [viewDetails, setViewDetails] = useState(false);
  const [interaction, setInteraction] = useState(null);
  const [toolbar, setToolbar] = useState(false);
  const [items, setItems] = useState([]);
  const [programOrProjects, setProgramOrProjects] = useState([]);
  const [gridApi, setGridApi] = useState(null);
  const [columnDefs] = useState([
    {
      field: "id",
      headerName: "View",
      filter: true,
      sortable: true,
      minWidth: 100,
      width: 100,
      cellRendererSelector: () => ({
        component: "eyeRenderer",
        params: {
          id: "id",
        },
      }),
    },
    {
      field: "actionType",
      headerName: "Type",
      filter: true,
      sortable: true,
      minWidth: 150,
      width: 285,
    },
    {
      field: "formattedProgramOrProject",
      headerName: "Program / Project",
      filter: true,
      sortable: true,
      minWidth: 150,
      width: 285,
    },
    {
      field: "formattedCategories",
      headerName: "Category",
      filter: true,
      sortable: true,
      minWidth: 150,
      width: 285,
    },
    {
      field: "formattedDate",
      headerName: "Date",
      filter: true,
      sortable: true,
      minWidth: 150,
      width: 285,
    },
  ]);

  // Combine program and project data into one array
  useEffect(() => {
    if (programs && projects && services) {
      setProgramOrProjects([...programs, ...projects, ...services]);
    }
  }, [programs, projects]);

  // Get the program or project name from the id
  const getProgramOrProjectName = useCallback(
    (id) => {
      const programOrProject = programOrProjects.find(
        (programOrProject) => programOrProject.id === id
      );
      return programOrProject?.name;
    },
    [programOrProjects]
  );

  useEffect(() => {
    if (props.data)
      setItems(
        props.data?.map((item) => ({
          ...item,
          formattedProgramOrProject: getProgramOrProjectName(
            item.programOrProject
          ),
          formattedCategories: MultipleIdToNameFormatter({
            data: interactionCategories,
            fieldToMatch: item.category,
          }),
          formattedDate: item.createdAt?.slice(0, 10),
        }))
      );
  }, [props.data]);

  useEffect(() => {
    if (gridApi) {
      gridApi.sizeColumnsToFit();
    }
  });

  const onGridReady = (params: any) => {
    setGridApi(params.api);
  };

  const handleView = (props: any) => {
    const showDetails = () => {
      setInteraction(props.data);
      setViewDetails(!viewDetails);
    };

    return (
      <div className="action-btns-container">
        <button onClick={showDetails}>
          <FaIcons.FaEye />
        </button>
      </div>
    );
  };

  // User Options
  const handleAdd = (
    actionType,
    createdAt,
    description,
    category,
    programOrProject
  ) => {
    const notify = () =>
      toast.success(
        `Thank you ${props.user?.attributes["custom:fullName"]}, your interaction has been recorded.`
      );
    createItem({
      variables: {
        sourceId: sourceId,
        newInteraction: {
          username: props.user?.attributes["custom:fullName"],
          actionType: actionType,
          body: description,
          category: category,
          contact: sourceId,
          programOrProject: programOrProject,
          createdAt: createdAt.toISOString(),
        },
      },
      refetchQueries: [{ query: GET_INTERACTIONS }],
    }).then((item) => {
      console.log(item.data);
      notify();
      setItems([item.data.createInteraction, ...items]);
    });
  };

  const handleExport = useCallback(() => {
    gridRef.current.api.exportDataAsExcel({
      allColumns: true,
    });
  }, []);

  return (
    <>
      {modal && (
        <AddInteraction
          contacts={props.contacts}
          handleSubmit={handleAdd}
          submitLabel="Submit"
        />
      )}
      {viewDetails && (
        <Interaction
          {...props}
          data={interaction}
          user={props.user}
          setViewDetails={setViewDetails}
          viewDetails={viewDetails}
        />
      )}
      <div className="items-table-container">
        <div className="items-table-header">
          <div className="items-table-button-container">
            <p title="search">
              <AiIcons.AiOutlineSearch onClick={() => setToolbar(!toolbar)} />
            </p>
            <p title="export">
              <AiIcons.AiOutlineDownload onClick={handleExport} />
            </p>
            <p title="Add Interaction">
              <AiIcons.AiOutlineAppstoreAdd onClick={() => setModal(!modal)} />
            </p>
          </div>
        </div>
        <div className="ag-theme-alpine" id="items-table">
          <AgGridReact
            ref={gridRef}
            rowData={items}
            columnDefs={columnDefs}
            onGridReady={onGridReady}
            suppressRowClickSelection={true}
            sideBar={toolbar ? "filters" : toolbar}
            frameworkComponents={{
              eyeRenderer: handleView,
            }}
          />
        </div>
      </div>
    </>
  );
};

export default Interactions;
