import React, { useState, useEffect } from "react";
import { useQuery, useMutation } from "@apollo/client";
import { toast } from "react-toastify";
import { Table, AdvancedInput, InfoCard } from "../../components";
import {
  GET_CERTIFICATIONS,
  CREATE_CERTIFICATION,
  UPDATE_CERTIFICATION,
  DELETE_CERTIFICATION,
} from "../../api";
import { DateFormatter } from "../../utilities";
import { SubmitButton, PageBody } from "../../theme";

const AdminCertifications = (props: any) => {
  const { data } = useQuery(GET_CERTIFICATIONS);
  const [name, setName] = useState("");
  const [modal, setModal] = useState(false);
  const [certifications, setCertifications] = useState([]);
  const [addCertifciation] = useMutation(CREATE_CERTIFICATION);
  const [columnDefs] = useState([
    {
      field: "name",
      headerName: "Name",
      filter: true,
      sortable: true,
      minWidth: 500,
      width: 500,
    },
    {
      field: "formattedDate",
      headerName: "Created At",
      minWidth: 150,
      width: 150,
    },
    {
      field: "id",
      headerName: "Actions",
      width: 100,
      cellRendererSelector: () => ({
        component: "actionsRenderer",
        params: {
          id: "id",
          refetchQuery: GET_CERTIFICATIONS,
          updateMutation: UPDATE_CERTIFICATION,
          deleteMutation: DELETE_CERTIFICATION,
        },
      }),
    },
  ]);

  const showModal = () => setModal(!modal);

  useEffect(() => {
    if (data) {
      setCertifications(
        data?.getAllCertification.certifications.map((item) => ({
          ...item,
          formattedDate: DateFormatter({ createdAt: item.createdAt }),
        }))
      );
    }
  }, [data]);

  const handleAdd = (e) => {
    e.preventDefault();
    const notify = () => toast.success("Certification successfully added");
    addCertifciation({
      variables: {
        newCertification: {
          name: name,
        },
      },
      refetchQueries: [{ query: GET_CERTIFICATIONS }],
    });
    notify();
    setName("");
    setModal(false);
  };

  return (
    <PageBody>
      {modal && (
        <div
          className={modal ? "admin-add-modal" : "admin-add-modal is-notActive"}
        >
          <InfoCard
            title="Create new Certification"
            width="350px"
            headerOption="modal"
            onBtnClick={showModal}
            backgroundColor="white"
            fields={[
              <form className="admin-edit-add-new-item" onSubmit={handleAdd}>
                <AdvancedInput
                  {...props}
                  name="Certification Name"
                  fieldName="name"
                  type="text"
                  width="95%"
                  isRequired={false}
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
                <SubmitButton width="150px">Submit</SubmitButton>
              </form>,
            ]}
          />
        </div>
      )}
      <Table
        title="Certifications / Licenses"
        isAdmin
        showAdd
        handleAdd={showModal}
        count={certifications.length}
        rowDataProp={certifications}
        colDefProp={columnDefs}
      />
    </PageBody>
  );
};

export default AdminCertifications;
