import React, { useState, useEffect, useRef } from "react";
import * as BsIcons from "react-icons/bs";
import * as AiIcons from "react-icons/ai";
import { AddNote } from "..";

const Note = ({
  note,
  replies,
  currentUser,
  addNote,
  activeNote,
  setActiveNote,
  updateNote,
  deleteNote,
  parent = null,
}) => {
  const optionsRef = useRef(null);
  const replyCount = replies.length;
  const [options, setOptions] = useState(false);
  const [hideReplies, setHideReplies] = useState(false);
  const [replyMsg, setReplyMsg] = useState(`Show ${replyCount} replies`);
  const [hasReplies, setHasReplies] = useState(false);
  const followUpId = parent ? parent : note.id;

  const canReply = Boolean(currentUser);
  const canManage = currentUser === note.username;
  const canDelete = currentUser === note.username && replies.length === 0;

  const isEditing =
    activeNote &&
    activeNote.id === note.id &&
    activeNote.type === "update-note";

  const isFollowingUp =
    activeNote &&
    activeNote.id === note.id &&
    activeNote.type === "following-up";

  useEffect(() => {
    if (replies.length > 0) setHasReplies(true);
  }, [replies.length]);

  const showOptions = () => setOptions(!options);

  const showReplies = () => {
    setHideReplies(!hideReplies);
    setReplyMsg(hideReplies ? `Show ${replyCount} replies` : "hide replies");
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const handleClickOutside = (e) => {
    if (optionsRef.current && !optionsRef.current.contains(e.target)) {
      setOptions(false);
    }
  };

  return (
    <>
      {isFollowingUp && (
        <AddNote
          submitLabel="Reply"
          handleSubmit={(details) => addNote(details, followUpId)}
        />
      )}
      {isEditing && (
        <AddNote
          submitLabel="Update"
          initialBody={note.body}
          handleSubmit={(details) => updateNote(details, note.id)}
        />
      )}
      <div className="note-item-container">
        <div className="note-item-header">
          <div className="note-item-header-left">
            <div className="note-item-author-image">
              <AiIcons.AiOutlineUser />
            </div>
            <h3>{note.username}</h3>
          </div>
          <div className="note-item-header-right">
            <p>{note.createdAt.substr(0, 10)}</p>
            {canManage && (
              <div ref={optionsRef}>
                <BsIcons.BsThreeDotsVertical
                  className="note-options-btn"
                  onClick={showOptions}
                />
              </div>
            )}
            {options && (
              <div className="note-item-author-options">
                <p
                  onClick={() =>
                    setActiveNote({ id: note.id, type: "update-note" })
                  }
                >
                  <AiIcons.AiOutlineEdit />
                  <span>Edit</span>
                </p>
                {canDelete && (
                  <p onClick={() => deleteNote(note.id)}>
                    <BsIcons.BsTrash />
                    <span>Delete</span>
                  </p>
                )}
              </div>
            )}
          </div>
        </div>
        <div className="note-item-body">
          <p>{note.body}</p>
        </div>
        <div className="note-item-footer">
          <div />
          {canReply && (
            <h3
              onClick={() =>
                setActiveNote({ id: note.id, type: "following-up" })
              }
            >
              <BsIcons.BsReplyAllFill />
              <span>Reply</span>
            </h3>
          )}
        </div>
      </div>
      {hasReplies && (
        <div className="note-item-reply-container">
          <p className="note-item-reply-dropdown-icon" onClick={showReplies}>
            <BsIcons.BsArrowReturnRight />
            <span>{replyMsg}</span>
          </p>
          {replies.map((reply) => (
            <>
              <div className="note-reply" key={reply.id}>
                {hideReplies && (
                  <Note
                    key={reply.id}
                    note={reply}
                    replies={[]}
                    currentUser={currentUser}
                    activeNote={activeNote}
                    setActiveNote={setActiveNote}
                    addNote={addNote}
                    updateNote={updateNote}
                    deleteNote={deleteNote}
                    parent={note.id}
                  />
                )}
              </div>
            </>
          ))}
        </div>
      )}
    </>
  );
};

export default Note;
