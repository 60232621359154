export const INITIAL_CONTACT_STATE = () => {
  return {
    title: null,
    name: "",
    phone: "",
    mobile: "",
    ext: "",
    work: "",
    fax: "",
    primaryEmail: "",
    secondaryEmail: "",
    companyName: null,
    jobTitle: "",
    division: "",
    isPrimary: false,
    industries: [],
    contactTypes: [],
    specialist: [],
    categories: [],
    country: null,
    province: "",
    county: "",
    city: "",
    street: "",
    street2: "",
    postal: "",
    notes: [],
    interactions: [],
    status: "",
    createdBy: "",
  };
};

export const ContactReducer = (state, action) => {
  switch (action.field) {
    case "title":
    case "name":
    case "phone":
    case "mobile":
    case "ext":
    case "work":
    case "fax":
    case "primaryEmail":
    case "secondaryEmail":
    case "companyName":
    case "jobTitle":
    case "division":
    case "industries":
    case "contactTypes":
    case "specialist":
    case "categories":
    case "country":
    case "province":
    case "county":
    case "city":
    case "street":
    case "street2":
    case "postal":
    case "notes":
    case "interactions":
    case "status":
    case "createdBy":
      return {
        ...state,
        [action.field]: action.payload,
      };

    case "isPrimary":
      return {
        ...state,
        isPrimary: !state.isPrimary,
      };

    default:
      return;
  }
};
