/* eslint-disable array-callback-return */
import React, { useState, useContext } from "react";
import { AddItem, AdvancedInput } from "..";
import { SubmitButton } from "../../theme";
import { DataContext } from "../../context/DataContext";

const programOrProjectOptions = [
  { name: "Program" },
  { name: "Project" },
  { name: "Consultation" },
];

const AddInteraction = (props: any) => {
  const {
    interactionCategories,
    interactionTypes,
    programs,
    projects,
    services,
  } = useContext(DataContext);
  const [modal, setModal] = useState(true);
  const [isProjects, setIsProjects] = useState(false);
  const [isProgram, setIsProgram] = useState(false);
  const [isConsulting, setIsConsulting] = useState(false);
  const [actionType, setActionType] = useState(null);
  const [date, setDate] = useState("");
  const [description, setDescription] = useState("");
  const [category, setCategory] = useState("");
  const [programOrProject, setProgramOrProject] = useState("");
  const createdAt = new Date(date + "T00:00:00");

  const handlePPCSelection = (e) => {
    if (e === "Program") {
      setIsProgram(true);
      setIsProjects(false);
      setIsConsulting(false);
    } else if (e === "Project") {
      setIsProjects(true);
      setIsProgram(false);
      setIsConsulting(false);
    } else {
      setIsConsulting(true);
      setIsProgram(false);
      setIsProjects(false);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    props.handleSubmit(
      actionType,
      createdAt,
      description,
      category,
      programOrProject
    );
    setModal(false);
    props.setEdit(false);
  };

  return (
    <>
      {modal && (
        <AddItem
          type="interaction"
          setEdit={props.setEdit}
          component={[
            <form
              className="add-item-form"
              key="add-interaction"
              onSubmit={handleSubmit}
            >
              <AdvancedInput
                {...props}
                name="Interaction Type"
                data={interactionTypes}
                fieldName="name"
                type="dropdown"
                width="95%"
                value={actionType}
                onChange={(e) => setActionType(e.name)}
              />
              <AdvancedInput
                {...props}
                name="Program, Project or Consultation"
                type="dropdown"
                width="95%"
                fieldName="name"
                data={programOrProjectOptions}
                onChange={(e) => handlePPCSelection(e.name)}
              />
              {isProgram ? (
                <AdvancedInput
                  {...props}
                  name="Program"
                  type="dropdown"
                  width="95%"
                  fieldName="name"
                  data={programs}
                  value={programOrProject}
                  onChange={(e) => setProgramOrProject(e.id)}
                />
              ) : isProjects ? (
                <AdvancedInput
                  {...props}
                  name="Project"
                  type="dropdown"
                  width="95%"
                  fieldName="name"
                  data={projects}
                  value={programOrProject}
                  onChange={(e) => setProgramOrProject(e.id)}
                />
              ) : isConsulting ? (
                <AdvancedInput
                  {...props}
                  name="Service Contracts"
                  type="dropdown"
                  width="95%"
                  fieldName="name"
                  data={services}
                  value={programOrProject}
                  onChange={(e) => setProgramOrProject(e.id)}
                />
              ) : null}
              <AdvancedInput
                {...props}
                name="Interaction Topic"
                data={interactionCategories}
                fieldName="name"
                type="dropdown"
                width="95%"
                value={category}
                onChange={(e) => setCategory(e.id)}
              />
              <AdvancedInput
                {...props}
                name="date"
                type="date"
                width="95%"
                value={date}
                onChange={(e) => {
                  setDate(e.target.value);
                }}
              />
              <AdvancedInput
                {...props}
                name="description"
                type="textArea"
                width="95%"
                value={description}
                onChange={(e) => {
                  setDescription(e.target.value);
                }}
              />
              <div className="add-item-button-container">
                <div />
              </div>
              <div className="add-item-button-container">
                <div style={{ width: "150px" }} />
                <SubmitButton width="150px">{props.submitLabel}</SubmitButton>
              </div>
            </form>,
          ]}
        />
      )}
    </>
  );
};

export default AddInteraction;
