import styled from "styled-components";

export const PageGrid = styled.div`
  display: grid;
  grid-template-columns: auto auto auto;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 50px;
  @media (max-width: 1100px) {
    display: flex;
    flex-direction: column;
  }
  @media (max-width: 480px) {
    grid-template-columns: auto;
  }
`;
