import React, { useState, useEffect, useRef, useContext } from "react";
import { SearchField } from "..";
import { DataContext } from "../../context/DataContext";
import * as AiIcons from "react-icons/ai";

const SearchOptions = {
  contacts: "Contacts",
  companies: "Companies",
  programs: "Programs",
  projects: "Projects",
  resources: "Resources",
  services: "Services",
};

const Searchbar = (props: any) => {
  const optionsRef = useRef(null);
  const searchRef = useRef(null);
  const { contacts, companies, programs, projects, resources, services } =
    useContext(DataContext);
  const [searchbar, setSearchbar] = useState(true);
  const [optionsTitle, setOptionsTitle] = useState(SearchOptions.contacts);
  const [optionsDropdown, setOptionsDropDown] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (window.innerWidth <= 480) {
      setIsMobile(true);
    } else setIsMobile(false);
  }, []);

  const showOptions = () => setOptionsDropDown(!optionsDropdown);

  const handleOption = (title: any) => {
    showOptions();
    setOptionsTitle(title);
  };
  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClickOutside = (e) => {
    if (optionsRef.current && !optionsRef.current.contains(e.target)) {
      setOptionsDropDown(false);
      if (isMobile) {
        setSearchbar(false);
      }
    }
    if (searchRef.current && !searchRef.current.contains(e.target)) {
      setSearchbar(false);
    }
  };

  return (
    <>
      <div
        className={
          searchbar ? "searchbar-container active" : "searchbar-container"
        }
        data-testid="searchbar"
      >
        <div className="searchbar-input" ref={searchRef}>
          <div
            className="searchbar-options"
            onClick={showOptions}
            ref={optionsRef}
          >
            <p>
              {optionsTitle}
              <span>
                <AiIcons.AiFillCaretDown />
              </span>
            </p>
          </div>
          <div
            className={
              optionsDropdown ? "options-dropdown active" : "options-dropdown"
            }
          >
            {Object.values(SearchOptions).map((item, index) => {
              return (
                <p
                  key={index}
                  onClick={(e) => {
                    handleOption(item);
                  }}
                >
                  {item}
                </p>
              );
            })}
          </div>
          <SearchField
            data={
              optionsTitle === SearchOptions.contacts
                ? contacts
                : optionsTitle === SearchOptions.companies
                ? companies
                : optionsTitle === SearchOptions.programs
                ? programs
                : optionsTitle === SearchOptions.projects
                ? projects
                : optionsTitle === SearchOptions.resources
                ? resources
                : services
            }
            breadcrumb={
              optionsTitle === SearchOptions.contacts
                ? "contacts"
                : optionsTitle === SearchOptions.companies
                ? "companies"
                : optionsTitle === SearchOptions.programs
                ? "programs"
                : optionsTitle === SearchOptions.projects
                ? "projects"
                : optionsTitle === SearchOptions.resources
                ? "resources"
                : "services"
            }
            searchTerm="name"
          />
        </div>
        {isMobile && (
          <div
            className="close-search-btn-container"
            onClick={() => setSearchbar(!searchbar)}
          >
            {searchbar && (
              <AiIcons.AiOutlineClose className="close-search-btn" />
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default Searchbar;
