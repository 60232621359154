import { gql } from "@apollo/client";

export const CREATE_BUG = gql`
  mutation CreateBug($newBug: BugInput) {
    createBug(newBug: $newBug) {
      id
      type
      details
      createdBy
      createdAt
    }
  }
`;
