import { gql } from "@apollo/client";

export const CREATE_NOTIFICATION = gql`
  mutation CreateNotification(
    $userId: ID!
    $newNotification: UserNotificationInput
  ) {
    createNotification(userId: $userId, newNotification: $newNotification) {
      id
      userId
      itemId
      type
      read
      createdBy
      createdAt
    }
  }
`;

export const UPDATE_NOTIFICATION = gql`
  mutation UpdateNotification(
    $userId: ID!
    $id: ID!
    $input: UserNotificationInput
  ) {
    updateNotification(userId: $userId, id: $id, input: $input) {
      id
      userId
      itemId
      type
      read
      createdBy
      createdAt
    }
  }
`;

export const DELETE_NOTIFICATION = gql`
  mutation DeleteNotification($userId: ID!, $id: ID!) {
    deleteNotification(userId: $userId, id: $id) {
      id
      userId
      itemId
      type
      read
      createdBy
      createdAt
    }
  }
`;

export const NOTIFICATION_SUBSCRIPTION = gql`
  subscription OnNewNotification {
    onNewNotification {
      id
      userId
      itemId
      type
      read
      createdBy
      createdAt
    }
  }
`;
