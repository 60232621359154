import { gql } from "@apollo/client";

export const GET_INDUSTRIES = gql`
  query getAllIndustries($nextToken: String) {
    getAllIndustries(nextToken: $nextToken) {
      industries {
        id
        name
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const GET_INDUSTRY_BY_ID = gql`
  query getIndustryById($id: ID!) {
    getIndustryById(id: $id) {
      id
      name
      createdAt
      updatedAt
    }
  }
`;

export const CREATE_INDUSTRY = gql`
  mutation createIndustry($newIndustry: IndustryInput!) {
    createIndustry(newIndustry: $newIndustry) {
      id
      name
      createdAt
      updatedAt
    }
  }
`;

export const UPDATE_INDUSTRY = gql`
  mutation updateIndustry($id: ID!, $input: IndustryInput!) {
    updateIndustry(id: $id, input: $input) {
      id
      name
      createdAt
      updatedAt
    }
  }
`;

export const DELETE_INDUSTRY = gql`
  mutation deleteIndustry($id: ID!) {
    deleteIndustry(id: $id) {
      id
      name
      createdAt
      updatedAt
    }
  }
`;
