export const ResourceSchema = [
  {
    headerName: "View",
    field: "id",
    cellRendererSelector: (params) => {
      return {
        component: "eyeRenderer",
        params: { url: "/directory/resources" },
      };
    },
    textAlign: "center",
    width: 75,
    minWidth: 75,
    hide: false,
  },
  {
    headerName: "Resource Name",
    field: "name",
    renderer: "linkRenderer",
    url: "directory/resources/",
    filter: true,
    value: "name",
    width: 200,
    minWidth: 200,
    hide: false,
  },
  {
    headerName: "Resource Type",
    field: "type",
    filter: true,
    width: 200,
    minWidth: 200,
    hide: false,
  },
  {
    headerName: "Author",
    field: "formattedAuthor.name",
    filter: true,
    width: 200,
    minWidth: 200,
    hide: false,
  },
  {
    headerName: "Program",
    field: "formattedProgram.name",
    filter: true,
    width: 200,
    minWidth: 200,
    hide: false,
  },
  {
    headerName: "Project",
    field: "formattedProject.name",
    filter: true,
    width: 200,
    minWidth: 200,
    hide: false,
  },
  {
    headerName: "Link",
    field: "link",
    cellRendererSelector: () => {
      return {
        component: "linkRenderer",
        params: { url: "" },
      };
    },
    filter: true,
    width: 250,
    minWidth: 250,
    hide: false,
  },
  {
    field: "dateHeld",
    headerName: "Hosted Date",
    minWidth: 150,
    width: 150,
    filter: true,
    hide: false,
  },
  {
    headerName: "CEU",
    field: "ceu",
    filter: true,
    width: 200,
    minWidth: 200,
    hide: true,
  },
  {
    headerName: "Participants",
    field: "participants",
    filter: true,
    width: 200,
    minWidth: 200,
    hide: true,
  },
];
