import React, { useState } from "react";
import { useMutation } from "@apollo/client";
import { toast } from "react-toastify";
import { AdvancedInput } from "../..";
import { CREATE_FAQ, UPDATE_FAQ, GET_FAQS } from "../../../api";
import { SubmitButton } from "../../../theme";

const AddFaq = (props: any) => {
  const [question, setQuestion] = useState("");
  const [answer, setAnswer] = useState("");
  const [createFaq] = useMutation(CREATE_FAQ);
  const [updateFaq] = useMutation(UPDATE_FAQ);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (props?.isEdit) {
      const notify = () => toast.success("Item updated successfully!");
      updateFaq({
        variables: {
          id: props?.id,
          input: {
            question: question,
            answer: answer,
          },
        },
        refetchQueries: [{ query: GET_FAQS }],
      });
      notify();
    } else {
      const notify = () => toast.success("Item added successfully!");
      createFaq({
        variables: {
          newFaq: {
            question: question,
            answer: answer,
          },
        },
        refetchQueries: [{ query: GET_FAQS }],
      });
      notify();
    }
  };

  return (
    <form className="add-item-form" onSubmit={handleSubmit}>
      <AdvancedInput
        {...props}
        name="question"
        type="text"
        width="95%"
        value={question}
        onChange={(e) => setQuestion(e.target.value)}
      />
      <AdvancedInput
        {...props}
        name="answer"
        type="textArea"
        width="95%"
        value={answer}
        onChange={(e) => setAnswer(e.target.value)}
      />
      <div className="add-item-button-container">
        <div style={{ width: "150px" }} />
        <SubmitButton width="150px">Submit</SubmitButton>
      </div>
    </form>
  );
};

export default AddFaq;
