import { gql } from "@apollo/client";

export const GET_INTERACTIONCATEGORIES = gql`
  query getAllInteractionCategories($nextToken: String) {
    getAllInteractionCategories(nextToken: $nextToken) {
      interactionCategories {
        id
        name
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const GET_INTERACTIONCATEGORY_BY_ID = gql`
  query getInteractionCategoryById($id: ID!) {
    getInteractionCategoryById(id: $id) {
      id
      name
      createdAt
      updatedAt
    }
  }
`;

export const CREATE_INTERACTIONCATEGORY = gql`
  mutation createInteractionCategory(
    $newInteractionCategory: InteractionCategoryInput
  ) {
    createInteractionCategory(newInteractionCategory: $newInteractionCategory) {
      id
      name
      createdAt
      updatedAt
    }
  }
`;

export const UPDATE_INTERACTIONCATEGORY = gql`
  mutation updateInteractionCategory(
    $id: ID!
    $input: InteractionCategoryInput
  ) {
    updateInteractionCategory(id: $id, input: $input) {
      id
      name
      createdAt
      updatedAt
    }
  }
`;

export const DELETE_INTERACTIONCATEGORY = gql`
  mutation deleteInteractionCategory($id: ID!) {
    deleteInteractionCategory(id: $id) {
      id
      name
      createdAt
      updatedAt
    }
  }
`;
