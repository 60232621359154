import React, { useState, useEffect, useRef, useCallback } from "react";
import { useMutation } from "@apollo/client";
import { AgGridReact } from "ag-grid-react";
import { toast } from "react-toastify";
import { AddBudget, OneDriveRenderer } from "..";
import { CREATE_BUDGET, DELETE_BUDGET, UPDATE_BUDGET } from "../../api";
import * as AiIcons from "react-icons/ai";
import * as FaIcons from "react-icons/fa";

const Budgets = (props: any) => {
  const sourceId = props.id;
  const [itemId, setItemId] = useState(null);
  const [refetchQuery] = useState(props.refetchQuery);
  const [modal, setModal] = useState(false);
  const [toolbar, setToolbar] = useState(false);
  const [edit, setEdit] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);
  const [items, setItems] = useState([]);
  const [createItem] = useMutation(CREATE_BUDGET);
  const [updateItem] = useMutation(UPDATE_BUDGET);
  const [deleteItem] = useMutation(DELETE_BUDGET);
  const [gridApi, setGridApi] = useState(null);
  const gridRef = useRef(null);
  const [columnDefs] = useState([
    {
      field: "file",
      headerName: "View",
      textAlign: "center",
      filter: true,
      sortable: true,
      minWidth: 100,
      width: 100,
      cellRendererSelector: () => {
        return {
          component: "oneDriveRenderer",
        };
      },
    },
    {
      field: "budget",
      headerName: "Total Budget",
      filter: true,
      sortable: true,
      minWidth: 150,
      width: 285,
    },
    {
      field: "expenses",
      headerName: "Total Expenses",
      filter: true,
      sortable: true,
      minWidth: 150,
      width: 285,
    },
    {
      field: "adminFees",
      headerName: "Admin Fees",
      filter: true,
      sortable: true,
      minWidth: 150,
      width: 285,
    },
    {
      field: "id",
      headerName: "Actions",
      textAlign: "center",
      minWidth: 150,
      width: 150,
      cellRendererSelector: () => ({
        component: "actionsRenderer",
        params: {
          id: "id",
          refetchQuery: props.refetchQuery,
          updateMutation: UPDATE_BUDGET,
          deleteMutation: DELETE_BUDGET,
        },
      }),
    },
  ]);

  const showModal = () => setModal(!modal);
  const showToolbar = () => setToolbar(!toolbar);

  useEffect(() => {
    if (props.data) setItems(props.data);
  }, [props.data]);

  useEffect(() => {
    if (gridApi) {
      gridApi.sizeColumnsToFit();
    }
  });

  useEffect(() => {
    setCurrentUser(
      props?.user?.attributes["custom:fullName"]
        .split(" ")
        .slice(0, -1)
        .join(" ")
    );
  }, [currentUser, props.user]);

  const onGridReady = (params: any) => {
    setGridApi(params.api);
  };

  const manageItems = (props: any) => {
    const handleEdit = () => {
      setItemId(props.value);
      setEdit(!edit);
    };

    const onDelete = () => {
      handleDelete(props.value);
    };

    return (
      <>
        <div className="action-btns-container">
          <p>
            <FaIcons.FaEdit onClick={handleEdit} />
          </p>
          <p>
            <FaIcons.FaTrash onClick={onDelete} />
          </p>
        </div>
      </>
    );
  };

  // User Options
  const handleAdd = (expenses, adminFees, budget, file) => {
    const notify = () =>
      toast.success(
        `Thank you ${currentUser}, your ${props.field} has been recorded.`
      );
    createItem({
      variables: {
        sourceId: sourceId,
        newBudget: {
          expenses,
          adminFees,
          budget,
          file,
        },
      },
      refetchQueries: [{ query: refetchQuery }],
    }).then((item) => {
      console.log(item.data.createBudget);
      setItems([item.data.createBudget, ...items]);
    });
    notify();
  };

  const handleUpdate = (expenses, adminFees, budget, file) => {
    const notify = () =>
      toast.success(`${props.field} has been successfully updated.`);
    updateItem({
      variables: {
        sourceId: sourceId,
        id: itemId,
        input: {
          expenses,
          adminFees,
          budget,
          file,
        },
      },
      refetchQueries: [{ query: props.refetchQuery }],
    }).then(() => {
      const updatedItems = items.map((item) => {
        if (item.id === itemId) {
          return {
            ...item,
            expenses,
            adminFees,
            budget,
            file,
          };
        }
        return item;
      });
      setItems(updatedItems);
      notify();
    });
  };

  const handleDelete = (itemId) => {
    if (
      window.confirm(`Are you sure you want to remove this ${props.field}?`)
    ) {
      deleteItem({
        variables: {
          sourceId: sourceId,
          id: itemId,
        },
        refetchQueries: [{ query: refetchQuery }],
      }).then(() => {
        setItems((items) => items.filter((item) => item.id !== itemId));
      });
    }
  };

  const handleExport = useCallback(() => {
    gridRef.current.api.exportDataAsExcel({
      allColumns: true,
    });
  }, []);

  return (
    <>
      {modal && (
        <AddBudget {...props} handleSubmit={handleAdd} submitLabel="Submit" />
      )}
      {edit && (
        <AddBudget
          {...props}
          handleSubmit={(expenses, adminFees, budget, file) =>
            handleUpdate(expenses, adminFees, budget, file)
          }
          submitLabel="Update"
        />
      )}
      <div className="items-table-container">
        <div className="items-table-header">
          <div className="items-table-button-container">
            <p title="search">
              <AiIcons.AiOutlineSearch onClick={showToolbar} />
            </p>
            <p title="export">
              <AiIcons.AiOutlineDownload onClick={handleExport} />
            </p>
            <p title={`Add ${props.type}`}>
              <AiIcons.AiOutlineAppstoreAdd onClick={showModal} />
            </p>
          </div>
        </div>
        <div className="ag-theme-alpine" id="items-table">
          <AgGridReact
            ref={gridRef}
            rowData={items}
            columnDefs={columnDefs}
            onGridReady={onGridReady}
            suppressRowClickSelection={true}
            sideBar={toolbar ? "filters" : toolbar}
            frameworkComponents={{
              oneDriveRenderer: OneDriveRenderer,
              actionsRenderer: manageItems,
            }}
          />
        </div>
      </div>
    </>
  );
};

export default Budgets;
