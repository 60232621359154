import styled from "styled-components";

export const TableExternalLink = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 75px;
  height: 42px;
  overflow: hidden;
`;
