import { gql } from "@apollo/client";

export const GET_UPDATES = gql`
  query getAllUpdates($nextToken: String) {
    getAllUpdates(nextToken: $nextToken) {
      updates {
        id
        sourceId
        userId
        createdAt
      }
      nextToken
    }
  }
`;

export const CREATE_UPDATE = gql`
  mutation CreateUpdate($sourceId: ID!, $newUpdate: UpdateInput) {
    createUpdate(sourceId: $sourceId, newUpdate: $newUpdate) {
      id
      sourceId
      userId
      createdAt
    }
  }
`;

export const UPDATE_UPDATE = gql`
  mutation UpdateUpdate($sourceId: ID!, $id: ID!, $input: UpdateInput) {
    updateUpdate(sourceId: $sourceId, id: $id, input: $input) {
      id
      sourceId
      userId
      createdAt
    }
  }
`;

export const DELETE_UPDATE = gql`
  mutation DeleteUpdate($sourceId: ID!, $id: ID!) {
    deleteUpdate(sourceId: $sourceId, id: $id) {
      id
      sourceId
      userId
      createdAt
    }
  }
`;
