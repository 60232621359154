import React from "react";
import { Button, CardBody } from "../../theme";
import * as FiIcons from "react-icons/fi";
import * as AiIcons from "react-icons/ai";

const InfoCard = (props: any) => {
  return (
    <CardBody style={{ ...props }}>
      <div className="card">
        <div className="info-header">
          <div className="info-header-left">
            <p style={{ fontSize: props.fontSize }}>{props.title}</p>
          </div>
          <div className="info-header-right">
            {props.headerOption === "view all" && (
              <Button onclick={props.onBtnClick}>View all</Button>
            )}
            {props.headerOption === "add" && (
              <Button onclick={props.onBtnClick}>Add {props.fieldName}</Button>
            )}
            {props.headerOption === "edit" && (
              <div className="card-header-icon" onClick={props.onBtnClick}>
                <FiIcons.FiEdit />
              </div>
            )}
            {props.headerOption === "contact" && (
              <div className="card-header-icon" onClick={props.onBtnClick}>
                <FiIcons.FiEye />
              </div>
            )}
            {props.headerOption === "modal" && (
              <div className="card-header-icon" onClick={props.onBtnClick}>
                <AiIcons.AiOutlineClose />
              </div>
            )}
            {props.headerOption === "drag" && (
              <div className="card-header-icon">{props.draggable}</div>
            )}
          </div>
        </div>
        <div className="info-body">{props.fields}</div>
      </div>
    </CardBody>
  );
};

export default InfoCard;
