import { gql } from "@apollo/client";

export const GET_CLAIMS = gql`
  query getAllClaims {
    getAllClaims {
      claims {
        username
        claimNumber
        sourceId
        paid
        id
        date
        createdAt
      }
    }
  }
`;

export const CREATE_CLAIM = gql`
  mutation CreateClaim($sourceId: ID!, $newClaim: ClaimInput) {
    createClaim(sourceId: $sourceId, newClaim: $newClaim) {
      id
      sourceId
      date
      claimNumber
      paid
    }
  }
`;

export const UPDATE_CLAIM = gql`
  mutation UpdateClaim($sourceId: ID!, $id: ID!, $input: ClaimInput) {
    updateClaim(sourceId: $sourceId, id: $id, input: $input) {
      id
      sourceId
      date
      claimNumber
      paid
    }
  }
`;

export const DELETE_CLAIM = gql`
  mutation DeleteClaim($sourceId: ID!, $id: ID!) {
    deleteClaim(sourceId: $sourceId, id: $id) {
      id
      sourceId
      date
      claimNumber
      paid
    }
  }
`;
