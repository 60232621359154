/* eslint-disable array-callback-return */
import React from "react";
import { useQuery, useMutation } from "@apollo/client";
import { useParams } from "react-router-dom";
import { PageBody, PageGrid } from "../../theme";
import { ServiceReducer } from "../../utilities";
import { AddService } from "../../components";
import {
  GET_SERVICE_BY_ID,
  UPDATE_SERVICE,
  CREATE_INVOICE,
  UPDATE_INVOICE,
  DELETE_INVOICE,
} from "../../api";
import {
  Editor,
  ContactToPRS,
  InfoCard,
  ProjectsAndServicesOverview,
  Notes,
  ClaimsAndInvoices,
  Tags,
  Deliverables,
} from "../../components";
import { DataContext } from "../../context/DataContext";

const Service = (props: any) => {
  const { id } = useParams();
  const [service, setService] = React.useState<any>(null);
  const [created, setCreated] = React.useState<string>("");
  const [editor, setEditor] = React.useState<boolean>(false);
  const [hasNDA, setHasNDA] = React.useState<boolean>(false);
  const [managerStatus, setManagerStatus] = React.useState<boolean>(false);
  const [canView, setCanView] = React.useState<boolean>(null);
  const [isLead, setIsLead] = React.useState<boolean>(false);
  const [updateStatus] = useMutation(UPDATE_SERVICE);
  const { users } = React.useContext<any>(DataContext);
  const { loading, data: serviceData } = useQuery(GET_SERVICE_BY_ID, {
    variables: { id },
  });

  React.useEffect(() => {
    if (serviceData && !loading) {
      setService(serviceData.getServiceById);
    }
  }, [serviceData, loading]);

  React.useEffect(() => {
    if (service && service.createdAt) {
      setCreated(service.createdAt.substr(0, 10));
    }
  }, [service]);

  React.useEffect(() => {
    const checkUser = () => {
      /* 
      If the user is an Uber User, Finance, 
      they can view it with no restrictions
      */
      if (
        props?.user.attributes["custom:userGroup"] === "Uber User" ||
        props?.user.attributes["custom:userGroup"] === "Finance"
      ) {
        setManagerStatus(true);
        setCanView(true);
      } else if (users && service && service.lead) {
        /*
        If the user is a manager, and the project is attached to their team,
        they can view it
        */
        let attachedUsers = [];
        const leadUser = service.lead.map((leadId: string) =>
          users.find((user: any) => user.id === leadId)
        );
        const memberUsers = service.members.map((memberId: string) =>
          users.find((user: any) => user.id === memberId)
        );
        attachedUsers.push(...leadUser, ...memberUsers);
        if (
          props?.user.attributes["custom:userGroup"] === "Manager" &&
          attachedUsers.find((user: any) =>
            user.team.includes(props?.user.attributes["custom:team"])
          )
        ) {
          setManagerStatus(true);
          setCanView(true);
        }
      } else if (props.user.attributes.sub.includes(service.lead)) {
        /*
        if the user is the project lead they have lead access
        */
        setIsLead(true);
        setCanView(true);
      } else if (
        service.members.includes(props.user.attributes.sub) ||
        service.lead === props.user.attributes.sub
      ) {
        /*
      if the user is a member of the project and have signed NDA, 
      they can view it
      */
        setHasNDA(true);
        setCanView(true);
      }
    };

    if (props.user && service) {
      checkUser();
    } else {
      setCanView(false);
    }
  }, [service, props, users]);

  const handleEdit = (e: any) => {
    e.preventDefault();
    setEditor(!editor);
  };

  const handleStatusUpdate = (status: any) => {
    updateStatus({
      variables: {
        id,
        input: {
          status: status,
        },
      },
      refetchQueries: [{ query: GET_SERVICE_BY_ID }],
    });
  };

  return (
    <>
      {!loading && canView ? (
        <>
          {editor && (
            <Editor
              type="Service"
              component={
                <AddService
                  {...props}
                  isEdit
                  id={id}
                  data={service}
                  isLead={isLead}
                  isManager={managerStatus}
                />
              }
            />
          )}
          <PageBody>
            <div className="last-modified">
              <p>
                Last Modified:
                <span>{created}</span>
              </p>
            </div>
            <PageGrid>
              <div className="item-left-column">
                <InfoCard
                  title="Overview"
                  height="650px"
                  width="100%"
                  headerOption={managerStatus || isLead ? "edit" : null}
                  onBtnClick={handleEdit}
                  fields={[
                    <ProjectsAndServicesOverview
                      data={service}
                      type="service"
                      handleStatusUpdate={handleStatusUpdate}
                      managerPermission={managerStatus}
                      hasNDA={hasNDA || managerStatus}
                    />,
                  ]}
                />
                <InfoCard
                  title="Tags"
                  height="300px"
                  width="100%"
                  fields={[<Tags type="service" data={service} />]}
                />
              </div>
              <div className="item-middle-column">
                <InfoCard
                  title="Main Client Contacts"
                  height="300px"
                  width="100%"
                  fields={[<ContactToPRS data={service?.contacts} />]}
                />
                <InfoCard
                  title="Deliverables"
                  height="300px"
                  width="100%"
                  fields={[
                    <Deliverables
                      key="service-deliverables"
                      id={id}
                      type="service"
                      field="deliverables"
                      isManager={managerStatus || isLead}
                      data={service?.deliverables}
                    />,
                  ]}
                />
                {hasNDA || managerStatus || isLead ? (
                  <InfoCard
                    title="Invoices"
                    height="300px"
                    width="100%"
                    fields={[
                      <ClaimsAndInvoices
                        id={id}
                        type="service"
                        field="invoice"
                        action="newInvoice"
                        data={service?.invoices}
                        createMutation={CREATE_INVOICE}
                        updateMutation={UPDATE_INVOICE}
                        deleteMutation={DELETE_INVOICE}
                        refetchQuery={GET_SERVICE_BY_ID}
                      />,
                    ]}
                  />
                ) : (
                  <div
                    className="grid-block"
                    style={{ height: "300px", margin: "25px" }}
                  />
                )}
              </div>
              <div className="item-right-column">
                <InfoCard
                  title="Notes"
                  height="650px"
                  width="100%"
                  fields={[
                    <Notes
                      id={id}
                      data={service?.notes}
                      reducer={ServiceReducer}
                      refetchQuery={GET_SERVICE_BY_ID}
                    />,
                  ]}
                />
                <div
                  className="grid-block"
                  style={{ height: "300px", margin: "25px" }}
                />
              </div>
            </PageGrid>
          </PageBody>
        </>
      ) : (
        <div className="no-permission">
          <h1>You do not have permission to view this page</h1>
        </div>
      )}
    </>
  );
};

export default Service;
