// export default Dashboard;
/* eslint-disable array-callback-return */
import React, { useState, useEffect, useContext } from "react";
import { useMutation } from "@apollo/client";
import { UPDATE_USER } from "../../api";
import { PageBody, Button, SubmitButton } from "../../theme";
import { InfoCard, ActivePRS, ActiveInteractions } from "../../components";
import { DataContext } from "../../context/DataContext";
import SortableList from "../../components/sortable-dashboard/SortableList";

const Dashboard = (props: any) => {
  const { users, fetchUsers, programs, projects, services, resources } =
    useContext(DataContext);

  const currentUser = users.find((item: any) => {
    if (item.id === props?.user.attributes.sub) {
      return item;
    }
  });

  const [updateUserMutation] = useMutation(UPDATE_USER);
  const [teamMembers, setTeamMembers] = useState([]);
  const [isManager, setIsManager] = useState(false);
  const [managerDashboard, setManagerDashboard] = useState(false);
  const [userItems, setUserItems] = useState(
    !localStorage.getItem("userItems")
      ? JSON.parse(currentUser.dashboardOrder)
      : JSON.parse(localStorage.getItem("userItems"))
  );

  useEffect(() => {
    if (props?.user.attributes["custom:userGroup"].includes("Team Member")) {
      setIsManager(false);
    } else {
      setIsManager(true);
    }
  }, [props?.user]);

  useEffect(() => {
    const teamMember = users?.filter((item: any) => {
      // Manager
      if (
        props?.user.attributes["custom:userGroup"] === "Manager" &&
        item?.team.includes(props?.user.attributes["custom:team"])
      ) {
        return item;
      }

      // Uber User
      if (
        props?.user.attributes["custom:userGroup"] === "Uber User" &&
        item?.team.includes(props?.user.attributes["custom:team"]) &&
        item?.userGroup !== "Team Member"
      ) {
        return item;
      }
    });
    setTeamMembers(teamMember);
  }, [users, props?.user]);

  useEffect(() => {
    updateUserMutation({
      variables: {
        id: props?.user.attributes.sub,
        input: {
          dashboardOrder: JSON.stringify(userItems),
        },
      },
    })
      .then(() => {
        fetchUsers(null);
        localStorage.setItem("userItems", JSON.stringify(userItems));
      })
      .catch((error) => {
        console.error("Failed to update dashboard order:", error);
      });
  }, [userItems, updateUserMutation, fetchUsers, props?.user.attributes.sub]);

  return (
    <PageBody>
      <div className="dashboard-mngt-btn-container" data-testid="dashboard">
        {isManager && (
          <>
            {managerDashboard ? (
              <Button width="150px" onClick={() => setManagerDashboard(false)}>
                Your Overview
              </Button>
            ) : (
              <SubmitButton
                width="150px"
                onClick={() => setManagerDashboard(false)}
              >
                Your Overview
              </SubmitButton>
            )}
            {managerDashboard ? (
              <SubmitButton
                width="150px"
                onClick={() => setManagerDashboard(true)}
              >
                Team Overview
              </SubmitButton>
            ) : (
              <Button width="150px" onClick={() => setManagerDashboard(true)}>
                Team Overview
              </Button>
            )}
          </>
        )}
      </div>
      <div className="dashboard-main-container">
        <div className="dashboard-left">
          <SortableList
            items={userItems}
            onChange={setUserItems}
            renderItem={(item: any) => (
              <SortableList.Item id={item.id}>
                {!managerDashboard ? (
                  <InfoCard
                    title={item.title}
                    height="40vh"
                    width="100%"
                    headerOption="drag"
                    draggable={<SortableList.DragHandle />}
                    fields={
                      <ActivePRS
                        key="dashboard-programs"
                        type={item.title}
                        id={props?.user.attributes.sub}
                        pairType={item.pairType}
                        secondaryPair={item.secondaryPair}
                        data={
                          item.title === "Programs"
                            ? programs
                            : item.title === "Projects"
                            ? projects
                            : item.title === "Services"
                            ? services
                            : item.title === "Resources"
                            ? resources
                            : null
                        }
                      />
                    }
                  />
                ) : (
                  <InfoCard
                    title={item.title}
                    height="40vh"
                    width="100%"
                    headerOption="drag"
                    draggable={<SortableList.DragHandle />}
                    fields={
                      <ActivePRS
                        isManager
                        key="dashboard-programs"
                        type={item.title}
                        manager={props?.user}
                        teamMembers={teamMembers}
                        pairType={item.pairType}
                        secondaryPair={item.secondaryPair}
                        data={
                          item.title === "Programs"
                            ? programs
                            : item.title === "Projects"
                            ? projects
                            : item.title === "Services"
                            ? services
                            : item.title === "Resources"
                            ? resources
                            : null
                        }
                      />
                    }
                  />
                )}
              </SortableList.Item>
            )}
          />
        </div>
        <div className="dashboard-right">
          <InfoCard
            title="Latest Interactions"
            height="100%"
            width="100%"
            fields={[
              <>
                {managerDashboard ? (
                  <ActiveInteractions
                    isManager
                    user={props?.user}
                    teamMembers={teamMembers}
                  />
                ) : (
                  <ActiveInteractions user={props?.user} />
                )}
              </>,
            ]}
          />
        </div>
      </div>
    </PageBody>
  );
};

export default Dashboard;
