export const INITIAL_PROJECT_STATE = () => {
  return {
    name: "",
    tagLine: "",
    funder: "",
    program: "",
    department: [],
    code: "",
    coops: [],
    lead: [],
    members: [],
    status: "",
    contacts: [],
    approvedBy: "",
    startDate: "",
    dueDate: "",
    industries: [],
    categories: [],
    notes: [],
    deliverables: [],
    description: "",
    budget: [],
    claims: [],
    username: "",
  };
};

export const ProjectReducer = (state, action) => {
  switch (action.field) {
    case "name":
    case "tagLine":
    case "funder":
    case "coops":
    case "program":
    case "department":
    case "code":
    case "lead":
    case "members":
    case "status":
    case "contacts":
    case "approvedBy":
    case "startDate":
    case "dueDate":
    case "industries":
    case "categories":
    case "notes":
    case "deliverables":
    case "description":
    case "budget":
    case "claims":
    case "username":
      return {
        ...state,
        [action.field]: action.payload,
      };
    default:
      return;
  }
};
