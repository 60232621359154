import styled from "styled-components";

export const PageContainer = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    background-color:#ffffff;
    z-index: 1000;
`;