import { gql } from "@apollo/client";

export const CREATE_NOTE = gql `
  mutation CreateNote($sourceId: ID!, $newNote: NoteInput) {
    createNote(sourceId: $sourceId, newNote: $newNote) {
      id
      sourceId
      username
      body
      parent
      createdAt
    }
  }
`;

export const UPDATE_NOTE = gql `
  mutation UpdateNote($sourceId: ID!, $id: ID!, $input: NoteInput) {
    updateNote(sourceId: $sourceId, id: $id, input: $input) {
      id
      sourceId
      username
      body
      parent
      createdAt
    }
  }
`;

export const DELETE_NOTE = gql `
  mutation DeleteNote($sourceId: ID!, $id: ID!) {
    deleteNote(sourceId: $sourceId, id: $id) {
      id
      sourceId
      username
      body
      parent
      createdAt
    }
  }
`;