import React, { useState } from "react";
import { AdvancedInput, AddItem } from "..";
import { SubmitButton } from "../../theme";

const AddBudget = (props: any) => {
  const [modal, setModal] = useState(true);
  const [expenses, setExpenses] = useState("");
  const [adminFees, setAdminFees] = useState("");
  const [budget, setBudget] = useState("");
  const [file, setFile] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    props.handleSubmit(expenses, adminFees, budget, file);
    setModal(false);
  };

  return (
    <>
      {modal && (
        <AddItem
          type="Budget"
          component={[
            <form
              className="add-item-form"
              key="add-budget-item"
              onSubmit={handleSubmit}
            >
              <AdvancedInput
                {...props}
                name="Total Budget"
                type="text"
                width="95%"
                value={budget}
                onChange={(e) => setBudget(e.target.value)}
              />
              <AdvancedInput
                {...props}
                name="Total Expenses"
                type="text"
                width="95%"
                value={expenses}
                onChange={(e) => setExpenses(e.target.value)}
              />
              <AdvancedInput
                {...props}
                name="Admin Fees"
                type="text"
                width="95%"
                value={adminFees}
                onChange={(e) => setAdminFees(e.target.value)}
              />
              <AdvancedInput
                {...props}
                name="File"
                type="text"
                width="95%"
                value={file}
                onChange={(e) => setFile(e.target.value)}
              />
              <span className="file-description">Onedrive file path</span>
              <div className="add-item-button-container">
                <div style={{ width: "150px" }} />
                <SubmitButton width="150px">Submit</SubmitButton>
              </div>
            </form>,
          ]}
        />
      )}
    </>
  );
};

export default AddBudget;
