/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from "react";
import { Table, AddItem, AddContact } from "../../components";
import { PageBody } from "../../theme";
import {
  ContactSchema,
  IdToNameFormatter,
  MultipleIdToNameFormatter,
} from "../../utilities";
import { DataContext } from "../../context/DataContext";

const Contacts = (props: any) => {
  const [addItem, setAddItem] = useState(false);
  const {
    contacts,
    setContacts,
    companies,
    industries,
    contactTypes,
    users,
    categories,
  } = useContext(DataContext);

  useEffect(() => {
    if (contacts) {
      setContacts(
        contacts
          .map((item: any) => {
            const formattedCompanyName = item.companyName
              ? IdToNameFormatter({
                  data: companies,
                  fieldToMatch: item.companyName,
                })
              : "";
            const formattedIndustries = item.industries
              ? MultipleIdToNameFormatter({
                  data: industries,
                  fieldToMatch: item.industries,
                })
              : [];
            const formattedContactTypes = item.contactTypes
              ? MultipleIdToNameFormatter({
                  data: contactTypes,
                  fieldToMatch: item.contactTypes,
                })
              : [];
            const formattedSpecialist = item.specialist
              ? MultipleIdToNameFormatter({
                  data: users,
                  fieldToMatch: item.specialist,
                })
              : [];
            const formattedCategories = item.categories
              ? MultipleIdToNameFormatter({
                  data: categories,
                  fieldToMatch: item.categories,
                })
              : [];

            return {
              ...item,
              formattedCompanyName,
              formattedIndustries,
              formattedContactTypes,
              formattedSpecialist,
              formattedCategories,
            };
          })
          .sort((a: any, b: any) => {
            if (a.status === "Pending") {
              return -1;
            } else if (b.status === "Pending") {
              return 1;
            } else if (a.status === "Active") {
              return -1;
            } else if (b.status === "Active") {
              return 1;
            } else {
              return 0;
            }
          })
      );
    }
  }, []);

  const handleAdd = (e) => {
    e.preventDefault();
    setAddItem(!addItem);
  };

  return (
    <>
      {addItem && (
        <AddItem type="contact" component={<AddContact {...props} />} />
      )}
      <PageBody>
        <Table
          showAdd
          handleAdd={handleAdd}
          title="Contacts"
          count={contacts.length}
          rowDataProp={contacts}
          colDefProp={ContactSchema}
        />
      </PageBody>
    </>
  );
};

export default Contacts;
