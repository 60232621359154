import { useState } from "react";
import { useMutation } from "@apollo/client";
import { toast } from "react-toastify";
import {
  UPDATE_INTERACTION,
  DELETE_INTERACTION,
  GET_INTERACTIONS,
} from "../../api";
import { AddInteraction, InfoCard } from "..";
import { Button } from "../../theme";
import * as AiIcons from "react-icons/ai";

const Interaction = (props: any) => {
  const [updateItem] = useMutation(UPDATE_INTERACTION);
  const [deleteItem] = useMutation(DELETE_INTERACTION);
  const [edit, setEdit] = useState(false);

  const handleUpdate = (
    actionType,
    description,
    category,
    programOrProject
  ) => {
    updateItem({
      variables: {
        sourceId: props?.data.sourceId,
        id: props?.data.id,
        input: {
          actionType: actionType,
          body: description,
          category: category,
          contact: props?.data.sourceId,
          programOrProject: programOrProject,
        },
      },
      refetchQueries: [{ query: GET_INTERACTIONS }],
    }).then(() => {
      toast.success(`Item has been successfully updated.`);
      setEdit(false);
      props.setViewDetails(false);
    });
  };

  const handleDelete = () => {
    if (window.confirm("Are you sure you want to remove this Interaction?")) {
      deleteItem({
        variables: {
          sourceId: props?.data.sourceId,
          id: props?.data.id,
        },
        refetchQueries: [{ query: GET_INTERACTIONS }],
      }).then(() => {
        toast.success(`Item has been successfully deleted.`);
        props.setViewDetails(false);
      });
    }
  };

  return (
    <>
      <div
        className={
          props.viewDetails
            ? "interaction-details"
            : "interaction-details  is-notActive"
        }
      >
        <InfoCard
          className="editor-card"
          title="Interaction Details"
          headerOption="modal"
          height="auto"
          width="350px"
          backgroundColor="white"
          onBtnClick={() => props.setViewDetails(false)}
          fields={[
            <>
              {edit ? (
                <AddInteraction
                  handleSubmit={(
                    actionType,
                    description,
                    category,
                    programOrProject
                  ) =>
                    handleUpdate(
                      actionType,
                      description,
                      category,
                      programOrProject
                    )
                  }
                  setEdit={setEdit}
                  submitLabel="Update"
                />
              ) : (
                <div className="interaction" key="interaction-details">
                  <div className="interaction-header">
                    <div className="interaction-header-left">
                      <div className="interaction-author-image">
                        <AiIcons.AiOutlineUser />
                      </div>
                      <p>{props.data.username}</p>
                    </div>
                    <div className="interaction-header-right">
                      <p>{props.data.createdAt.substr(0, 10)}</p>
                    </div>
                  </div>
                  <div className="interaction-body">
                    <p>{props.data.body}</p>
                  </div>
                  {props.user?.attributes["custom:fullName"] ===
                    props.data.username && (
                    <div className="interaction-footer">
                      <Button width="75px" onClick={() => setEdit(!edit)}>
                        Edit
                      </Button>
                      <Button width="75px" onClick={handleDelete}>
                        Delete
                      </Button>
                    </div>
                  )}
                </div>
              )}
            </>,
          ]}
        />
      </div>
    </>
  );
};

export default Interaction;
