import styled from "styled-components";

export const CardBody = styled.div.attrs((props: any) => ({
  height: props.height,
  width: props.width,
  backgroundColor: props.backgroundColor,
}))`
  display: flex;
  flex-direction: column;
  border: 1px solid #b0b7bc;
  width: ${(props: any) => props.width};
  background-color: #ffffff;
  height: ${(props: any) => props.height};
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.5);
  border-radius: 5px;
  margin: 25px;
  &::-webkit-scrollbar {
    width: 0px;
    background: transparent;
  }
  @media (max-width: 480px) {
    width: calc(95vw - 10px) !important;
    margin: 10px;
    min-height: 35vh !important;
    overflow: hidden;
  }
`;
