export const ContactTitles = [
  {
    name: " ",
  },
  {
    name: "Dr.",
  },
  {
    name: "Mr.",
  },
  {
    name: "Mrs.",
  },
  {
    name: "Ms.",
  },
];

export const PaymentTerms = [
  {
    name: "20%",
  },
  {
    name: "50%",
  },
  {
    name: "100%",
  },
];

export const ActionTypes = [
  {
    name: " ",
  },
  {
    name: "Email",
  },
  {
    name: "Text",
  },
  {
    name: "Phone Call",
  },
  {
    name: "Site Visit",
  },
  {
    name: "In-Office",
  },
];

export const UserLevels = [
  {
    name: "Team Member",
  },
  {
    name: "Manager",
  },
  {
    name: "Finance",
  },
  {
    name: "Uber User",
  },
];

export const StatusPRS = [
  {
    name: "Pending",
  },
  {
    name: "In Progress",
  },
  {
    name: "Complete",
  },
];

export const Status = [
  {
    name: "Pending",
  },
  {
    name: "Inactive",
  },
  {
    name: "Active",
  },
];
