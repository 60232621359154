/* eslint-disable array-callback-return */
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { DataContext } from "../../context/DataContext";
import * as AiIcons from "react-icons/ai";

const ResourceOverview = (props: any) => {
  const Data = props.data;
  const { programs, users } = React.useContext(DataContext);
  const [program, setProgram] = useState(null);
  const [author, setAuthor] = useState(null);

  // Get Related Pairings
  // Users and Members
  useEffect(() => {
    const linkedAuthor = users.find((item) => {
      if (Data?.author.includes(item.id)) return item;
    });
    setAuthor(linkedAuthor);
  }, [users, Data?.author]);

  // Misc Info
  useEffect(() => {
    const linkedProgram = programs.find((item) => {
      if (Data?.program.includes(item.id)) return item;
    });
    setProgram(linkedProgram);
  }, [programs, Data?.program]);

  return (
    <div className="item-overview">
      <div className="item-main-info">
        <p className="item-name">{Data?.name}</p>
        <p>
          Resource Type:
          <span>{Data?.type}</span>
        </p>
        <ul>
          Program:
          <li>
            <Link to={`/directory/programs/${program?.id}`}>
              {program?.name}
            </Link>
          </li>
        </ul>
        <p>
          Author:
          <span>{author?.name}</span>
        </p>
        <p>
          Link:
          <span>
            <a href={Data?.link} target="_blank" rel="noreferrer">
              {Data?.link}
            </a>
          </span>
        </p>
        <ul>
          Description:
          <li>{Data?.description}</li>
        </ul>
        <p>
          CEU:
          <span className="isCeu is-active">
            {Data?.ceu ? (
              <AiIcons.AiOutlineCheck />
            ) : (
              <AiIcons.AiOutlineClose />
            )}
          </span>
        </p>
        {Data?.ceu && (
          <ul>
            CEU Description:
            <li>{Data?.ceuDescription}</li>
          </ul>
        )}
      </div>
      <div className="item-dates-info">
        <p>
          Date Held:
          <span>{Data?.dateHeld}</span>
        </p>
        {Data?.type === "Field Day" ||
        Data?.type === "Workshop" ||
        Data?.type === "Webinar" ||
        Data?.type === "Presentation" ||
        Data?.type === "Conference" ? (
          <p>
            Participants:
            <span>{Data?.participants}</span>
          </p>
        ) : Data?.type === "Publication" || Data?.type === "Fact Sheets" ? (
          <p>
            Copies:
            <span>{Data?.copies}</span>
          </p>
        ) : Data?.type === "Blog" || Data?.type === "Webinar" ? (
          <p>
            Views:
            <span>{Data?.views}</span>
          </p>
        ) : (
          Data?.type === "Podcast" && (
            <p>
              Listeners:
              <span>{Data?.listeners}</span>
            </p>
          )
        )}
      </div>
    </div>
  );
};

export default ResourceOverview;
