/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ca-central-1",
    "aws_mobile_analytics_app_id": "7349dcdefefb426fb06c6eca8b8f4ca8",
    "aws_mobile_analytics_app_region": "ca-central-1",
    "Analytics": {
        "AWSPinpoint": {
            "appId": "7349dcdefefb426fb06c6eca8b8f4ca8",
            "region": "ca-central-1"
        }
    },
    "aws_appsync_graphqlEndpoint": "https://tdyo2ghlsfav5hr3hryzd3czqq.appsync-api.ca-central-1.amazonaws.com/graphql",
    "aws_appsync_region": "ca-central-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cognito_identity_pool_id": "ca-central-1:39cab332-4c78-4caa-a25f-03dde12b541d",
    "aws_cognito_region": "ca-central-1",
    "aws_user_pools_id": "ca-central-1_hE4kShwwX",
    "aws_user_pools_web_client_id": "3nfmnhbug9b77ru9i3ptdodbep",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "ON",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "crmapps3bucket140903-dev",
    "aws_user_files_s3_bucket_region": "ca-central-1"
};


export default awsmobile;
