export const INITIAL_RESOURCE_STATE = () => {
  return {
    author: "",
    name: "",
    type: "",
    description: "",
    program: "",
    ceu: false,
    ceuDescription: "",
    link: "",
    dateHeld: "",
    participants: "",
    views: "",
    copies: "",
    listeners: "",
    notes: [],
    username: "",
  };
};

export const ResourceReducer = (state, action) => {
  switch (action.field) {
    case "author":
    case "name":
    case "type":
    case "description":
    case "program":
    case "ceuDescription":
    case "link":
    case "dateHeld":
    case "participants":
    case "views":
    case "copies":
    case "listeners":
    case "notes":
    case "username":
      return {
        ...state,
        [action.field]: action.payload,
      };

    case "ceu":
      return {
        ...state,
        ceu: !state.ceu,
      };

    default:
      return;
  }
};
