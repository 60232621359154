import React, { useState, useEffect, useRef } from "react";
import { useQuery, useMutation } from "@apollo/client";
import { toast } from "react-toastify";
import { GET_FAQS, DELETE_FAQ } from "../../api";
import { PageBody, Button } from "../../theme";
import { AddItem, AddFaq, Editor } from "../../components";
import * as AiIcons from "react-icons/ai";
import * as FiIcons from "react-icons/fi";

const FAQ = (props: any) => {
  const ref = useRef(null);
  const { data: faqData } = useQuery(GET_FAQS);
  const [deleteFaq] = useMutation(DELETE_FAQ);
  const [faqs, setFaqs] = useState([]);
  const [active, setActive] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [addFaq, setAddFaq] = useState(false);
  const [editFaq, setEditFaq] = useState(false);
  const [faqId, setFaqId] = useState("");

  useEffect(() => {
    if (faqData) {
      setFaqs(faqData?.getAllFaqs.faqs);
    }
    if (props?.user.attributes["custom:userGroup"] === "Uber User") {
      setIsAdmin(true);
    }
  }, [faqData, props]);

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const handleClickOutside = (e) => {
    if (ref.current && !ref.current.contains(e.target)) {
      setActive(false);
    }
  };

  const handleToggle = (index) => {
    if (active === index) {
      return setActive(false);
    }

    setActive(index);
  };

  const handleDeleteFaqItem = (id) => {
    const notify = () => toast.success("Item deleted successfully!");
    if (window.confirm("Are you sure you want to delete this item?")) {
      deleteFaq({
        variables: {
          id: id,
        },
      }).then((item) => {
        const updatedFaqs = faqs.filter(
          (el) => el.id !== item.data.deleteFaq.id
        );
        setFaqs(updatedFaqs);
      });
      notify();
    }
  };

  return (
    <>
      {addFaq && <AddItem type="FAQ Question" component={<AddFaq />} />}
      {editFaq && (
        <Editor
          type="FAQ Question"
          component={<AddFaq isEdit={true} id={faqId} />}
        />
      )}
      <PageBody>
        <div className="faq-top" ref={ref}>
          {isAdmin && (
            <div className="admin-options">
              <Button onClick={() => setAddFaq(!addFaq)}>Create Item</Button>
            </div>
          )}
          <h1>FAQ</h1>
          {faqs?.map((item, index) => (
            <div className="faq-item-container" key={index}>
              <div className="faq-item-header">
                <h3 onClick={() => handleToggle(index)} key={index}>
                  {item?.question}
                </h3>
                <div className="faq-btn-container">
                  <button onClick={() => handleToggle(index)} key={index}>
                    {/* @ts-ignore */}
                    {!active && index === 0 ? (
                      <AiIcons.AiOutlineCaretUp />
                    ) : // @ts-ignore
                    active === index ? (
                      <AiIcons.AiOutlineCaretUp />
                    ) : (
                      <AiIcons.AiOutlineCaretDown />
                    )}
                  </button>
                  {isAdmin && (
                    <>
                      <button
                        onClick={() => {
                          setEditFaq(!editFaq);
                          setFaqId(item.id);
                        }}
                      >
                        <FiIcons.FiEdit />
                      </button>
                      <button onClick={() => handleDeleteFaqItem(item.id)}>
                        <FiIcons.FiTrash />
                      </button>
                    </>
                  )}
                </div>
              </div>

              <div
                className={
                  // @ts-ignore
                  !active && index === 0
                    ? "faq-item-body active"
                    : // @ts-ignore
                    active === index
                    ? "faq-item-body active"
                    : "faq-item-body"
                }
              >
                <p>{item.answer}</p>
              </div>
            </div>
          ))}
        </div>
      </PageBody>
    </>
  );
};

export default FAQ;
