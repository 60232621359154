import { gql } from "@apollo/client";

export const GET_SERVICETYPES = gql`
  query getAllServiceTypes($nextToken: String) {
    getAllServiceTypes(nextToken: $nextToken) {
      serviceTypes {
        id
        name
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const GET_SERVICETYPE_BY_ID = gql`
  query getServiceTypeById($id: ID!) {
    getServiceTypeById(id: $id) {
      id
      name
      createdAt
      updatedAt
    }
  }
`;

export const CREATE_SERVICETYPE = gql`
  mutation createServiceType($newServiceType: ServiceTypeInput) {
    createServiceType(newServiceType: $newServiceType) {
      id
      name
      createdAt
      updatedAt
    }
  }
`;

export const UPDATE_SERVICETYPE = gql`
  mutation updateServiceType($id: ID!, $input: ServiceTypeInput) {
    updateServiceType(id: $id, input: $input) {
      id
      name
      createdAt
      updatedAt
    }
  }
`;

export const DELETE_SERVICETYPE = gql`
  mutation deleteServiceType($id: ID!) {
    deleteServiceType(id: $id) {
      id
      name
      createdAt
      updatedAt
    }
  }
`;
