/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Auth } from "aws-amplify";
import { Table, AddItem, AddProgram } from "../../components";
import { ProgramSchema, MultipleIdToNameFormatter } from "../../utilities";
import { PageBody } from "../../theme";
import { DataContext } from "../../context/DataContext";

const Programs = (props: any) => {
  const [addItem, setAddItem] = useState(false);
  const [isSuperAdmin, setIsSuperAdmin] = useState(false);
  const {
    programs,
    setPrograms,
    contacts,
    companies,
    projects,
    resources,
    services,
    industries,
    users,
  } = React.useContext(DataContext);

  useEffect(() => {
    if (programs) {
      setPrograms(
        programs
          .map((item: any) => {
            const formattedFunders = item.funder
              ? MultipleIdToNameFormatter({
                  data: companies,
                  fieldToMatch: item.funder,
                })
              : [];
            const formattedContacts = item.contacts
              ? MultipleIdToNameFormatter({
                  data: contacts,
                  fieldToMatch: item.contacts,
                })
              : [];
            const formattedLead = item.lead
              ? MultipleIdToNameFormatter({
                  data: users,
                  fieldToMatch: item.lead,
                })
              : [];
            const formattedSpecialists = item.members
              ? MultipleIdToNameFormatter({
                  data: users,
                  fieldToMatch: item.members,
                })
              : [];
            const formattedIndustries = item.industries
              ? MultipleIdToNameFormatter({
                  data: industries,
                  fieldToMatch: item.industries,
                })
              : [];
            const formattedProjects = item.projects
              ? MultipleIdToNameFormatter({
                  data: projects,
                  fieldToMatch: item.projects,
                })
              : [];
            const formattedServices = item.services
              ? MultipleIdToNameFormatter({
                  data: services,
                  fieldToMatch: item.services,
                })
              : [];
            const formattedResources = item.resources
              ? MultipleIdToNameFormatter({
                  data: resources,
                  fieldToMatch: item.resources,
                })
              : [];

            return {
              ...item,
              formattedFunders,
              formattedContacts,
              formattedLead,
              formattedSpecialists,
              formattedIndustries,
              formattedProjects,
              formattedServices,
              formattedResources,
            };
          })
          .sort((a: any, b: any) => {
            if (a.status === "Pending") {
              return -1;
            } else if (b.status === "Pending") {
              return 1;
            } else if (a.status === "Active") {
              return -1;
            } else if (b.status === "Active") {
              return 1;
            } else {
              return 0;
            }
          })
      );
    }
  }, []);

  useEffect(() => {
    const getUserDetails = async () => {
      const authedUser = await Auth.currentAuthenticatedUser();
      if (authedUser?.attributes["custom:userGroup"].includes("Uber User")) {
        setIsSuperAdmin(true);
      } else {
        setIsSuperAdmin(false);
      }
    };
    getUserDetails();
  }, []);

  return (
    <>
      {addItem && (
        <AddItem type="program" component={<AddProgram {...props} />} />
      )}
      <PageBody>
        <Table
          showAdd={isSuperAdmin}
          handleAdd={() => setAddItem(!addItem)}
          title="Programs"
          count={programs?.length}
          rowDataProp={programs}
          colDefProp={ProgramSchema}
        />
      </PageBody>
    </>
  );
};

export default Programs;
