import React from "react";
import { CategoryCard } from "..";

const Category = ({ categories, parent = null, level = 0 }) => {
  const items = categories
    .filter((item) => item.parent === parent)
    .sort((a, b) => (a.name > b.name ? 1 : -1));

  if (!items.length) return <p>Please add a Category to continue</p>;

  return (
    <>
      {items.map((item) => (
        <>
          <CategoryCard key={item.id} item={item} level={level}>
            <Category
              categories={categories}
              parent={item.id}
              level={level + 1}
            />
          </CategoryCard>
        </>
      ))}
    </>
  );
};

export default Category;
