/* eslint-disable array-callback-return */
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Auth } from "aws-amplify";
import { NavData } from "../../utilities";
import * as AiIcons from "react-icons/ai";

const Sidebar = () => {
  const [userLevel, setUserLevel] = useState(null);
  const [sidebar, setSidebar] = useState(false);

  const showSidebar = () => setSidebar(!sidebar);

  const getUserGroup = async () => {
    const authedUser = await Auth.currentAuthenticatedUser();
    if (authedUser) {
      setUserLevel(authedUser?.attributes["custom:userGroup"]);
    }
  };

  useEffect(() => {
    getUserGroup();
  }, []);

  return (
    <>
      <div className="sidebar" data-testid="sidebar">
        <AiIcons.AiOutlineMenu
          className="sidebar-nav-icon"
          onClick={showSidebar}
        />
      </div>
      <nav className={sidebar ? "sidebar-nav active" : "sidebar-nav"}>
        <div className="sidebar-item-wrapper">
          <AiIcons.AiOutlineClose
            className="sidebar-nav-icon-close"
            onClick={showSidebar}
          />
          {NavData?.map((item, index) => {
            if (item.userGroup.includes(userLevel)) {
              return (
                <Link
                  key={index}
                  className="sidebar-link"
                  data-testid="nav-item"
                  to={item.path}
                  onClick={showSidebar}
                >
                  <p className="sidebar-label">
                    {item.icon}
                    <span data-testid="sidebar-link-title">{item.title}</span>
                  </p>
                </Link>
              );
            }
          })}
        </div>
      </nav>
    </>
  );
};

export default Sidebar;
