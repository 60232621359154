import styled from "styled-components";

export const SubmitButton = styled.button.attrs((props) => ({
  width: props.width,
}))`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 36px;
  width: ${(props) => props.width};
  color: #ffffff;
  background: linear-gradient(to right, #003b4d 50%, #009999 50%);
  background-size: 200% 100%;
  background-position: right bottom;
  transition: all 0.5s ease-out;
  border-radius: 5px;
  border: none;
  outline: none;
  margin: 10px;
  margin-left: 15px;
  &:hover {
    cursor: pointer;
    background-position: left bottom;
  }
`;
