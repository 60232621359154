/* eslint-disable array-callback-return */
import React, { useState, useEffect } from "react";
import { AdvancedInput } from "..";

const CategorySelect = (props: any) => {
  const [categories, setCategories] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);

  useEffect(() => {
    if (selectedCategories.length === 0) {
      const topLevelCats = props.data?.filter((items) => {
        if (!items.parent) {
          return items;
        }
      });
      setCategories(topLevelCats.sort((a, b) => (a.name > b.name ? 1 : -1)));
    }
  }, [props.data, selectedCategories]);

  const handleSelect = (catIds) => {
    props.handleOnChange(catIds);
    const children = props?.data.filter((items) => {
      if (catIds?.includes(items?.parent)) {
        return items;
      }
    });
    if (children) {
      setCategories(children.sort((a, b) => (a.name > b.name ? 1 : -1)));
    }

    setSelectedCategories(catIds);
  };

  return (
    <>
      <AdvancedInput
        {...props}
        name="select a category"
        data={categories}
        isMulti={true}
        fieldName="name"
        type="dropdown"
        width="95%"
        value={selectedCategories}
        onChange={(selectedOptions) => {
          handleSelect(selectedOptions.map((i) => i.id));
        }}
      />
    </>
  );
};
export default CategorySelect;
